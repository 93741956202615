import {
  DatesProvider,
  DatePicker as MantineDatePicker,
  PickerInputBase as MantinePickerInputBase,
  type CalendarLevel as MantineCalendarLevel,
  type DateTimePickerProps as MantineDateTimePickerProps,
} from '@mantine/dates'
import { useDisclosure } from '@mantine/hooks'
import { add, isToday } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { capitalize } from 'lodash-es'
import { useState } from 'react'
import { type RefCallBack } from 'react-hook-form'

import { kuiThemeVars } from 'components/kui/_internal/theme'
import { KuiNumberInput } from 'components/kui/inputs/KuiNumberInput'
import { KuiSelectInput } from 'components/kui/inputs/KuiSelectInput'
import { KuiTimeInput } from 'components/kui/inputs/KuiTimeInput'
import { KuiButton } from 'components/kui/KuiButton'
import { KuiFlex } from 'components/kui/KuiFlex'
import { KuiGrid } from 'components/kui/KuiGrid'
import { KuiStack } from 'components/kui/KuiStack'
import { KuiText } from 'components/kui/KuiText'
import {
  enrichDateWithTime,
  LOCAL_TIMEZONE,
  type KuiTimezone,
  setInTimezone,
} from 'utils/datetime'

import classes from './KuiDateInput.module.css'

type KuiDateInputShortcut = {
  label: string
  value: Date
  preview?: boolean
}

export type KuiDateInputWithTimeProps =
  | {
      withTime: true

      /** @default LOCAL_TIMEZONE() */
      timezone: KuiTimezone | undefined

      /** @default true */
      includeTimezone?: boolean
    }
  | {
      withTime?: false
      includeTimezone?: never

      /** @default UTC */
      timezone?: KuiTimezone | undefined
    }

export type KuiDateInputProps = Pick<
  MantineDateTimePickerProps,
  | 'label'
  | 'description'
  | 'placeholder'
  | 'error'
  | 'disabled'
  | 'withAsterisk'
  | 'variant'
  | 'minDate'
  | 'maxDate'
  | 'clearable'
> & {
  value: Date | null
  onChange: (value: Date | null) => void

  initialVisibleDate?: Date

  defaultTime?: string

  shortcuts?:
    | KuiDateInputShortcut[]
    | ((_: { date: Date | null }) => KuiDateInputShortcut[])

  customShortcutProps?: {
    initialValues: CustomShortcutProps['initialValues']
    relativeDate: 'now' | 'selected-date'
  }

  /** @default value of the `onChange` prop */
  onShortcutSelect?: (value: Date) => void

  inputRef?: RefCallBack
} & KuiDateInputWithTimeProps

export function KuiDateInput({
  value,
  onChange,
  withTime = false,
  timezone = withTime ? LOCAL_TIMEZONE : 'UTC',
  includeTimezone = true,
  initialVisibleDate,
  defaultTime = '10:00',
  inputRef,
  minDate,
  maxDate,
  shortcuts: consumerShortcuts,
  customShortcutProps,
  onShortcutSelect = onChange,
  label,
  placeholder = typeof label === 'string'
    ? `Select ${label.toLowerCase()}`
    : undefined,
  clearable = true,
  ...restProps
}: KuiDateInputProps) {
  const [dropdownOpened, dropdownHandlers] = useDisclosure(false)

  const [currentLevel, setCurrentLevel] =
    useState<MantineCalendarLevel>('month')

  const formattedValue = value
    ? formatInTimeZone(
        value,
        timezone,
        withTime
          ? `MM/dd/yyyy - hh:mm a${includeTimezone ? ' zzz' : ''}`
          : 'MM/dd/yyyy'
      )
    : undefined

  const shortcuts =
    typeof consumerShortcuts === 'function'
      ? consumerShortcuts({ date: value })
      : consumerShortcuts ?? []

  return (
    <DatesProvider settings={{ locale: 'en', timezone }}>
      <MantinePickerInputBase
        ref={inputRef}
        {...restProps}
        type='default'
        label={label}
        placeholder={placeholder}
        value={value}
        formattedValue={formattedValue}
        clearable={clearable}
        dropdownOpened={dropdownOpened}
        dropdownHandlers={dropdownHandlers}
        shouldClear={!!value}
        onClear={() => onChange(null)}
        style={{ width: '100%' }}
        styles={
          restProps.variant === 'unstyled'
            ? {
                input: {
                  paddingLeft: kuiThemeVars.spacingSizes.xs,
                },
              }
            : undefined
        }
      >
        <KuiGrid columns={shortcuts.length > 0 ? 2 : 1} gapSize='sm'>
          <KuiStack gapSize='sm'>
            <MantineDatePicker
              value={value}
              defaultDate={value ?? initialVisibleDate}
              size='sm'
              firstDayOfWeek={0}
              level={currentLevel}
              classNames={{
                day: classes.day,
              }}
              renderDay={(day) =>
                isToday(day) ? (
                  <div style={{ textDecoration: 'underline' }}>
                    {day.getDate()}
                  </div>
                ) : (
                  day.getDate()
                )
              }
              minDate={minDate}
              maxDate={maxDate}
              onLevelChange={setCurrentLevel}
              onChange={onSelectDate}
            />

            {withTime && currentLevel === 'month' && (
              <KuiFlex gapSize='xs'>
                <KuiFlex.Item grow={1}>
                  <KuiTimeInput
                    value={
                      value
                        ? formatInTimeZone(value, timezone, 'HH:mm')
                        : defaultTime
                    }
                    onChange={onTimeChange}
                  />
                </KuiFlex.Item>

                <KuiButton
                  iconType='check'
                  variant='outline'
                  onClick={dropdownHandlers.close}
                />
              </KuiFlex>
            )}
          </KuiStack>

          {shortcuts.length > 0 && (
            <KuiFlex
              direction='column'
              justifyContent='spaceBetween'
              gapSize='md'
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '36px', // align with month picker
                    marginBottom: '4px', // hack to better align with day headers
                  }}
                >
                  <KuiText.p size='sm'>Shortcuts</KuiText.p>
                </div>

                {shortcuts.map((shortcut) => (
                  <div key={shortcut.label} style={{ paddingTop: '0.5px' }}>
                    <KuiButton
                      _leftAlign={true}
                      fullWidth={true}
                      onClick={() => {
                        onShortcutSelect(shortcut.value)
                        dropdownHandlers.close()
                      }}
                    >
                      {shortcut.label}
                      {shortcut.preview !== false && (
                        <KuiText.span color='hushed'>
                          &nbsp;
                          {`(${formatInTimeZone(shortcut.value, timezone, 'MMM d')})`}
                        </KuiText.span>
                      )}
                    </KuiButton>
                  </div>
                ))}
              </div>

              {customShortcutProps &&
                (customShortcutProps.relativeDate === 'now' || !!value) && (
                  <CustomShortcut
                    initialValues={customShortcutProps.initialValues}
                    onSubmit={({ length, unit }) => {
                      onShortcutSelect(
                        add(
                          customShortcutProps.relativeDate === 'now'
                            ? new Date()
                            : value!,
                          { [unit]: length }
                        )
                      )

                      dropdownHandlers.close()
                    }}
                  />
                )}
            </KuiFlex>
          )}
        </KuiGrid>
      </MantinePickerInputBase>
    </DatesProvider>
  )

  function onSelectDate(nextDate: Date | null) {
    if (withTime === false) {
      dropdownHandlers.close()

      return onChange(nextDate)
    }

    if (!nextDate) {
      return onChange(null)
    }

    const newValue = setInTimezone(
      value ?? enrichDateWithTime(nextDate, timezone, defaultTime),
      timezone,
      {
        year: nextDate.getFullYear(),
        month: nextDate.getMonth(),
        date: nextDate.getDate(),
      }
    )

    onChange(newValue)
  }

  function onTimeChange(nextValue: string) {
    if (nextValue === '') {
      return
    }

    const newValue = enrichDateWithTime(
      value ?? new Date(),
      timezone,
      nextValue
    )

    onChange(newValue)
  }
}

type CustomShortcutUnit = 'days' | 'weeks'

type CustomShortcutProps = {
  initialValues: { length: number; unit: CustomShortcutUnit }
  onSubmit: (value: { length: number; unit: CustomShortcutUnit }) => void
}

function CustomShortcut({ initialValues, onSubmit }: CustomShortcutProps) {
  const [length, setLength] = useState(initialValues.length)
  const [unit, setUnit] = useState<CustomShortcutUnit>(initialValues.unit)

  return (
    <KuiFlex gapSize='xs'>
      <div style={{ width: '70px' }}>
        <KuiNumberInput
          type='int'
          value={length}
          onChange={(nextValue) => {
            if (nextValue === null) {
              return
            }

            setLength(nextValue)
          }}
        />
      </div>

      <KuiFlex.Item grow={1}>
        <KuiSelectInput
          value={unit}
          items={['days', 'weeks'] as const}
          parseItem={(item) => ({
            key: item,
            label: capitalize(item),
          })}
          searchable={false}
          clearable={false}
          onChange={(nextValue) => {
            if (!nextValue) {
              return
            }

            setUnit(nextValue)
          }}
        />
      </KuiFlex.Item>

      <KuiFlex.Item shrink={0}>
        <KuiButton variant='outline' onClick={() => onSubmit({ length, unit })}>
          Use
        </KuiButton>
      </KuiFlex.Item>
    </KuiFlex>
  )
}
