import { lazily } from 'react-lazily'

import {
  getGetUserQueryOptions,
  getLocationGetQueryOptions,
} from '_autogenerated'
import { businessSettingsUrls } from 'routes/Settings/settingsUrls'
import { createRoute } from 'routing'

const {
  BusinessSettingsPageRoot,
  BusinessSettingsPageCompanyDetailsTab,
  LocationSettingsPage,
  LocationCreatePage,
  LocationDetailPage,
  LocationEditPage,
  UsersList,
  UserDetail,
  UserCreatePage,
  UserEditPage,
} = lazily(() => import('./components'))

export const businessSettingsRoutes = [
  createRoute({
    route: businessSettingsUrls,
    Component: BusinessSettingsPageRoot,
    children: [
      createRoute({
        route: businessSettingsUrls,
        Component: BusinessSettingsPageCompanyDetailsTab,
      }),
      createRoute({
        route: businessSettingsUrls.users,
        Component: UsersList,
      }),
      createRoute({
        route: businessSettingsUrls.locations,
        Component: LocationSettingsPage,
      }),
    ],
  }),
  createRoute({
    route: businessSettingsUrls.locations.create,
    Component: LocationCreatePage,
  }),
  createRoute({
    route: businessSettingsUrls.locations.detail,
    Component: LocationDetailPage,
    prefetch: ({ id }) => [getLocationGetQueryOptions(id)],
  }),
  createRoute({
    route: businessSettingsUrls.locations.edit,
    Component: LocationEditPage,
    prefetch: ({ id }) => [getLocationGetQueryOptions(id)],
  }),
  createRoute({
    route: businessSettingsUrls.users.create,
    Component: UserCreatePage,
  }),
  createRoute({
    route: businessSettingsUrls.users.detail,
    Component: UserDetail,
    prefetch: ({ id }) => [getGetUserQueryOptions(id)],
  }),
  createRoute({
    route: businessSettingsUrls.users.edit,
    Component: UserEditPage,
    prefetch: ({ id }) => [getGetUserQueryOptions(id)],
  }),
]
