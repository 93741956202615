import {
  useController,
  useFormContext,
  type ControllerRenderProps,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
} from 'react-hook-form'

import {
  KuiDateInput,
  type KuiDateInputWithTimeProps,
  type KuiDateInputProps,
} from 'components/kui/inputs/KuiDateInput/KuiDateInput'
import { useIsReadonlyKyusuForm } from 'hooks/useKyusuFormContext'
import { type RequireGeneric } from 'utils/types'

import { getKuiFormFieldError, getMaybeReadonlyFormFieldProps } from './utils'
import { kuiRequiredValidator } from './validators'

type KuiFormDateInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Pick<
  UseControllerProps<RequireGeneric<TFieldValues>>,
  'name' | 'rules' | 'disabled'
> &
  KuiDateInputWithTimeProps &
  Omit<KuiDateInputProps, keyof ControllerRenderProps<TFieldValues, TName>> & {
    required?: boolean
    fallbackValue?: Date | null
    onChange?: (value: Date | null) => void
    readOnly?: boolean
  }

export function KuiFormDateInput<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
>({
  name,
  rules,
  required = false,
  clearable = !required,
  fallbackValue = null,
  onChange: consumerOnChange,
  readOnly: consumerReadOnly = false,
  ...restProps
}: KuiFormDateInputProps<TFieldValues, TName>) {
  const readOnly = useIsReadonlyKyusuForm() || consumerReadOnly
  const formContext = useFormContext<TFieldValues>()

  const {
    field: { ref: inputRef, value, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({
    name,
    rules: { ...rules, required: kuiRequiredValidator(required) },
  })

  return (
    <KuiDateInput
      inputRef={inputRef}
      {...field}
      {...(restProps as any)}
      value={value ?? fallbackValue}
      {...getMaybeReadonlyFormFieldProps({
        readOnly,
        ...restProps,
        withAsterisk: required,
      })}
      clearable={clearable}
      error={getKuiFormFieldError({ fieldState })}
      onChange={(nextDate) => {
        formContext.clearErrors(name)

        onChange(nextDate)
        consumerOnChange?.(nextDate)
      }}
    />
  )
}
