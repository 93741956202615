import { type InputBaseProps as MantineInputBaseProps } from '@mantine/core'
import { type FocusEventHandler } from 'react'
import { type RefCallBack } from 'react-hook-form'

import {
  KuiSelect,
  type KuiSelectProps,
  type KuiSelectRenderTargetParams,
} from './KuiSelect'
import { KuiTextInput } from './KuiTextInput'

export type KuiAutocompleteInputProps<TItemSingle> = Omit<
  KuiSelectProps<TItemSingle>,
  | 'renderTarget'
  | 'value'
  | 'onChange'
  | 'onSearchDebounced'
  | 'selectedItems'
  | 'setSelectedItems'
> &
  Pick<
    MantineInputBaseProps,
    'label' | 'description' | 'error' | 'disabled' | 'withAsterisk' | 'variant'
  > & {
    value: string
    onChange: (nextValue: string) => void

    placeholder?: string

    onSelect: (item: TItemSingle) => void

    inputRef?: RefCallBack

    onBlur?: FocusEventHandler<HTMLInputElement>

    onSearchDebounced?: (nextSearch: string) => void
  }

export function KuiAutocompleteInput<TItemSingle>({
  value,
  label,
  description,
  placeholder,
  error,
  disabled,
  variant,
  withAsterisk,
  inputRef,
  onSelect,
  onBlur,
  onChange,
  ...restProps
}: KuiAutocompleteInputProps<TItemSingle>) {
  return (
    <KuiSelect
      {...restProps}
      queryString={value}
      searchable={false}
      renderTarget={renderInput}
      onChange={onSelect}
    />
  )

  function renderInput({
    _combobox: combobox,
  }: KuiSelectRenderTargetParams<TItemSingle>) {
    const inputProps = {
      label,
      description,
      placeholder,
      error,
      disabled,
      withAsterisk,
      variant,
    }

    return (
      <KuiTextInput
        {...inputProps}
        ref={inputRef}
        value={value}
        onChange={(nextValue) => {
          combobox.openDropdown()
          onChange(nextValue)
        }}
        onClick={() => combobox.openDropdown()}
        onFocus={() => combobox.openDropdown()}
        onBlur={(event) => {
          combobox.closeDropdown()
          onBlur?.(event)
        }}
      />
    )
  }
}
