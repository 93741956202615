import {
  type FloatingPosition,
  Combobox as MantineCombobox,
  useCombobox,
  type ComboboxStore as MantineComboboxStore,
} from '@mantine/core'
import { useDebouncedCallback } from '@mantine/hooks'
import { useEffect, useMemo, useRef, useState, type ReactNode } from 'react'

import {
  KuiComboboxOptionsDropdown,
  defaultComboboxItemsFilter,
  filterItems,
  type KuiComboboxOptionsDropdownProps,
} from 'components/kui/_internal/KuiComboboxOptionsDropdown'

type KuiDropdownWidth = 'relative' | 's'

const dropdownWidthMap: Record<
  Exclude<KuiDropdownWidth, 'relative'>,
  string
> = {
  s: '300px',
}

export type KuiSelectRenderTargetParams<TItemSingle> = Pick<
  MantineComboboxStore,
  'toggleDropdown'
> & {
  value: TItemSingle | null

  search: string | null
  setSearch: (nextSearch: string | null) => void

  _combobox: MantineComboboxStore
}

type SingleSelectProps<TItemSingle> = {
  value?: TItemSingle | null
  onChange: (item: TItemSingle) => void

  selectedItems?: never
  setSelectedItems?: never
}

type MultiSelectProps<TItemSingle> = {
  selectedItems: TItemSingle[]
  setSelectedItems: (item: TItemSingle[]) => void

  value?: never
  onChange?: never
}

export type KuiSelectProps<TItemSingle> =
  KuiComboboxOptionsDropdownProps<TItemSingle> & {
    dropdownMinWidth?: KuiDropdownWidth

    /** @default true */
    searchable?: boolean

    /** @default false */
    loading?: boolean

    onSearchDebounced?: (nextSearch: string) => void

    renderTarget: (
      params: KuiSelectRenderTargetParams<TItemSingle>
    ) => ReactNode

    dropdownPosition?: FloatingPosition

    _opened?: boolean
    _targetType?: 'Target' | 'DropdownTarget'
    _keepOpenAfterSelect?: boolean
    _onDropdownOpen?: () => void
    _onDropdownClose?: () => void
  } & (SingleSelectProps<TItemSingle> | MultiSelectProps<TItemSingle>)

export function KuiSelect<TItemSingle>({
  searchable = true,
  dropdownMinWidth = 's',
  value = null,
  onChange,
  selectedItems = [],
  setSelectedItems,
  parseItem,
  onSearchDebounced: consumerOnSearchDebounced,
  loading: loadingOptions = false,
  renderTarget,
  _opened,
  _targetType = 'Target',
  _keepOpenAfterSelect = false,
  _onDropdownClose,
  _onDropdownOpen,
  _creatableProps,
  dropdownPosition = 'bottom-start',
  items: consumerItems,
  ...restProps
}: KuiSelectProps<TItemSingle>) {
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const combobox = useCombobox({
    opened: _opened,
    onDropdownClose: () => {
      combobox.resetSelectedOption()

      if (dropdownRef.current) {
        dropdownRef.current.scrollTop = 0
      }

      _onDropdownClose?.()
    },
    onDropdownOpen: () => {
      setInitiallySelectedItems(selectedItems)

      combobox.selectFirstOption()

      if (searchable) {
        combobox.focusSearchInput()
      }

      _onDropdownOpen?.()
    },
  })

  const [search, setSearchInternal] = useState<string | null>(null)
  const [debouncedSearch, setDebouncedSearch] = useState<string>('')
  const isDebouncing =
    consumerOnSearchDebounced && (search ?? '') !== debouncedSearch

  useEffect(() => {
    combobox.selectFirstOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, consumerItems])

  const onSearchDebounced = useDebouncedCallback((nextSearch: string) => {
    consumerOnSearchDebounced?.(nextSearch)
    setDebouncedSearch(nextSearch)
  }, 200)

  const selectedItemKeys = useMemo(
    () => new Set(selectedItems.map((item) => parseItem(item).key)),
    [parseItem, selectedItems]
  )

  const ComboboxTarget =
    _targetType === 'Target'
      ? MantineCombobox.Target
      : MantineCombobox.DropdownTarget

  const [initiallySelectedItems, setInitiallySelectedItems] =
    useState(selectedItems)
  const initiallySelectedItemKeys = useMemo(
    () => new Set(initiallySelectedItems.map((item) => parseItem(item).key)),
    [parseItem, initiallySelectedItems]
  )

  const items = setSelectedItems
    ? // display selected items at top for multi select
      [
        ...defaultComboboxItemsFilter({
          items: initiallySelectedItems,
          parseItem,
          queryString: search ?? '',
        }),
        ...filterItems({
          items: consumerItems,
          predicate: (item) =>
            !initiallySelectedItemKeys.has(parseItem(item).key),
        }),
      ]
    : consumerItems

  return (
    <MantineCombobox
      store={combobox}
      width='target'
      position={dropdownPosition}
      styles={
        dropdownMinWidth !== 'relative'
          ? { dropdown: { minWidth: dropdownWidthMap[dropdownMinWidth] } }
          : undefined
      }
    >
      <ComboboxTarget>
        {renderTarget({
          toggleDropdown: combobox.toggleDropdown,
          value,
          search,
          setSearch,
          _combobox: combobox,
        })}
      </ComboboxTarget>

      <KuiComboboxOptionsDropdown
        {...restProps}
        items={items}
        dropdownRef={dropdownRef}
        combobox={combobox}
        loading={loadingOptions || isDebouncing}
        queryString={search ?? ''}
        onSearch={searchable ? setSearch : undefined}
        parseItem={parseItem}
        isItemSelected={isItemSelected}
        onItemSelect={onItemSelect}
        _creatableProps={
          _creatableProps
            ? {
                ..._creatableProps,
                onCreate: (queryString) => {
                  _creatableProps.onCreate(queryString)
                  combobox.closeDropdown()
                  setSearch(null)
                },
              }
            : undefined
        }
      />
    </MantineCombobox>
  )

  function setSearch(nextSearch: string | null) {
    setSearchInternal(nextSearch)

    if (consumerOnSearchDebounced) {
      onSearchDebounced(nextSearch ?? '')
    }
  }

  function isItemSelected(item: TItemSingle) {
    if (setSelectedItems) {
      return selectedItemKeys.has(parseItem(item).key)
    }

    return !!value && parseItem(value).key === parseItem(item).key
  }

  function onItemSelect(nextItem: TItemSingle) {
    if (onChange) {
      if (!_keepOpenAfterSelect) {
        combobox.closeDropdown()

        if (searchable) {
          combobox.focusTarget()
        }
      } else {
        combobox.focusSearchInput()
      }

      setSearch(null)

      return onChange(nextItem)
    }

    if (selectedItemKeys.has(parseItem(nextItem).key)) {
      return setSelectedItems(
        selectedItems.filter((p) => areItemsEqual(p, nextItem) === false)
      )
    }

    setSelectedItems([...selectedItems, nextItem])
  }

  function areItemsEqual(itemA: TItemSingle, itemB: TItemSingle) {
    return parseItem(itemA).key === parseItem(itemB).key
  }
}
