import {
  CloseButton as MantineCloseButton,
  Combobox as MantineCombobox,
  Input as MantineInput,
  InputBase as MantineInputBase,
  Loader as MantineLoader,
  type InputBaseProps as MantineInputBaseProps,
} from '@mantine/core'
import { mergeRefs } from '@mantine/hooks'
import { useRef, useState, type FocusEventHandler } from 'react'
import { type RefCallBack } from 'react-hook-form'

import { KuiFlex } from 'components/kui/KuiFlex'
import { KuiIcon, type KuiIconType } from 'components/kui/KuiIcon/KuiIcon'
import { KuiText } from 'components/kui/KuiText'
import { wrapKuiApiRequest } from 'components/kui/wrapKuiApiRequest'

import {
  KuiSelect,
  type KuiSelectProps,
  type KuiSelectRenderTargetParams,
} from './KuiSelect'
import { KuiTextInput } from './KuiTextInput'

export type KuiSelectInputProps<TItemSingle> = Omit<
  KuiSelectProps<TItemSingle>,
  'renderTarget' | 'value' | 'onChange' | 'selectedItems' | 'setSelectedItems'
> &
  Pick<
    MantineInputBaseProps,
    'label' | 'description' | 'error' | 'disabled' | 'withAsterisk' | 'variant'
  > & {
    value: TItemSingle | null
    onChange: (item: TItemSingle | null) => void | Promise<void>

    placeholder?: string

    /** @default true */
    clearable?: boolean

    iconType?: KuiIconType

    inputRef?: RefCallBack

    onBlur?: FocusEventHandler

    _searchLocation?: 'input' | 'dropdown'

    _hideRightSection?: boolean
  }

export function KuiSelectInput<TItemSingle>({
  label,
  placeholder = typeof label === 'string'
    ? `Select ${label.toLowerCase()}`
    : undefined,
  description,
  error,
  disabled,
  variant,
  withAsterisk,
  clearable = true,
  value,
  parseItem,
  inputRef: consumerInputRef,
  onChange: consumerOnChange,
  onBlur,
  dropdownMinWidth,
  iconType,
  _hideRightSection = false,
  _searchLocation = 'dropdown',
  searchable = true,
  ...restProps
}: KuiSelectInputProps<TItemSingle>) {
  const [transientState, setTransientState] = useState<{
    saving: boolean
    label: string | null
  }>({ saving: false, label: null })

  const selectedItemLabel = getSelectedItemLabel()

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <KuiSelect
      {...restProps}
      searchable={searchable && _searchLocation === 'dropdown'}
      dropdownMinWidth={dropdownMinWidth}
      value={value}
      parseItem={parseItem}
      renderTarget={renderInput}
      onChange={onChange}
      _onDropdownClose={() => inputRef.current?.blur()}
    />
  )

  function renderInput({
    search,
    setSearch,
    toggleDropdown,
    _combobox,
  }: KuiSelectRenderTargetParams<TItemSingle>) {
    const inputProps = {
      label,
      description,
      placeholder,
      error,
      disabled,
      variant,
      withAsterisk,
      ...getRightSectionProps({ _combobox, setSearch }),
    }

    if (searchable && _searchLocation === 'input') {
      return (
        <KuiTextInput
          {...inputProps}
          ref={mergeRefs(consumerInputRef, inputRef)}
          iconType={iconType}
          value={search ?? selectedItemLabel ?? ''}
          onChange={(nextValue) => {
            _combobox.openDropdown()
            setSearch(nextValue)
          }}
          onClick={() => _combobox.openDropdown()}
          onFocus={() => _combobox.openDropdown()}
          onBlur={(event) => {
            _combobox.closeDropdown()
            setSearch(null)

            if (clearable && !search) {
              consumerOnChange(null)
            }

            onBlur?.(event)
          }}
        />
      )
    }

    return (
      <MantineInputBase
        {...inputProps}
        ref={consumerInputRef}
        component='button'
        type='button'
        pointer={true}
        onClick={() => toggleDropdown()}
        onBlur={onBlur}
        styles={{
          input: {
            borderColor:
              _combobox.dropdownOpened && !error
                ? 'var(--input-bd-focus)'
                : undefined,
          },
        }}
      >
        <div style={{ overflow: 'hidden' }}>
          <KuiFlex
            gapSize='xs'
            padding={{ leftSize: variant === 'unstyled' ? 'sm' : undefined }}
          >
            {iconType && <KuiIcon type={iconType} color='hushed' />}

            {selectedItemLabel ? (
              <KuiText.div wrap='no-wrap' truncate={true}>
                {selectedItemLabel}
              </KuiText.div>
            ) : (
              <MantineInput.Placeholder
                style={{ textWrap: 'nowrap', overflow: 'hidden' }}
              >
                {placeholder}
              </MantineInput.Placeholder>
            )}
          </KuiFlex>
        </div>
      </MantineInputBase>
    )
  }

  async function onChange(nextItem: TItemSingle | null) {
    const maybePromise = consumerOnChange(nextItem)

    if (maybePromise instanceof Promise) {
      try {
        setTransientState({
          saving: true,
          label: nextItem ? parseItem(nextItem).label : null,
        })
        await wrapKuiApiRequest(() => Promise.resolve(maybePromise))()
      } finally {
        setTransientState({ saving: false, label: null })
      }
    }
  }

  function getSelectedItemLabel() {
    if (transientState.saving) {
      return transientState.label
    }

    if (value) {
      return parseItem(value).label
    }

    return null
  }

  function getRightSectionProps({
    _combobox,
    setSearch,
  }: Pick<
    KuiSelectRenderTargetParams<TItemSingle>,
    '_combobox' | 'setSearch'
  >): Pick<
    MantineInputBaseProps,
    'rightSection' | 'rightSectionPointerEvents'
  > {
    if (_hideRightSection || (disabled && variant === 'content')) {
      return {
        rightSection: null,
        rightSectionPointerEvents: 'none',
      }
    }

    if (transientState.saving) {
      return {
        rightSection: <MantineLoader size={18} />,
        rightSectionPointerEvents: 'none',
      }
    }

    if (clearable && value) {
      return {
        rightSection: (
          <MantineCloseButton
            size='sm'
            aria-label='Clear value'
            onMouseDown={(event) => event.preventDefault()}
            onClick={() => {
              onChange(null)
              _combobox.closeDropdown()
              setSearch(null)
            }}
          />
        ),
        rightSectionPointerEvents: 'all',
      }
    }

    return {
      rightSection: <MantineCombobox.Chevron />,
      rightSectionPointerEvents: 'none',
    }
  }
}
