import { type ValidationRule } from 'react-hook-form'

export function kuiEmailValidator(value: string | null) {
  if (!value) {
    return true
  }

  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  if (!regex.test(value)) {
    return 'Invalid email'
  }

  return true
}

export function kuiPhoneValidator(value: string | null) {
  if (!value) {
    return true
  }

  const regex = /^[0-9]{10}$/
  if (!regex.test(value)) {
    return 'Invalid phone number'
  }

  return true
}

export function kuiUrlValidator(value: string | null): true | string {
  if (!value) {
    return true
  }

  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i
  if (!regex.test(value)) {
    return 'Invalid URL'
  }

  return true
}

export function kuiRequiredValidator(
  required: boolean
): ValidationRule<boolean> {
  return {
    value: !!required,
    message: 'Required',
  }
}

type Validator<TValue> = (value: TValue) => string | boolean

export function kuiCombineValidators<TValue>(
  validators: Array<Validator<TValue>>
): Validator<TValue> {
  return function (value) {
    for (const validator of validators) {
      const result = validator(value)

      if (result !== true) {
        return result
      }
    }

    return true
  }
}

export function newPasswordValidator(value: string | null) {
  if (!value) {
    return true
  }

  if (value.length < 8) {
    return 'Password too short'
  }

  if (!/[A-Z]/.test(value)) {
    return 'Password must contain at least one uppercase letter'
  }

  if (!/[a-z]/.test(value)) {
    return 'Password must contain at least one lowercase letter'
  }

  if (!/[0-9]/.test(value)) {
    return 'Password must contain at least one number'
  }

  if (value.toLowerCase().includes('password')) {
    return 'Password cannot contain the word "password"'
  }

  return true
}
