import { useEffect } from 'react'
import { useBlocker } from 'react-router-dom'

import { KuiModal } from './KuiModal'
import { type KuiConfirmationModalProps } from './useKuiConfirmation'

type KuiNavigationPromptProps = {
  shouldBlock: boolean
}

export function KuiNavigationPrompt({ shouldBlock }: KuiNavigationPromptProps) {
  const blocker = useBlocker(shouldBlock)

  useEffect(() => {
    let callback: (event: BeforeUnloadEvent) => void | undefined

    if (shouldBlock) {
      callback = (event: BeforeUnloadEvent) => event.preventDefault()
      window.addEventListener('beforeunload', callback)
    }

    return () => {
      if (callback) {
        window.removeEventListener('beforeunload', callback)
      }
    }
  }, [shouldBlock])

  return (
    <KuiModal
      opened={blocker.state === 'blocked'}
      onClose={onCancel}
      variant='simple'
      title='Discard unsaved changes'
      description='This will delete your current progress.'
      actions={[
        {
          variant: 'filled',
          color: 'destructive',
          label: 'Discard',
          autoClose: false,
          onClick: onProceed,
        },
      ]}
      _zIndex='confirmationModal'
    />
  )

  function onCancel() {
    blocker.reset?.()
  }

  function onProceed() {
    blocker.proceed?.()
  }
}

export const kuiNavigationPromptProps: Pick<
  KuiConfirmationModalProps,
  'title' | 'description' | 'confirmProps'
> = {
  title: 'Discard unsaved changes',
  description: 'This will delete your current progress.',
  confirmProps: {
    color: 'destructive',
    label: 'Discard',
  },
}
