/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
export type ListCommissionMatrixsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListInventoryCommissionGroupsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListCustomerCommissionGroupsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type UsersParams = {
start?: number;
page_size?: number;
search?: string;
};

export type GetProductCodesParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListTagsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type SerpImagesParams = {
query: string;
start?: number;
};

export type OrderListParams = {
start?: number;
page_size?: number;
search?: string;
statuses?: string[];
customer_ids?: string[];
asset_ids?: string[];
klass_ids?: string[];
location_ids?: string[];
is_signed?: boolean;
sources?: string[];
expires_at_start?: string;
expires_at_end?: string;
amount_min?: string;
amount_max?: string;
amount_due_min?: string;
amount_due_max?: string;
contract_starts_at?: string;
contract_ends_at?: string;
billing_starts_at?: string;
billing_ends_at?: string;
};

export type BillingProfileListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type InvoiceListParams = {
start?: number;
page_size?: number;
search?: string;
customer_ids?: string[];
order_ids?: string[];
location_ids?: string[];
types?: string[];
statuses?: string[];
issue_date_start?: string;
issue_date_end?: string;
due_date_start?: string;
due_date_end?: string;
amount_min?: string;
amount_max?: string;
amount_due_nonzero?: boolean;
};

export type DepositListParams = {
start?: number;
page_size?: number;
search?: string;
statuses?: string[];
deposit_at_start?: string;
deposit_at_end?: string;
type?: string;
amount_min?: string;
amount_max?: string;
};

export type GetPaymentSummaryReportParams = {
start_date: string;
end_date?: string;
};

export type GetAccountsReceivableReportParams = {
as_of_date?: string;
};

export type ItemReceiptListParams = {
start?: number;
page_size?: number;
search?: string;
purchase_order_ids?: string[];
posting_date?: string;
};

export type VendorBillListParams = {
start?: number;
page_size?: number;
search?: string;
purchase_order_ids?: string[];
posting_date?: string;
due_date?: string;
};

export type PurchaseOrderListParams = {
start?: number;
page_size?: number;
search?: string;
statuses?: string[];
posting_date?: string;
vendor_ids?: string[];
};

export type VendorListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type PaymentListParams = {
start?: number;
page_size?: number;
search?: string;
customer_ids?: string[];
is_reconciled?: boolean;
is_deposit_reconciled?: boolean;
payment_providers?: string[];
paid_at_start?: string;
paid_at_end?: string;
amount_min?: string;
amount_max?: string;
payment_types?: string[];
statuses?: string[];
};

export type ListKitsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type RateTemplatesParams = {
start?: number;
page_size?: number;
search?: string;
is_business_default?: boolean;
};

export type RateProfilesParams = {
start?: number;
page_size?: number;
search?: string;
};

export type UsageMetricTypesParams = {
start?: number;
page_size?: number;
search?: string;
type?: string;
};

export type GetKlassesOrderSearchParams = {
start?: number;
page_size?: number;
search?: string;
category_ids?: string[];
types?: string[];
tracking?: string;
visible_storefront?: boolean;
visible_orders?: boolean;
};

export type GetKlassesParams = {
start?: number;
page_size?: number;
search?: string;
category_ids?: string[];
types?: string[];
tracking?: string;
visible_storefront?: boolean;
visible_orders?: boolean;
};

export type AssetUsageMetricParams = {
start?: number;
page_size?: number;
};

export type AssetListParams = {
start?: number;
page_size?: number;
tracking: string;
search?: string;
types?: string[];
product_ids?: string[];
klass_ids?: string[];
category_ids?: string[];
location_ids?: string[];
};

export type AttachmentsParams = {
start?: number;
page_size?: number;
search?: string;
type: string;
asset_id?: string;
category_id?: string;
klass_id?: string;
};

export type ExpenseClassListParams = {
start?: number;
page_size?: number;
search?: string;
fees_only?: boolean;
discounts_only?: boolean;
customer_id?: string;
billing_profile_id?: string;
klass_id?: string;
kit_line_item_id?: string;
};

export type ListDeliveryTrucksParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListDeliveryDriversParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListDeliveryRoutesParams = {
start?: number;
page_size?: number;
search?: string;
delivery_date?: string;
location_id?: string;
};

export type ListDeliveryRequestsParams = {
start?: number;
page_size?: number;
location_ids?: string[];
date?: string;
is_assigned?: boolean;
};

export type DeliveryConfigurationsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type GetCustomFormsParams = {
start?: number;
page_size?: number;
label?: string;
type?: string;
};

export type CustomerGetAddressesParams = {
start?: number;
page_size?: number;
customer_id: string;
search?: string;
};

export type CustomerWithContactListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type CustomerListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListTermsAndConditionsAddendumsParams = {
start?: number;
page_size?: number;
};

export type LocationGroupListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type LocationListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListTemplatesParams = {
start?: number;
page_size?: number;
search?: string;
type?: string;
};

export type GetCategoriesParams = {
start?: number;
page_size?: number;
search?: string;
deleted_only?: boolean;
types?: string[];
};

export type JournalEntryListParams = {
start?: number;
page_size?: number;
statuses?: string[];
};

export type RemoteObjectsParams = {
start?: number;
page_size?: number;
type: string;
search?: string;
};

export type RemoteAccountsParams = {
start?: number;
page_size?: number;
search?: string;
account_number?: string;
};

export type LedgerAccountsParams = {
start?: number;
page_size?: number;
search?: string;
account_number?: string;
};

export type InventoryProfileListParams = {
start?: number;
page_size?: number;
search?: string;
asset_types?: string[];
};

export type AccountMappingsParams = {
inventory_profile_ids?: string[];
};

export type DepreciationSettingsParams = {
start?: number;
page_size?: number;
search?: string;
};

export interface CommissionMatrixCreateSchema {
  name: string;
  values: CommissionMatrixValueSchema[];
}

export type PaginatedCommissionMatrixDumpSchemaNext = number | null;

export type CommissionMatrixValueSchemaInventoryCommissionGroupId = string | null;

export type CommissionMatrixValueSchemaCustomerCommissionGroupId = string | null;

export interface CommissionMatrixValueSchema {
  customer_commission_group_id?: CommissionMatrixValueSchemaCustomerCommissionGroupId;
  inventory_commission_group_id?: CommissionMatrixValueSchemaInventoryCommissionGroupId;
  value: string;
}

export interface CommissionMatrixUpdateSchema {
  name: string;
  values: CommissionMatrixValueSchema[];
}

export interface CommissionMatrixDumpSchema {
  id: string;
  name: string;
  values: CommissionMatrixValueSchema[];
}

export interface PaginatedCommissionMatrixDumpSchema {
  elements: CommissionMatrixDumpSchema[];
  next?: PaginatedCommissionMatrixDumpSchemaNext;
  total_count: number;
}

export interface InventoryCommissionGroupUpdateSchema {
  name: string;
}

export interface InventoryCommissionGroupCreateSchema {
  name: string;
}

export interface InventoryCommissionGroupDumpSchema {
  id: string;
  name: string;
}

export interface InventoryCommissionListDumpSchema {
  elements: InventoryCommissionGroupDumpSchema[];
}

export interface CustomerCommissionGroupUpdateSchema {
  name: string;
}

export interface CustomerCommissionGroupCreateSchema {
  name: string;
}

export interface CustomerCommissionGroupDumpSchema {
  id: string;
  name: string;
}

export interface CustomerCommissionListDumpSchema {
  elements: CustomerCommissionGroupDumpSchema[];
}

export type UserLocationUpdateSchemaCurrentLocationId = string | null;

export interface UserLocationUpdateSchema {
  current_location_id?: UserLocationUpdateSchemaCurrentLocationId;
}

export interface UserForgotPasswordBodySchema {
  email: string;
}

export interface UserPasswordUpdateBodySchema {
  current_password: string;
  new_password: string;
}

export type UserUpdateSchemaPhone = string | null;

export type UserUpdateSchemaLastName = string | null;

export type UserUpdateSchemaFirstName = string | null;

export type UserUpdateSchemaDefaultLocationId = string | null;

export interface UserUpdateSchema {
  default_location_id?: UserUpdateSchemaDefaultLocationId;
  email: string;
  first_name?: UserUpdateSchemaFirstName;
  is_delivery_driver?: boolean;
  last_name?: UserUpdateSchemaLastName;
  phone?: UserUpdateSchemaPhone;
  roles: StaticRole[];
  secondary_locations: string[];
}

export type UserCreateSchemaPhone = string | null;

export type UserCreateSchemaLastName = string | null;

export type UserCreateSchemaFirstName = string | null;

export type UserCreateSchemaDefaultLocationId = string | null;

export interface UserCreateSchema {
  default_location_id?: UserCreateSchemaDefaultLocationId;
  email: string;
  first_name?: UserCreateSchemaFirstName;
  is_delivery_driver?: boolean;
  last_name?: UserCreateSchemaLastName;
  phone?: UserCreateSchemaPhone;
  roles: StaticRole[];
  secondary_locations: string[];
}

export type UserHydratedDumpSchemaPhone = string | null;

export type UserHydratedDumpSchemaLastName = string | null;

export type UserHydratedDumpSchemaFirstName = string | null;

export type UserHydratedDumpSchemaDefaultLocation = LocationDumpSchema | null;

export type UserHydratedDumpSchemaCurrentLocation = LocationDumpSchema | null;

export type StaticRole = typeof StaticRole[keyof typeof StaticRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaticRole = {
  admin: 'admin',
  manager: 'manager',
  user: 'user',
} as const;

export interface UserHydratedDumpSchema {
  current_location?: UserHydratedDumpSchemaCurrentLocation;
  default_location?: UserHydratedDumpSchemaDefaultLocation;
  email: string;
  first_name?: UserHydratedDumpSchemaFirstName;
  full_name: string;
  id: string;
  is_delivery_driver: boolean;
  last_name?: UserHydratedDumpSchemaLastName;
  permissions: PermissionType[];
  phone?: UserHydratedDumpSchemaPhone;
  roles: StaticRole[];
  secondary_locations: LocationDumpSchema[];
  username: string;
}

export type PermissionVerb = typeof PermissionVerb[keyof typeof PermissionVerb];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionVerb = {
  create: 'create',
  delete: 'delete',
  list: 'list',
  read: 'read',
  update: 'update',
} as const;

/**
 * The intention of these is to be combined with Resources

e.g. 'write' user.password implies the ability to change a user's
password

In the case of there being a permission on the resource and a
specific sub resource, the resource shall exclude the sub
resource.

e.g. 'write' user does not include 'write' user.password

The naming of these enums should be <RESOURCE>__<IDENITIFER> =
lower(<identifier>)

This is validated in tests.
 */
export type PermissionSubResource = typeof PermissionSubResource[keyof typeof PermissionSubResource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionSubResource = {
  user__password: 'user__password',
  order__customer: 'order__customer',
  order__sign: 'order__sign',
  payment__pay_invoice: 'payment__pay_invoice',
  business__pdf: 'business__pdf',
} as const;

export type PermissionTypeSubresource = PermissionSubResource | null;

export type PermissionResource = typeof PermissionResource[keyof typeof PermissionResource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionResource = {
  accounting_accounts: 'accounting_accounts',
  accounting_journal_entry: 'accounting_journal_entry',
  accounting_ledger_account: 'accounting_ledger_account',
  accounting_ledger_entry: 'accounting_ledger_entry',
  accounting_objects: 'accounting_objects',
  accounting_provider: 'accounting_provider',
  accounting_settings: 'accounting_settings',
  address: 'address',
  asset: 'asset',
  billing_profile: 'billing_profile',
  business: 'business',
  calendar: 'calendar',
  category: 'category',
  comments: 'comments',
  comms_template: 'comms_template',
  communication_preference: 'communication_preference',
  customer: 'customer',
  custom_form: 'custom_form',
  delivery: 'delivery',
  delivery_configuration: 'delivery_configuration',
  deposit: 'deposit',
  depreciation_settings: 'depreciation_settings',
  document_configuration: 'document_configuration',
  email: 'email',
  event_configuration: 'event_configuration',
  expense__expense_class: 'expense__expense_class',
  inventory__usage_metric_type: 'inventory__usage_metric_type',
  inventory_attachment: 'inventory_attachment',
  invoice: 'invoice',
  kit: 'kit',
  klass: 'klass',
  location: 'location',
  location_group: 'location_group',
  order: 'order',
  order_line_item: 'order_line_item',
  order_line_item_configuration: 'order_line_item_configuration',
  payment: 'payment',
  payment_surcharge: 'payment_surcharge',
  product: 'product',
  purchasing__purchase_order: 'purchasing__purchase_order',
  purchasing__item_receipt: 'purchasing__item_receipt',
  purchasing__vendor_bill: 'purchasing__vendor_bill',
  purchasing__vendor: 'purchasing__vendor',
  rate_profile: 'rate_profile',
  rate_template: 'rate_template',
  report: 'report',
  search: 'search',
  stripe: 'stripe',
  tag: 'tag',
  tax: 'tax',
  terms_and_conditions_addendum: 'terms_and_conditions_addendum',
  user: 'user',
  sales__customer_commission_group: 'sales__customer_commission_group',
  sales__inventory_commission_group: 'sales__inventory_commission_group',
  sales__commission_matrix: 'sales__commission_matrix',
} as const;

export interface PermissionType {
  resource: PermissionResource;
  subresource?: PermissionTypeSubresource;
  verb: PermissionVerb;
}

export type PaginatedUserSchemaNext = number | null;

export interface PaginatedUserSchema {
  elements: UserDumpSchema[];
  next?: PaginatedUserSchemaNext;
  total_count: number;
}

export type TaxConfigurationCreateSchemaTaxLiabilityAccountId = string | null;

export type TaxConfigurationCreateSchemaDescription = string | null;

export interface TaxConfigurationCreateSchema {
  country_code: CountryEnum;
  description?: TaxConfigurationCreateSchemaDescription;
  name: string;
  rate: string;
  source: TaxSourceEnum;
  state_code: StateEnum;
  sub_configurations: TaxConfigurationChildCreateSchema[];
  tax_liability_account_id?: TaxConfigurationCreateSchemaTaxLiabilityAccountId;
  type: TaxTypeEnum;
}

export interface ProductTaxCodeGroupUpdateSchema {
  configurations: TaxConfigurationCreateSchema[];
  is_business_default: boolean;
  name: string;
}

export interface ProductTaxCodeGroupCreateSchema {
  configurations: TaxConfigurationCreateSchema[];
  is_business_default: boolean;
  name: string;
}

export type TaxConfigurationChildCreateSchemaTaxLiabilityAccountId = string | null;

export interface TaxConfigurationChildCreateSchema {
  name: string;
  rate: string;
  tax_liability_account_id?: TaxConfigurationChildCreateSchemaTaxLiabilityAccountId;
  zip_codes: string[];
}

export type PaginatedProductTaxCodeGroupDumpSchemaNext = number | null;

export interface PaginatedProductTaxCodeGroupDumpSchema {
  elements: ProductTaxCodeGroupDumpSchema[];
  next?: PaginatedProductTaxCodeGroupDumpSchemaNext;
  total_count: number;
}

export type PaginatedTagSchemaNext = number | null;

export interface TagDumpSchema {
  id: string;
  name: string;
}

export interface PaginatedTagSchema {
  elements: TagDumpSchema[];
  next?: PaginatedTagSchemaNext;
  total_count: number;
}

export interface StripeActiveSchema {
  active: boolean;
}

export interface StripeOnboardingSchema {
  url: string;
}

export type SerpImageResponseSchemaPrev = number | null;

export interface SerpImage {
  original: string;
  thumbnail: string;
}

export interface SerpImageResponseSchema {
  images: SerpImage[];
  next: number;
  prev?: SerpImageResponseSchemaPrev;
}

export type SearchResponseSchemaNext = number | null;

export type VendorSearchObjectType = typeof VendorSearchObjectType[keyof typeof VendorSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorSearchObjectType = {
  vendor: 'vendor',
} as const;

export interface VendorSearchObject {
  id: string;
  obj: VendorDumpSchema;
  rank: number;
  type: VendorSearchObjectType;
}

export type ProductSearchObjectType = typeof ProductSearchObjectType[keyof typeof ProductSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductSearchObjectType = {
  product: 'product',
} as const;

export interface ProductSearchObject {
  id: string;
  obj: ProductDumpSchema;
  rank: number;
  type: ProductSearchObjectType;
}

export type OrderSearchObjectType = typeof OrderSearchObjectType[keyof typeof OrderSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderSearchObjectType = {
  order: 'order',
} as const;

export interface OrderSearchObject {
  id: string;
  obj: OrderDumpSchema;
  rank: number;
  type: OrderSearchObjectType;
}

export type LocationSearchObjectType = typeof LocationSearchObjectType[keyof typeof LocationSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocationSearchObjectType = {
  location: 'location',
} as const;

export interface LocationSearchObject {
  id: string;
  obj: LocationDumpSchema;
  rank: number;
  type: LocationSearchObjectType;
}

export type KlassSearchObjectType = typeof KlassSearchObjectType[keyof typeof KlassSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KlassSearchObjectType = {
  klass: 'klass',
} as const;

export interface KlassSearchObject {
  id: string;
  obj: KlassDumpSchema;
  rank: number;
  type: KlassSearchObjectType;
}

export type CustomerSearchObjectType = typeof CustomerSearchObjectType[keyof typeof CustomerSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerSearchObjectType = {
  customer: 'customer',
} as const;

export interface CustomerSearchObject {
  id: string;
  obj: CustomerDumpSchema;
  rank: number;
  type: CustomerSearchObjectType;
}

export type CategorySearchObjectType = typeof CategorySearchObjectType[keyof typeof CategorySearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategorySearchObjectType = {
  category: 'category',
} as const;

export interface CategorySearchObject {
  id: string;
  obj: CategoryDumpSchema;
  rank: number;
  type: CategorySearchObjectType;
}

export type SearchResponseSchemaElementsItem = OrderSearchObject | VendorSearchObject | CustomerSearchObject | CategorySearchObject | KlassSearchObject | AssetSearchObject | LocationSearchObject | ProductSearchObject;

export interface SearchResponseSchema {
  elements: SearchResponseSchemaElementsItem[];
  next?: SearchResponseSchemaNext;
  total_count: number;
}

export type AssetSearchObjectType = typeof AssetSearchObjectType[keyof typeof AssetSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetSearchObjectType = {
  asset: 'asset',
} as const;

export interface AssetSearchObject {
  id: string;
  obj: AssetDumpSchema;
  rank: number;
  type: AssetSearchObjectType;
}

export type SearchObjects = typeof SearchObjects[keyof typeof SearchObjects];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchObjects = {
  asset: 'asset',
  klass: 'klass',
  category: 'category',
  customer: 'customer',
  vendor: 'vendor',
  order: 'order',
  location: 'location',
  product: 'product',
} as const;

export type SearchRequestSchemaObjects = SearchObjects[] | null;

export interface SearchRequestSchema {
  objects?: SearchRequestSchemaObjects;
  search: string;
}

export type DeliveryRouteHydratedStopDumpSchemaStop = DeliveryRouteBranchStopDumpSchema | DeliveryRouteCustomerStopDumpSchema;

export interface DeliveryRouteHydratedStopDumpSchema {
  route: DeliveryRouteDumpSchema;
  stop: DeliveryRouteHydratedStopDumpSchemaStop;
}

export interface OrderDispatchDumpSchema {
  stops: DeliveryRouteHydratedStopDumpSchema[];
}

export interface OrderSignatureSchema {
  signed_by: string;
}

export type OrderExternalDumpSchemaSignedByIp = string | null;

export type OrderExternalDumpSchemaSignedBy = string | null;

export type OrderExternalDumpSchemaSignedAt = string | null;

export type OrderExternalDumpSchemaSecondarySalesRepId = string | null;

export type OrderExternalDumpSchemaSecondarySalesRep = UserDumpSchema | null;

export type OrderExternalDumpSchemaSalesRepId = string | null;

export type OrderExternalDumpSchemaSalesRep = UserDumpSchema | null;

export type OrderExternalDumpSchemaSaleTaxRate = string | null;

export type OrderExternalDumpSchemaRentalTaxRate = string | null;

export type OrderExternalDumpSchemaPaymentMethodId = string | null;

export type OrderExternalDumpSchemaLineItemsItem = RentalLineItemExternalDumpSchema | SaleLineItemExternalDumpSchema;

export type OrderExternalDumpSchemaInvoiceDueNow = LinkIDSchema | null;

export type OrderExternalDumpSchemaInternalNotes = string | null;

export type OrderExternalDumpSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

export type OrderExternalDumpSchemaExternalNotes = string | null;

export type OrderExternalDumpSchemaCustomerPoNumber = string | null;

export type OrderExternalDumpSchemaCustomerJobSiteNumber = string | null;

export type OrderExternalDumpSchemaCustomerId = string | null;

export type OrderExternalDumpSchemaCustomerContactId = string | null;

export type OrderExternalDumpSchemaCustomerContact = CustomerContactHydratedDumpSchema | null;

export type OrderExternalDumpSchemaCustomer = CustomerHydratedDumpSchema | null;

export type OrderExternalDumpSchemaContractEndsAt = string | null;

export type OrderExternalDumpSchemaBusinessTermsAndConditionsAnyOfItem = {};

export type OrderExternalDumpSchemaBusinessTermsAndConditions = OrderExternalDumpSchemaBusinessTermsAndConditionsAnyOfItem[] | null;

export type OrderExternalDumpSchemaBusinessLogoUrl = string | null;

export type OrderExternalDumpSchemaBillingProfileId = string | null;

export type OrderExternalDumpSchemaBillingEndsAt = string | null;

export type OrderExternalDumpSchemaBillingAddress = AddressDumpSchema | null;

export interface OrderExternalDumpSchema {
  activity_log_id: string;
  amount: string;
  amount_billed: string;
  amount_due: string;
  amount_paid: string;
  autogenerated_identifier: string;
  billing_address?: OrderExternalDumpSchemaBillingAddress;
  billing_ends_at?: OrderExternalDumpSchemaBillingEndsAt;
  billing_profile_id?: OrderExternalDumpSchemaBillingProfileId;
  billing_starts_at: string;
  business_logo_url?: OrderExternalDumpSchemaBusinessLogoUrl;
  business_name: string;
  business_terms_and_conditions?: OrderExternalDumpSchemaBusinessTermsAndConditions;
  contract_ends_at?: OrderExternalDumpSchemaContractEndsAt;
  contract_starts_at: string;
  customer?: OrderExternalDumpSchemaCustomer;
  customer_contact?: OrderExternalDumpSchemaCustomerContact;
  customer_contact_id?: OrderExternalDumpSchemaCustomerContactId;
  customer_id?: OrderExternalDumpSchemaCustomerId;
  customer_job_site_number?: OrderExternalDumpSchemaCustomerJobSiteNumber;
  customer_po_number?: OrderExternalDumpSchemaCustomerPoNumber;
  delivery_requests: DeliveryRequestSlimDumpSchema[];
  delivery_subtotal: string;
  expires_at: string;
  external_notes?: OrderExternalDumpSchemaExternalNotes;
  future_invoice_payment_method?: OrderExternalDumpSchemaFutureInvoicePaymentMethod;
  has_amount_owed: boolean;
  id: string;
  internal_notes?: OrderExternalDumpSchemaInternalNotes;
  invoice_due_now?: OrderExternalDumpSchemaInvoiceDueNow;
  invoices: InvoiceExternalDumpSchema[];
  line_items: OrderExternalDumpSchemaLineItemsItem[];
  location: LocationDumpSchema;
  location_id: string;
  modifiers: ModifierDumpSchema[];
  payment_method_id?: OrderExternalDumpSchemaPaymentMethodId;
  rental_tax_rate?: OrderExternalDumpSchemaRentalTaxRate;
  rental_tax_subtotal: string;
  sale_tax_rate?: OrderExternalDumpSchemaSaleTaxRate;
  sale_tax_subtotal: string;
  sales_rep?: OrderExternalDumpSchemaSalesRep;
  sales_rep_id?: OrderExternalDumpSchemaSalesRepId;
  secondary_sales_rep?: OrderExternalDumpSchemaSecondarySalesRep;
  secondary_sales_rep_id?: OrderExternalDumpSchemaSecondarySalesRepId;
  signed_at?: OrderExternalDumpSchemaSignedAt;
  signed_by?: OrderExternalDumpSchemaSignedBy;
  signed_by_ip?: OrderExternalDumpSchemaSignedByIp;
  source: OrderSource;
  status: OrderStatus;
  summary: OrderSummarySection[];
  tax_type: TaxType;
  terms_and_conditions_addendums: TermsAndConditionsAddendumDumpSchema[];
}

export type SaleLineItemExternalDumpSchemaType = typeof SaleLineItemExternalDumpSchemaType[keyof typeof SaleLineItemExternalDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaleLineItemExternalDumpSchemaType = {
  sale: 'sale',
} as const;

export type SaleLineItemExternalDumpSchemaNotes = string | null;

export type SaleLineItemExternalDumpSchemaExternalDescription = string | null;

export type SaleLineItemExternalDumpSchemaContractStartedAt = string | null;

export type SaleLineItemExternalDumpSchemaBillingStartedAt = string | null;

export type SaleLineItemExternalDumpSchemaAssetId = string | null;

export type SaleLineItemExternalDumpSchemaAssetCustomerIdentifier = string | null;

export interface SaleLineItemExternalDumpSchema {
  amount_per_unit: string;
  asset_customer_identifier?: SaleLineItemExternalDumpSchemaAssetCustomerIdentifier;
  asset_id?: SaleLineItemExternalDumpSchemaAssetId;
  assets: AssetExternalDumpSchema[];
  base_amount_per_unit: string;
  billing_started_at?: SaleLineItemExternalDumpSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  contract_started_at?: SaleLineItemExternalDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  external_description?: SaleLineItemExternalDumpSchemaExternalDescription;
  id: string;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  modifiers?: ModifierDumpSchema[];
  notes?: SaleLineItemExternalDumpSchemaNotes;
  parent_client_id: string;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  source: LineItemSource;
  status: LineItemStatus;
  type: SaleLineItemExternalDumpSchemaType;
}

export type RentalLineItemExternalDumpSchemaType = typeof RentalLineItemExternalDumpSchemaType[keyof typeof RentalLineItemExternalDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RentalLineItemExternalDumpSchemaType = {
  rental: 'rental',
} as const;

export type RentalLineItemExternalDumpSchemaShiftId = string | null;

export type RentalLineItemExternalDumpSchemaShift = ShiftDumpSchema | null;

export type RentalLineItemExternalDumpSchemaNotes = string | null;

export type RentalLineItemExternalDumpSchemaExternalDescription = string | null;

export type RentalLineItemExternalDumpSchemaContractStartedAt = string | null;

export type RentalLineItemExternalDumpSchemaContractEndedAt = string | null;

export type RentalLineItemExternalDumpSchemaBillingStartedAt = string | null;

export type RentalLineItemExternalDumpSchemaBillingEndedAt = string | null;

export type RentalLineItemExternalDumpSchemaAssetId = string | null;

export type RentalLineItemExternalDumpSchemaAssetCustomerIdentifier = string | null;

export interface RentalLineItemExternalDumpSchema {
  amount_per_unit: string;
  asset_customer_identifier?: RentalLineItemExternalDumpSchemaAssetCustomerIdentifier;
  asset_id?: RentalLineItemExternalDumpSchemaAssetId;
  assets: AssetExternalDumpSchema[];
  base_amount_per_unit: string;
  billing_ended_at?: RentalLineItemExternalDumpSchemaBillingEndedAt;
  billing_ends_at: string;
  billing_started_at?: RentalLineItemExternalDumpSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  contract_ended_at?: RentalLineItemExternalDumpSchemaContractEndedAt;
  contract_ends_at: string;
  contract_started_at?: RentalLineItemExternalDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  external_description?: RentalLineItemExternalDumpSchemaExternalDescription;
  id: string;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  modifiers?: ModifierDumpSchema[];
  notes?: RentalLineItemExternalDumpSchemaNotes;
  parent_client_id: string;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  shift?: RentalLineItemExternalDumpSchemaShift;
  shift_id?: RentalLineItemExternalDumpSchemaShiftId;
  source: LineItemSource;
  status: LineItemStatus;
  type: RentalLineItemExternalDumpSchemaType;
}

export type DeliveryRequestSlimDumpSchemaToWindow = DateTimeWindowSchema | null;

export type DeliveryRequestSlimDumpSchemaTaxRate = string | null;

export type DeliveryRequestSlimDumpSchemaTaxGroupId = string | null;

export type DeliveryRequestSlimDumpSchemaFromWindow = DateTimeWindowSchema | null;

export type DeliveryRequestSlimDumpSchemaDeliverySecondaryRate = string | null;

export type DeliveryRequestSlimDumpSchemaBillingProfileId = string | null;

export interface DeliveryRequestSlimDumpSchema {
  amount: string;
  billing_profile_id?: DeliveryRequestSlimDumpSchemaBillingProfileId;
  charge_at: string;
  delivery_address: DeliveryAddressDumpSchema;
  delivery_address_id: string;
  delivery_configuration_id: string;
  delivery_rate: string;
  delivery_secondary_rate?: DeliveryRequestSlimDumpSchemaDeliverySecondaryRate;
  direction: DeliveryRequestDirection;
  from_address: AddressDumpSchema;
  from_address_id: string;
  from_window?: DeliveryRequestSlimDumpSchemaFromWindow;
  tax_group_id?: DeliveryRequestSlimDumpSchemaTaxGroupId;
  tax_rate?: DeliveryRequestSlimDumpSchemaTaxRate;
  to_address: AddressDumpSchema;
  to_address_id: string;
  to_window?: DeliveryRequestSlimDumpSchemaToWindow;
}

export type OrderReceivingDumpSchemaSignedByIp = string | null;

export type OrderReceivingDumpSchemaSignedBy = string | null;

export type OrderReceivingDumpSchemaSignedAt = string | null;

export type OrderReceivingDumpSchemaSecondarySalesRepId = string | null;

export type OrderReceivingDumpSchemaSalesRepId = string | null;

export type OrderReceivingDumpSchemaSaleTaxRate = string | null;

export type OrderReceivingDumpSchemaRentalTaxRate = string | null;

export type OrderReceivingDumpSchemaPaymentMethodId = string | null;

export type OrderReceivingDumpSchemaInternalNotes = string | null;

export type OrderReceivingDumpSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

export type OrderReceivingDumpSchemaExternalNotes = string | null;

export type OrderReceivingDumpSchemaCustomerPoNumber = string | null;

export type OrderReceivingDumpSchemaCustomerJobSiteNumber = string | null;

export type OrderReceivingDumpSchemaCustomerId = string | null;

export type OrderReceivingDumpSchemaCustomerContactId = string | null;

export type OrderReceivingDumpSchemaContractEndsAt = string | null;

export type OrderReceivingDumpSchemaBillingProfileId = string | null;

export type OrderReceivingDumpSchemaBillingEndsAt = string | null;

export interface OrderReceivingDumpSchema {
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  billing_ends_at?: OrderReceivingDumpSchemaBillingEndsAt;
  billing_profile_id?: OrderReceivingDumpSchemaBillingProfileId;
  billing_starts_at: string;
  contract_ends_at?: OrderReceivingDumpSchemaContractEndsAt;
  contract_starts_at: string;
  customer_contact_id?: OrderReceivingDumpSchemaCustomerContactId;
  customer_id?: OrderReceivingDumpSchemaCustomerId;
  customer_job_site_number?: OrderReceivingDumpSchemaCustomerJobSiteNumber;
  customer_po_number?: OrderReceivingDumpSchemaCustomerPoNumber;
  expires_at: string;
  external_notes?: OrderReceivingDumpSchemaExternalNotes;
  future_invoice_payment_method?: OrderReceivingDumpSchemaFutureInvoicePaymentMethod;
  has_amount_owed: boolean;
  id: string;
  internal_notes?: OrderReceivingDumpSchemaInternalNotes;
  line_items: LineItemReceivingDumpSchema[];
  location_id: string;
  payment_method_id?: OrderReceivingDumpSchemaPaymentMethodId;
  rental_tax_rate?: OrderReceivingDumpSchemaRentalTaxRate;
  sale_tax_rate?: OrderReceivingDumpSchemaSaleTaxRate;
  sales_rep_id?: OrderReceivingDumpSchemaSalesRepId;
  secondary_sales_rep_id?: OrderReceivingDumpSchemaSecondarySalesRepId;
  signed_at?: OrderReceivingDumpSchemaSignedAt;
  signed_by?: OrderReceivingDumpSchemaSignedBy;
  signed_by_ip?: OrderReceivingDumpSchemaSignedByIp;
  source: OrderSource;
  status: OrderStatus;
  tax_type: TaxType;
}

export type LineItemReceivingDumpSchemaAssetId = string | null;

export type LineItemReceivingDumpSchemaAsset = AssetHydratedDumpSchema | null;

export interface LineItemReceivingDumpSchema {
  asset?: LineItemReceivingDumpSchemaAsset;
  asset_id?: LineItemReceivingDumpSchemaAssetId;
  id: string;
  klass: KlassDumpSchema;
  klass_id: string;
  quantity: number;
}

export type OrderUpdateSchemaSecondarySalesRepId = string | null;

export type OrderUpdateSchemaSalesRepId = string | null;

export type OrderUpdateSchemaSaleTaxRate = string | null;

export type OrderUpdateSchemaRentalTaxRate = string | null;

export type OrderUpdateSchemaPaymentMethodId = string | null;

export type OrderUpdateSchemaLineItemsItem = LineItemRentalUpdateSchema | LineItemSaleUpdateSchema;

export type OrderUpdateSchemaInternalNotes = string | null;

export type OrderUpdateSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

export type OrderUpdateSchemaExternalNotes = string | null;

export type OrderUpdateSchemaExpiresAt = string | null;

export type OrderUpdateSchemaDelivery = DeliveryPickupSchema | DeliveryDropoffSchema;

export type OrderUpdateSchemaCustomerPoNumber = string | null;

export type OrderUpdateSchemaCustomerJobSiteNumber = string | null;

export type OrderUpdateSchemaCustomerId = string | null;

export type OrderUpdateSchemaCustomerContactId = string | null;

export type OrderUpdateSchemaBillingProfileId = string | null;

export interface OrderUpdateSchema {
  billing_profile_id?: OrderUpdateSchemaBillingProfileId;
  customer_contact_id?: OrderUpdateSchemaCustomerContactId;
  customer_id?: OrderUpdateSchemaCustomerId;
  customer_job_site_number?: OrderUpdateSchemaCustomerJobSiteNumber;
  customer_po_number?: OrderUpdateSchemaCustomerPoNumber;
  delivery: OrderUpdateSchemaDelivery;
  expires_at?: OrderUpdateSchemaExpiresAt;
  external_notes?: OrderUpdateSchemaExternalNotes;
  future_invoice_payment_method?: OrderUpdateSchemaFutureInvoicePaymentMethod;
  internal_notes?: OrderUpdateSchemaInternalNotes;
  line_items: OrderUpdateSchemaLineItemsItem[];
  location_id: string;
  modifiers: ModifierSchema[];
  payment_method_id?: OrderUpdateSchemaPaymentMethodId;
  rental_tax_rate?: OrderUpdateSchemaRentalTaxRate;
  sale_tax_rate?: OrderUpdateSchemaSaleTaxRate;
  sales_rep_id?: OrderUpdateSchemaSalesRepId;
  secondary_sales_rep_id?: OrderUpdateSchemaSecondarySalesRepId;
  source: OrderSource;
  status: OrderStatus;
  tags: string[];
  tax_type: TaxType;
}

export type ReceiveLineItemSchemaReceivingNotes = string | null;

export interface ReceiveLineItemSchema {
  receiving_attachments: string[];
  receiving_notes?: ReceiveLineItemSchemaReceivingNotes;
  usage_metric_values: UsageMetricValueCreateSchema[];
}

export type LineItemSaleUpdateSchemaType = typeof LineItemSaleUpdateSchemaType[keyof typeof LineItemSaleUpdateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemSaleUpdateSchemaType = {
  sale: 'sale',
} as const;

export type LineItemSaleUpdateSchemaReceiving = ReceiveLineItemSchema | null;

export type LineItemSaleUpdateSchemaNotes = string | null;

export type LineItemSaleUpdateSchemaId = string | null;

export type LineItemSaleUpdateSchemaContractStartedAt = string | null;

export type LineItemSaleUpdateSchemaBillingStartedAt = string | null;

export type LineItemSaleUpdateSchemaAssetId = string | null;

export interface LineItemSaleUpdateSchema {
  asset_id?: LineItemSaleUpdateSchemaAssetId;
  billing_started_at?: LineItemSaleUpdateSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  contract_started_at?: LineItemSaleUpdateSchemaContractStartedAt;
  contract_starts_at: string;
  id?: LineItemSaleUpdateSchemaId;
  is_ready_for_receiving?: boolean;
  klass_id: string;
  modifiers: ModifierSchema[];
  notes?: LineItemSaleUpdateSchemaNotes;
  parent_client_id: string;
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  receiving?: LineItemSaleUpdateSchemaReceiving;
  source?: LineItemSource;
  type: LineItemSaleUpdateSchemaType;
}

export type LineItemRentalUpdateSchemaType = typeof LineItemRentalUpdateSchemaType[keyof typeof LineItemRentalUpdateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemRentalUpdateSchemaType = {
  rental: 'rental',
} as const;

export type LineItemRentalUpdateSchemaShiftId = string | null;

export type LineItemRentalUpdateSchemaReceiving = ReceiveLineItemSchema | null;

export type LineItemRentalUpdateSchemaNotes = string | null;

export type LineItemRentalUpdateSchemaId = string | null;

export type LineItemRentalUpdateSchemaContractStartedAt = string | null;

export type LineItemRentalUpdateSchemaContractEndedAt = string | null;

export type LineItemRentalUpdateSchemaBillingStartedAt = string | null;

export type LineItemRentalUpdateSchemaBillingEndedAt = string | null;

export type LineItemRentalUpdateSchemaAssetId = string | null;

export interface LineItemRentalUpdateSchema {
  asset_id?: LineItemRentalUpdateSchemaAssetId;
  billing_ended_at?: LineItemRentalUpdateSchemaBillingEndedAt;
  billing_ends_at: string;
  billing_started_at?: LineItemRentalUpdateSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  contract_ended_at?: LineItemRentalUpdateSchemaContractEndedAt;
  contract_ends_at: string;
  contract_started_at?: LineItemRentalUpdateSchemaContractStartedAt;
  contract_starts_at: string;
  id?: LineItemRentalUpdateSchemaId;
  is_ready_for_receiving?: boolean;
  klass_id: string;
  modifiers: ModifierSchema[];
  notes?: LineItemRentalUpdateSchemaNotes;
  parent_client_id: string;
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  receiving?: LineItemRentalUpdateSchemaReceiving;
  shift_id?: LineItemRentalUpdateSchemaShiftId;
  source?: LineItemSource;
  type: LineItemRentalUpdateSchemaType;
}

export type OrderCreateSchemaSecondarySalesRepId = string | null;

export type OrderCreateSchemaSalesRepId = string | null;

export type OrderCreateSchemaSaleTaxRate = string | null;

export type OrderCreateSchemaRentalTaxRate = string | null;

export type OrderCreateSchemaPaymentMethodId = string | null;

export type OrderCreateSchemaLineItemsItem = LineItemRentalCreateSchema | LineItemSaleCreateSchema;

export type OrderCreateSchemaInternalNotes = string | null;

export type OrderCreateSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

export type OrderCreateSchemaExternalNotes = string | null;

export type OrderCreateSchemaExpiresAt = string | null;

export type OrderCreateSchemaDelivery = DeliveryPickupSchema | DeliveryDropoffSchema;

export type OrderCreateSchemaCustomerPoNumber = string | null;

export type OrderCreateSchemaCustomerJobSiteNumber = string | null;

export type OrderCreateSchemaCustomerId = string | null;

export type OrderCreateSchemaCustomerContactId = string | null;

export type OrderCreateSchemaBillingProfileId = string | null;

export interface OrderCreateSchema {
  billing_profile_id?: OrderCreateSchemaBillingProfileId;
  customer_contact_id?: OrderCreateSchemaCustomerContactId;
  customer_id?: OrderCreateSchemaCustomerId;
  customer_job_site_number?: OrderCreateSchemaCustomerJobSiteNumber;
  customer_po_number?: OrderCreateSchemaCustomerPoNumber;
  delivery: OrderCreateSchemaDelivery;
  expires_at?: OrderCreateSchemaExpiresAt;
  external_notes?: OrderCreateSchemaExternalNotes;
  future_invoice_payment_method?: OrderCreateSchemaFutureInvoicePaymentMethod;
  internal_notes?: OrderCreateSchemaInternalNotes;
  line_items: OrderCreateSchemaLineItemsItem[];
  location_id: string;
  modifiers: ModifierSchema[];
  payment_method_id?: OrderCreateSchemaPaymentMethodId;
  rental_tax_rate?: OrderCreateSchemaRentalTaxRate;
  sale_tax_rate?: OrderCreateSchemaSaleTaxRate;
  sales_rep_id?: OrderCreateSchemaSalesRepId;
  secondary_sales_rep_id?: OrderCreateSchemaSecondarySalesRepId;
  source: OrderSource;
  status: OrderStatus;
  tags: string[];
  tax_type: TaxType;
}

export type DeliveryDropoffSchemaType = typeof DeliveryDropoffSchemaType[keyof typeof DeliveryDropoffSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryDropoffSchemaType = {
  delivery: 'delivery',
} as const;

export type DeliveryDropoffSchemaPickupWindow = DateTimeWindowSchema | null;

export type DeliveryDropoffSchemaDropoffWindow = DateTimeWindowSchema | null;

export type DeliveryDropoffSchemaDeliveryTaxRate = string | null;

export type DeliveryDropoffSchemaDeliverySecondaryRate = string | null;

export type DeliveryDropoffSchemaDeliveryMinimum = string | null;

export type DeliveryDropoffSchemaDeliveryAddressId = string | null;

export interface DeliveryDropoffSchema {
  delivery_address_id?: DeliveryDropoffSchemaDeliveryAddressId;
  delivery_configuration_id: string;
  delivery_minimum?: DeliveryDropoffSchemaDeliveryMinimum;
  delivery_rate: string;
  delivery_secondary_rate?: DeliveryDropoffSchemaDeliverySecondaryRate;
  delivery_tax_rate?: DeliveryDropoffSchemaDeliveryTaxRate;
  dropoff: boolean;
  dropoff_window?: DeliveryDropoffSchemaDropoffWindow;
  location_id: string;
  modifiers: ModifierSchema[];
  pickup: boolean;
  pickup_window?: DeliveryDropoffSchemaPickupWindow;
  type: DeliveryDropoffSchemaType;
}

export type PartialOrderCreateSchemaSecondarySalesRepId = string | null;

export type PartialOrderCreateSchemaSalesRepId = string | null;

export type PartialOrderCreateSchemaSaleTaxRate = string | null;

export type PartialOrderCreateSchemaRentalTaxRate = string | null;

export type PartialOrderCreateSchemaPaymentMethodId = string | null;

export type PartialOrderCreateSchemaLineItemsItem = LineItemRentalCreateSchema | LineItemSaleCreateSchema;

export type PartialOrderCreateSchemaInternalNotes = string | null;

export type PartialOrderCreateSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

export type PartialOrderCreateSchemaExternalNotes = string | null;

export type PartialOrderCreateSchemaExpiresAt = string | null;

export type PartialOrderCreateSchemaDelivery = PartialDeliveryDropoffSchema | DeliveryPickupSchema;

export type PartialOrderCreateSchemaCustomerPoNumber = string | null;

export type PartialOrderCreateSchemaCustomerJobSiteNumber = string | null;

export type PartialOrderCreateSchemaCustomerId = string | null;

export type PartialOrderCreateSchemaCustomerContactId = string | null;

export type PartialOrderCreateSchemaBillingProfileId = string | null;

export interface PartialOrderCreateSchema {
  billing_profile_id?: PartialOrderCreateSchemaBillingProfileId;
  customer_contact_id?: PartialOrderCreateSchemaCustomerContactId;
  customer_id?: PartialOrderCreateSchemaCustomerId;
  customer_job_site_number?: PartialOrderCreateSchemaCustomerJobSiteNumber;
  customer_po_number?: PartialOrderCreateSchemaCustomerPoNumber;
  delivery: PartialOrderCreateSchemaDelivery;
  expires_at?: PartialOrderCreateSchemaExpiresAt;
  external_notes?: PartialOrderCreateSchemaExternalNotes;
  future_invoice_payment_method?: PartialOrderCreateSchemaFutureInvoicePaymentMethod;
  internal_notes?: PartialOrderCreateSchemaInternalNotes;
  line_items: PartialOrderCreateSchemaLineItemsItem[];
  location_id: string;
  modifiers: ModifierSchema[];
  payment_method_id?: PartialOrderCreateSchemaPaymentMethodId;
  rental_tax_rate?: PartialOrderCreateSchemaRentalTaxRate;
  sale_tax_rate?: PartialOrderCreateSchemaSaleTaxRate;
  sales_rep_id?: PartialOrderCreateSchemaSalesRepId;
  secondary_sales_rep_id?: PartialOrderCreateSchemaSecondarySalesRepId;
  source: OrderSource;
  status: OrderStatus;
  tags: string[];
  tax_type: TaxType;
}

export type PartialDeliveryDropoffSchemaType = typeof PartialDeliveryDropoffSchemaType[keyof typeof PartialDeliveryDropoffSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartialDeliveryDropoffSchemaType = {
  delivery: 'delivery',
} as const;

export type PartialDeliveryDropoffSchemaPickupWindow = DateTimeWindowSchema | null;

export type PartialDeliveryDropoffSchemaPickup = boolean | null;

export type PartialDeliveryDropoffSchemaDropoffWindow = DateTimeWindowSchema | null;

export type PartialDeliveryDropoffSchemaDropoff = boolean | null;

export type PartialDeliveryDropoffSchemaDeliveryTaxRate = string | null;

export type PartialDeliveryDropoffSchemaDeliverySecondaryRate = string | null;

export type PartialDeliveryDropoffSchemaDeliveryRate = string | null;

export type PartialDeliveryDropoffSchemaDeliveryMinimum = string | null;

export type PartialDeliveryDropoffSchemaDeliveryConfigurationId = string | null;

export type PartialDeliveryDropoffSchemaDeliveryAddressId = string | null;

export interface PartialDeliveryDropoffSchema {
  delivery_address_id?: PartialDeliveryDropoffSchemaDeliveryAddressId;
  delivery_configuration_id?: PartialDeliveryDropoffSchemaDeliveryConfigurationId;
  delivery_minimum?: PartialDeliveryDropoffSchemaDeliveryMinimum;
  delivery_rate?: PartialDeliveryDropoffSchemaDeliveryRate;
  delivery_secondary_rate?: PartialDeliveryDropoffSchemaDeliverySecondaryRate;
  delivery_tax_rate?: PartialDeliveryDropoffSchemaDeliveryTaxRate;
  dropoff?: PartialDeliveryDropoffSchemaDropoff;
  dropoff_window?: PartialDeliveryDropoffSchemaDropoffWindow;
  location_id: string;
  modifiers: ModifierSchema[];
  pickup?: PartialDeliveryDropoffSchemaPickup;
  pickup_window?: PartialDeliveryDropoffSchemaPickupWindow;
  type: PartialDeliveryDropoffSchemaType;
}

export type LineItemSaleCreateSchemaType = typeof LineItemSaleCreateSchemaType[keyof typeof LineItemSaleCreateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemSaleCreateSchemaType = {
  sale: 'sale',
} as const;

export type LineItemSaleCreateSchemaNotes = string | null;

export type LineItemSaleCreateSchemaContractStartedAt = string | null;

export type LineItemSaleCreateSchemaBillingStartedAt = string | null;

export type LineItemSaleCreateSchemaAssetId = string | null;

export interface LineItemSaleCreateSchema {
  asset_id?: LineItemSaleCreateSchemaAssetId;
  billing_started_at?: LineItemSaleCreateSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  contract_started_at?: LineItemSaleCreateSchemaContractStartedAt;
  contract_starts_at: string;
  is_ready_for_receiving?: boolean;
  klass_id: string;
  modifiers: ModifierSchema[];
  notes?: LineItemSaleCreateSchemaNotes;
  parent_client_id: string;
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  source?: LineItemSource;
  type: LineItemSaleCreateSchemaType;
}

export type LineItemRentalCreateSchemaType = typeof LineItemRentalCreateSchemaType[keyof typeof LineItemRentalCreateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemRentalCreateSchemaType = {
  rental: 'rental',
} as const;

export type LineItemRentalCreateSchemaShiftId = string | null;

export type LineItemRentalCreateSchemaNotes = string | null;

export type LineItemRentalCreateSchemaContractStartedAt = string | null;

export type LineItemRentalCreateSchemaContractEndedAt = string | null;

export type LineItemRentalCreateSchemaBillingStartedAt = string | null;

export type LineItemRentalCreateSchemaBillingEndedAt = string | null;

export type LineItemRentalCreateSchemaAssetId = string | null;

export interface LineItemRentalCreateSchema {
  asset_id?: LineItemRentalCreateSchemaAssetId;
  billing_ended_at?: LineItemRentalCreateSchemaBillingEndedAt;
  billing_ends_at: string;
  billing_started_at?: LineItemRentalCreateSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  contract_ended_at?: LineItemRentalCreateSchemaContractEndedAt;
  contract_ends_at: string;
  contract_started_at?: LineItemRentalCreateSchemaContractStartedAt;
  contract_starts_at: string;
  is_ready_for_receiving?: boolean;
  klass_id: string;
  modifiers: ModifierSchema[];
  notes?: LineItemRentalCreateSchemaNotes;
  parent_client_id: string;
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  shift_id?: LineItemRentalCreateSchemaShiftId;
  source?: LineItemSource;
  type: LineItemRentalCreateSchemaType;
}

export type DeliveryPickupSchemaType = typeof DeliveryPickupSchemaType[keyof typeof DeliveryPickupSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryPickupSchemaType = {
  in_store: 'in_store',
} as const;

export interface DeliveryPickupSchema {
  type: DeliveryPickupSchemaType;
}

export type OrderHydratedDumpSchemaSignedByIp = string | null;

export type OrderHydratedDumpSchemaSignedBy = string | null;

export type OrderHydratedDumpSchemaSignedAt = string | null;

export type OrderHydratedDumpSchemaSecondarySalesRepId = string | null;

export type OrderHydratedDumpSchemaSecondarySalesRep = UserDumpSchema | null;

export type OrderHydratedDumpSchemaSalesRepId = string | null;

export type OrderHydratedDumpSchemaSalesRep = UserDumpSchema | null;

export type OrderHydratedDumpSchemaSaleTaxRate = string | null;

export type OrderHydratedDumpSchemaRentalTaxRate = string | null;

export type OrderHydratedDumpSchemaPaymentMethodId = string | null;

export type OrderHydratedDumpSchemaLineItemsItem = RentalLineItemHydratedDumpSchema | SaleLineItemHydratedDumpSchema;

export type OrderHydratedDumpSchemaInvoiceDueNow = LinkIDSchema | null;

export type OrderHydratedDumpSchemaInternalNotes = string | null;

export type OrderHydratedDumpSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

export type OrderHydratedDumpSchemaExternalNotes = string | null;

export type OrderHydratedDumpSchemaCustomerPoNumber = string | null;

export type OrderHydratedDumpSchemaCustomerJobSiteNumber = string | null;

export type OrderHydratedDumpSchemaCustomerId = string | null;

export type OrderHydratedDumpSchemaCustomerContactId = string | null;

export type OrderHydratedDumpSchemaCustomerContact = CustomerContactHydratedDumpSchema | null;

export type OrderHydratedDumpSchemaCustomer = CustomerHydratedDumpSchema | null;

export type OrderHydratedDumpSchemaContractEndsAt = string | null;

export type OrderHydratedDumpSchemaBillingProfileId = string | null;

export type OrderHydratedDumpSchemaBillingProfile = BillingProfileDumpSchema | null;

export type OrderHydratedDumpSchemaBillingEndsAt = string | null;

export interface OrderHydratedDumpSchema {
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  billing_ends_at?: OrderHydratedDumpSchemaBillingEndsAt;
  billing_profile?: OrderHydratedDumpSchemaBillingProfile;
  billing_profile_id?: OrderHydratedDumpSchemaBillingProfileId;
  billing_starts_at: string;
  contract_ends_at?: OrderHydratedDumpSchemaContractEndsAt;
  contract_starts_at: string;
  count_cycle_prepayment_invoices: number;
  customer?: OrderHydratedDumpSchemaCustomer;
  customer_contact?: OrderHydratedDumpSchemaCustomerContact;
  customer_contact_id?: OrderHydratedDumpSchemaCustomerContactId;
  customer_id?: OrderHydratedDumpSchemaCustomerId;
  customer_job_site_number?: OrderHydratedDumpSchemaCustomerJobSiteNumber;
  customer_po_number?: OrderHydratedDumpSchemaCustomerPoNumber;
  delivery_requests: OrderDeliveryRequestDumpSchema[];
  delivery_subtotal: string;
  expires_at: string;
  external_notes?: OrderHydratedDumpSchemaExternalNotes;
  future_invoice_payment_method?: OrderHydratedDumpSchemaFutureInvoicePaymentMethod;
  has_amount_owed: boolean;
  id: string;
  internal_notes?: OrderHydratedDumpSchemaInternalNotes;
  invoice_due_now?: OrderHydratedDumpSchemaInvoiceDueNow;
  line_items: OrderHydratedDumpSchemaLineItemsItem[];
  location: LocationDumpSchema;
  location_id: string;
  modifiers: ModifierDumpSchema[];
  payment_method_id?: OrderHydratedDumpSchemaPaymentMethodId;
  rental_tax_rate?: OrderHydratedDumpSchemaRentalTaxRate;
  rental_tax_subtotal: string;
  sale_tax_rate?: OrderHydratedDumpSchemaSaleTaxRate;
  sale_tax_subtotal: string;
  sales_rep?: OrderHydratedDumpSchemaSalesRep;
  sales_rep_id?: OrderHydratedDumpSchemaSalesRepId;
  secondary_sales_rep?: OrderHydratedDumpSchemaSecondarySalesRep;
  secondary_sales_rep_id?: OrderHydratedDumpSchemaSecondarySalesRepId;
  signed_at?: OrderHydratedDumpSchemaSignedAt;
  signed_by?: OrderHydratedDumpSchemaSignedBy;
  signed_by_ip?: OrderHydratedDumpSchemaSignedByIp;
  source: OrderSource;
  status: OrderStatus;
  summary: OrderSummarySection[];
  tags: string[];
  tax_type: TaxType;
}

export type SaleLineItemHydratedDumpSchemaType = typeof SaleLineItemHydratedDumpSchemaType[keyof typeof SaleLineItemHydratedDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaleLineItemHydratedDumpSchemaType = {
  sale: 'sale',
} as const;

export type SaleLineItemHydratedDumpSchemaNotes = string | null;

export type SaleLineItemHydratedDumpSchemaContractStartedAt = string | null;

export type SaleLineItemHydratedDumpSchemaBillingStartedAt = string | null;

export type SaleLineItemHydratedDumpSchemaAssetId = string | null;

export type SaleLineItemHydratedDumpSchemaAssetCustomerIdentifier = string | null;

export type SaleLineItemHydratedDumpSchemaAsset = AssetDumpSchema | null;

export interface SaleLineItemHydratedDumpSchema {
  amount_per_unit: string;
  asset?: SaleLineItemHydratedDumpSchemaAsset;
  asset_customer_identifier?: SaleLineItemHydratedDumpSchemaAssetCustomerIdentifier;
  asset_id?: SaleLineItemHydratedDumpSchemaAssetId;
  base_amount_per_unit: string;
  billing_started_at?: SaleLineItemHydratedDumpSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  contract_started_at?: SaleLineItemHydratedDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  id: string;
  is_ready_for_receiving: boolean;
  klass: KlassDumpSchema;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  modifiers: ModifierDumpSchema[];
  notes?: SaleLineItemHydratedDumpSchemaNotes;
  parent_client_id: string;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  source: LineItemSource;
  status: LineItemStatus;
  type: SaleLineItemHydratedDumpSchemaType;
}

export type RentalLineItemHydratedDumpSchemaType = typeof RentalLineItemHydratedDumpSchemaType[keyof typeof RentalLineItemHydratedDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RentalLineItemHydratedDumpSchemaType = {
  rental: 'rental',
} as const;

export type RentalLineItemHydratedDumpSchemaShiftId = string | null;

export type RentalLineItemHydratedDumpSchemaShift = ShiftDumpSchema | null;

export type RentalLineItemHydratedDumpSchemaReceiving = ReceiveLineItemHydratedDumpSchema | null;

export type RentalLineItemHydratedDumpSchemaNotes = string | null;

export type RentalLineItemHydratedDumpSchemaContractStartedAt = string | null;

export type RentalLineItemHydratedDumpSchemaContractEndedAt = string | null;

export type RentalLineItemHydratedDumpSchemaBillingStartedAt = string | null;

export type RentalLineItemHydratedDumpSchemaBillingEndedAt = string | null;

export type RentalLineItemHydratedDumpSchemaAssetId = string | null;

export type RentalLineItemHydratedDumpSchemaAssetCustomerIdentifier = string | null;

export type RentalLineItemHydratedDumpSchemaAsset = AssetDumpSchema | null;

export interface RentalLineItemHydratedDumpSchema {
  amount_per_unit: string;
  asset?: RentalLineItemHydratedDumpSchemaAsset;
  asset_customer_identifier?: RentalLineItemHydratedDumpSchemaAssetCustomerIdentifier;
  asset_id?: RentalLineItemHydratedDumpSchemaAssetId;
  base_amount_per_unit: string;
  billing_ended_at?: RentalLineItemHydratedDumpSchemaBillingEndedAt;
  billing_ends_at: string;
  billing_started_at?: RentalLineItemHydratedDumpSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  contract_ended_at?: RentalLineItemHydratedDumpSchemaContractEndedAt;
  contract_ends_at: string;
  contract_started_at?: RentalLineItemHydratedDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  id: string;
  is_ready_for_receiving: boolean;
  klass: KlassDumpSchema;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  modifiers: ModifierDumpSchema[];
  notes?: RentalLineItemHydratedDumpSchemaNotes;
  parent_client_id: string;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  receiving?: RentalLineItemHydratedDumpSchemaReceiving;
  shift?: RentalLineItemHydratedDumpSchemaShift;
  shift_id?: RentalLineItemHydratedDumpSchemaShiftId;
  source: LineItemSource;
  status: LineItemStatus;
  type: RentalLineItemHydratedDumpSchemaType;
}

export type ReceiveLineItemHydratedDumpSchemaReceivingNotes = string | null;

export interface ReceiveLineItemHydratedDumpSchema {
  receiving_attachments: CustomerFileDumpSchema[];
  receiving_notes?: ReceiveLineItemHydratedDumpSchemaReceivingNotes;
  usage_metric_values: UsageMetricValueDumpSchema[];
}

export interface OrderSummarySection {
  bold?: boolean;
  lines: OrderSummaryLine[];
}

export type OrderDeliveryRequestDumpSchemaTaxRate = string | null;

export type OrderDeliveryRequestDumpSchemaTaxGroupId = string | null;

export type OrderDeliveryRequestDumpSchemaFromWindow = DateTimeWindowSchema | null;

export type OrderDeliveryRequestDumpSchemaDeliverySecondaryRate = string | null;

export type OrderDeliveryRequestDumpSchemaBillingProfileId = string | null;

export type LineItemStatus = typeof LineItemStatus[keyof typeof LineItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemStatus = {
  order: 'order',
  reserved: 'reserved',
  on_rent: 'on_rent',
  off_rent: 'off_rent',
  returned: 'returned',
  sold: 'sold',
} as const;

export type LineItemSource = typeof LineItemSource[keyof typeof LineItemSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemSource = {
  user: 'user',
  receiving__manual: 'receiving__manual',
  receiving__automatic: 'receiving__automatic',
} as const;

export interface DateTimeWindowSchema {
  end: string;
  start: string;
}

export type OrderDeliveryRequestDumpSchemaToWindow = DateTimeWindowSchema | null;

export interface OrderDeliveryRequestDumpSchema {
  amount: string;
  billing_profile_id?: OrderDeliveryRequestDumpSchemaBillingProfileId;
  charge_at: string;
  delivery_address: DeliveryAddressDumpSchema;
  delivery_address_id: string;
  delivery_configuration: DeliveryConfigurationDumpSchema;
  delivery_configuration_id: string;
  delivery_rate: string;
  delivery_secondary_rate?: OrderDeliveryRequestDumpSchemaDeliverySecondaryRate;
  direction: DeliveryRequestDirection;
  from_address: AddressDumpSchema;
  from_address_id: string;
  from_window?: OrderDeliveryRequestDumpSchemaFromWindow;
  modifiers: ModifierDumpSchema[];
  tax_group_id?: OrderDeliveryRequestDumpSchemaTaxGroupId;
  tax_rate?: OrderDeliveryRequestDumpSchemaTaxRate;
  to_address: AddressDumpSchema;
  to_address_id: string;
  to_window?: OrderDeliveryRequestDumpSchemaToWindow;
}

export type PaginatedOrderSchemaNext = number | null;

export interface PaginatedOrderSchema {
  elements: OrderDumpSchema[];
  next?: PaginatedOrderSchemaNext;
  total_count: number;
}

export type OrderSource = typeof OrderSource[keyof typeof OrderSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderSource = {
  self_service: 'self_service',
  sales: 'sales',
} as const;

export type OrderDumpSchemaVendor = LinkNameSchema | null;

export type OrderDumpSchemaSignedByIp = string | null;

export type OrderDumpSchemaSignedBy = string | null;

export type OrderDumpSchemaSignedAt = string | null;

export type OrderDumpSchemaSecondarySalesRepId = string | null;

export type OrderDumpSchemaSecondarySalesRep = LinkNameSchema | null;

export type OrderDumpSchemaSalesRepId = string | null;

export type OrderDumpSchemaSalesRep = LinkNameSchema | null;

export type OrderDumpSchemaSaleTaxRate = string | null;

export type OrderDumpSchemaRentalTaxRate = string | null;

export type OrderDumpSchemaPaymentMethodId = string | null;

export type OrderDumpSchemaInternalNotes = string | null;

export type OrderDumpSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

export type OrderDumpSchemaExternalNotes = string | null;

export type OrderDumpSchemaCustomerPoNumber = string | null;

export type OrderDumpSchemaCustomerJobSiteNumber = string | null;

export type OrderDumpSchemaCustomerId = string | null;

export type OrderDumpSchemaCustomerContactId = string | null;

export type OrderDumpSchemaCustomer = LinkIDNameSchema | null;

export type OrderDumpSchemaContractEndsAt = string | null;

export type OrderDumpSchemaBillingProfileId = string | null;

export type OrderDumpSchemaBillingEndsAt = string | null;

export interface OrderDumpSchema {
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  billing_ends_at?: OrderDumpSchemaBillingEndsAt;
  billing_profile_id?: OrderDumpSchemaBillingProfileId;
  billing_starts_at: string;
  contract_ends_at?: OrderDumpSchemaContractEndsAt;
  contract_starts_at: string;
  customer?: OrderDumpSchemaCustomer;
  customer_contact_id?: OrderDumpSchemaCustomerContactId;
  customer_id?: OrderDumpSchemaCustomerId;
  customer_job_site_number?: OrderDumpSchemaCustomerJobSiteNumber;
  customer_po_number?: OrderDumpSchemaCustomerPoNumber;
  expires_at: string;
  external_notes?: OrderDumpSchemaExternalNotes;
  future_invoice_payment_method?: OrderDumpSchemaFutureInvoicePaymentMethod;
  has_amount_owed: boolean;
  id: string;
  internal_notes?: OrderDumpSchemaInternalNotes;
  location: LinkIDNameSchema;
  location_id: string;
  payment_method_id?: OrderDumpSchemaPaymentMethodId;
  rental_tax_rate?: OrderDumpSchemaRentalTaxRate;
  sale_tax_rate?: OrderDumpSchemaSaleTaxRate;
  sales_rep?: OrderDumpSchemaSalesRep;
  sales_rep_id?: OrderDumpSchemaSalesRepId;
  secondary_sales_rep?: OrderDumpSchemaSecondarySalesRep;
  secondary_sales_rep_id?: OrderDumpSchemaSecondarySalesRepId;
  signed_at?: OrderDumpSchemaSignedAt;
  signed_by?: OrderDumpSchemaSignedBy;
  signed_by_ip?: OrderDumpSchemaSignedByIp;
  source: OrderSource;
  status: OrderStatus;
  tax_type: TaxType;
  vendor?: OrderDumpSchemaVendor;
}

export type FutureInvoicePaymentMethod = typeof FutureInvoicePaymentMethod[keyof typeof FutureInvoicePaymentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FutureInvoicePaymentMethod = {
  auto_charge: 'auto_charge',
  email_no_link: 'email_no_link',
  email_with_link: 'email_with_link',
} as const;

export type BillingProfileCreateSchemaSpecificDay = number | null;

export type PrepaymentCreateSchemaChargeTime = PrepaymentChargeTime | null;

export type PrepaymentCreateSchemaChargeAmount = string | null;

export interface PrepaymentCreateSchema {
  charge_amount?: PrepaymentCreateSchemaChargeAmount;
  charge_time?: PrepaymentCreateSchemaChargeTime;
  cycle: PrepaymentCycle;
  type: PrepaymentType;
}

export interface BillingProfileCreateSchema {
  cycle: BillingCycle;
  due: PaymentDue;
  name: string;
  prepayments: PrepaymentCreateSchema[];
  specific_day?: BillingProfileCreateSchemaSpecificDay;
  terms: PaymentTerms;
  type: BillingProfileType;
}

export type BillingProfileHydratedDumpSchemaSpecificDay = number | null;

export interface BillingProfileHydratedDumpSchema {
  cycle: BillingCycle;
  due: PaymentDue;
  id: string;
  name: string;
  prepayments: PrepaymentDumpSchema[];
  specific_day?: BillingProfileHydratedDumpSchemaSpecificDay;
  terms: PaymentTerms;
  type: BillingProfileType;
}

export type PrepaymentType = typeof PrepaymentType[keyof typeof PrepaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrepaymentType = {
  one_time: 'one_time',
  recurring: 'recurring',
} as const;

export type PrepaymentDumpSchemaChargeAmount = string | null;

export type PrepaymentCycle = typeof PrepaymentCycle[keyof typeof PrepaymentCycle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrepaymentCycle = {
  entire: 'entire',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
} as const;

export interface PrepaymentDumpSchema {
  charge_amount?: PrepaymentDumpSchemaChargeAmount;
  charge_time?: PrepaymentDumpSchemaChargeTime;
  cycle: PrepaymentCycle;
  id: string;
  type: PrepaymentType;
}

export type PrepaymentChargeTime = typeof PrepaymentChargeTime[keyof typeof PrepaymentChargeTime];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrepaymentChargeTime = {
  reservation_creation: 'reservation_creation',
  bd1_contract_start: 'bd1_contract_start',
  bd2_contract_start: 'bd2_contract_start',
  bd14_contract_start: 'bd14_contract_start',
  start_of_contract: 'start_of_contract',
} as const;

export type PrepaymentDumpSchemaChargeTime = PrepaymentChargeTime | null;

export type PaginatedBillingProfileSchemaNext = number | null;

export interface PaginatedBillingProfileSchema {
  elements: BillingProfileDumpSchema[];
  next?: PaginatedBillingProfileSchemaNext;
  total_count: number;
}

export interface InvoiceCustomerBalanceBodySchema {
  amount: string;
}

export interface InvoiceAdjustmentBodySchema {
  amount: string;
  reason: string;
}

export type InvoiceExternalHydratedDumpSchemaSecondarySalesRep = UserDumpSchema | null;

export type InvoiceExternalHydratedDumpSchemaSalesRep = UserDumpSchema | null;

export type InvoiceExternalHydratedDumpSchemaExternalNotes = string | null;

export type InvoiceExternalHydratedDumpSchemaCycleStart = string | null;

export type InvoiceExternalHydratedDumpSchemaCycleEnd = string | null;

export type InvoiceExternalHydratedDumpSchemaCustomerPoNumber = string | null;

export type InvoiceExternalHydratedDumpSchemaCustomerJobSiteNumber = string | null;

export type InvoiceExternalHydratedDumpSchemaCustomerContact = CustomerContactHydratedDumpSchema | null;

export type InvoiceExternalHydratedDumpSchemaCustomer = CustomerHydratedDumpSchema | null;

export type InvoiceExternalHydratedDumpSchemaBillingAddress = AddressDumpSchema | null;

export type LineItemType = typeof LineItemType[keyof typeof LineItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemType = {
  rental: 'rental',
  sale: 'sale',
} as const;

export interface InvoiceLineItemNonItemExternalDumpSchema {
  amount: string;
  id: string;
  label: string;
  type: InvoiceLineItemType;
}

export interface InvoiceExternalHydratedDumpSchema {
  adjustment_subtotal: string;
  amount: string;
  amount_billed: string;
  amount_due: string;
  amount_paid: string;
  billing_address?: InvoiceExternalHydratedDumpSchemaBillingAddress;
  business_name: string;
  charge_at: string;
  child_invoices: InvoiceExternalDumpSchema[];
  created_at: string;
  customer?: InvoiceExternalHydratedDumpSchemaCustomer;
  customer_contact?: InvoiceExternalHydratedDumpSchemaCustomerContact;
  customer_id: string;
  customer_job_site_number?: InvoiceExternalHydratedDumpSchemaCustomerJobSiteNumber;
  customer_po_number?: InvoiceExternalHydratedDumpSchemaCustomerPoNumber;
  cycle_end?: InvoiceExternalHydratedDumpSchemaCycleEnd;
  cycle_start?: InvoiceExternalHydratedDumpSchemaCycleStart;
  due_at: string;
  external_notes?: InvoiceExternalHydratedDumpSchemaExternalNotes;
  id: string;
  identifier: string;
  invoice_only_line_items: InvoiceLineItemNonItemExternalDumpSchema[];
  line_items: InvoiceLineItemItemExternalDumpSchema[];
  location: LocationDumpSchema;
  modifiers: ModifierDumpSchema[];
  order: LinkIDSchema;
  order_id: string;
  payments: PaymentDumpSchema[];
  rental_item_subtotal: string;
  sale_item_subtotal: string;
  sales_rep?: InvoiceExternalHydratedDumpSchemaSalesRep;
  secondary_sales_rep?: InvoiceExternalHydratedDumpSchemaSecondarySalesRep;
  status: InvoiceStatus;
  summary: OrderSummaryLine[];
  tax_subtotal: string;
  type: InvoiceType;
}

export type InvoiceLineItemItemExternalDumpSchemaShiftId = string | null;

export type InvoiceLineItemItemExternalDumpSchemaShift = ShiftDumpSchema | null;

export type InvoiceLineItemItemExternalDumpSchemaExternalDescription = string | null;

export type InvoiceLineItemItemExternalDumpSchemaContractStartedAt = string | null;

export type InvoiceLineItemItemExternalDumpSchemaContractEndedAt = string | null;

export type InvoiceLineItemItemExternalDumpSchemaAssetId = string | null;

export type InvoiceLineItemItemExternalDumpSchemaAssetCustomerIdentifier = string | null;

export interface InvoiceLineItemItemExternalDumpSchema {
  amount_per_unit: string;
  asset_customer_identifier?: InvoiceLineItemItemExternalDumpSchemaAssetCustomerIdentifier;
  asset_id?: InvoiceLineItemItemExternalDumpSchemaAssetId;
  assets: AssetExternalDumpSchema[];
  base_amount_per_unit: string;
  contract_ended_at?: InvoiceLineItemItemExternalDumpSchemaContractEndedAt;
  contract_ends_at: string;
  contract_started_at?: InvoiceLineItemItemExternalDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  external_description?: InvoiceLineItemItemExternalDumpSchemaExternalDescription;
  id: string;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  label: string;
  modifiers?: ModifierDumpSchema[];
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  shift?: InvoiceLineItemItemExternalDumpSchemaShift;
  shift_id?: InvoiceLineItemItemExternalDumpSchemaShiftId;
  type: LineItemType;
}

export type InvoiceExternalDumpSchemaCycleStart = string | null;

export type InvoiceExternalDumpSchemaCycleEnd = string | null;

export type InvoiceExternalDumpSchemaCustomerPoNumber = string | null;

export interface InvoiceExternalDumpSchema {
  adjustment_subtotal: string;
  amount: string;
  amount_billed: string;
  amount_due: string;
  amount_paid: string;
  charge_at: string;
  created_at: string;
  customer: LinkIDNameSchema;
  customer_id: string;
  customer_po_number?: InvoiceExternalDumpSchemaCustomerPoNumber;
  cycle_end?: InvoiceExternalDumpSchemaCycleEnd;
  cycle_start?: InvoiceExternalDumpSchemaCycleStart;
  due_at: string;
  id: string;
  identifier: string;
  order: LinkIDSchema;
  order_id: string;
  payments: PaymentDumpSchema[];
  rental_item_subtotal: string;
  sale_item_subtotal: string;
  status: InvoiceStatus;
  tax_subtotal: string;
  type: InvoiceType;
}

export type AssetExternalDumpSchemaPrimarySerialNumber = string | null;

export type AssetExternalDumpSchemaMeterOutValue = string | null;

export type AssetExternalDumpSchemaMeterOutUnit = UsageMetricUnit | null;

export type AssetExternalDumpSchemaMeterInValue = string | null;

export type AssetExternalDumpSchemaGaugeType = FuelType | null;

export type AssetExternalDumpSchemaGaugeOutValue = string | null;

export type AssetExternalDumpSchemaGaugeOutUnit = UsageMetricUnit | null;

export type AssetExternalDumpSchemaGaugeInValue = string | null;

export interface AssetExternalDumpSchema {
  gauge_in_value?: AssetExternalDumpSchemaGaugeInValue;
  gauge_out_unit?: AssetExternalDumpSchemaGaugeOutUnit;
  gauge_out_value?: AssetExternalDumpSchemaGaugeOutValue;
  gauge_type?: AssetExternalDumpSchemaGaugeType;
  identifier: string;
  meter_in_value?: AssetExternalDumpSchemaMeterInValue;
  meter_out_unit?: AssetExternalDumpSchemaMeterOutUnit;
  meter_out_value?: AssetExternalDumpSchemaMeterOutValue;
  primary_serial_number?: AssetExternalDumpSchemaPrimarySerialNumber;
}

export type InvoicePOUpdateBodySchemaCustomerPoNumber = string | null;

export interface InvoicePOUpdateBodySchema {
  customer_po_number?: InvoicePOUpdateBodySchemaCustomerPoNumber;
}

export type InvoiceHydratedDumpSchemaParentInvoiceId = string | null;

export type InvoiceHydratedDumpSchemaParentInvoice = LinkIDSchema | null;

export type InvoiceHydratedDumpSchemaCycleStart = string | null;

export type InvoiceHydratedDumpSchemaCycleEnd = string | null;

export type InvoiceHydratedDumpSchemaCustomerPoNumber = string | null;

export type TaxType = typeof TaxType[keyof typeof TaxType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxType = {
  auto: 'auto',
  manual: 'manual',
} as const;

export interface OrderSummaryLine {
  amount: string;
  label: string;
}

export type ModifierDumpSchemaExpenseRuleId = string | null;

export interface InvoicePaymentDumpSchema {
  amount: string;
  payment: PaymentDumpSchema;
}

export type InvoiceLineItemType = typeof InvoiceLineItemType[keyof typeof InvoiceLineItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceLineItemType = {
  tax: 'tax',
  adjustment: 'adjustment',
  surcharge: 'surcharge',
  item__rental: 'item__rental',
  item__sale: 'item__sale',
} as const;

export type InvoiceLineItemDumpSchemaTaxType = TaxType | null;

export type InvoiceLineItemDumpSchemaTaxRate = string | null;

export type InvoiceLineItemDumpSchemaTaxGroupId = string | null;

export type InvoiceLineItemDumpSchemaShiftId = string | null;

export type InvoiceLineItemDumpSchemaShift = ShiftDumpSchema | null;

export type InvoiceLineItemDumpSchemaLineItemId = string | null;

export type InvoiceLineItemDumpSchemaKlassId = string | null;

export type InvoiceLineItemDumpSchemaKlass = KlassDumpSchema | null;

export type InvoiceLineItemDumpSchemaContractStartsAt = string | null;

export type InvoiceLineItemDumpSchemaContractStartedAt = string | null;

export type InvoiceLineItemDumpSchemaContractEndsAt = string | null;

export type InvoiceLineItemDumpSchemaContractEndedAt = string | null;

export type InvoiceLineItemDumpSchemaAssetId = string | null;

export type InvoiceLineItemDumpSchemaAsset = AssetDumpSchema | null;

export interface InvoiceLineItemDumpSchema {
  amount_per_unit: string;
  asset?: InvoiceLineItemDumpSchemaAsset;
  asset_id?: InvoiceLineItemDumpSchemaAssetId;
  base_amount_per_unit: string;
  contract_ended_at?: InvoiceLineItemDumpSchemaContractEndedAt;
  contract_ends_at?: InvoiceLineItemDumpSchemaContractEndsAt;
  contract_started_at?: InvoiceLineItemDumpSchemaContractStartedAt;
  contract_starts_at?: InvoiceLineItemDumpSchemaContractStartsAt;
  extended_amount: string;
  extended_base_amount: string;
  id: string;
  klass?: InvoiceLineItemDumpSchemaKlass;
  klass_id?: InvoiceLineItemDumpSchemaKlassId;
  label: string;
  line_item_id?: InvoiceLineItemDumpSchemaLineItemId;
  modifiers: ModifierDumpSchema[];
  pricings: KlassPricingDumpSchema[];
  quantity?: number;
  shift?: InvoiceLineItemDumpSchemaShift;
  shift_id?: InvoiceLineItemDumpSchemaShiftId;
  tax_group_id?: InvoiceLineItemDumpSchemaTaxGroupId;
  tax_rate?: InvoiceLineItemDumpSchemaTaxRate;
  tax_type?: InvoiceLineItemDumpSchemaTaxType;
  type: InvoiceLineItemType;
}

export interface InvoiceHydratedDumpSchema {
  activity_log_id: string;
  adjustment_subtotal: string;
  amount: string;
  amount_due: string;
  amount_paid: string;
  charge_at: string;
  child_invoices: InvoiceDumpSchema[];
  created_at: string;
  customer: CustomerHydratedDumpSchema;
  customer_id: string;
  customer_po_number?: InvoiceHydratedDumpSchemaCustomerPoNumber;
  cycle_end?: InvoiceHydratedDumpSchemaCycleEnd;
  cycle_start?: InvoiceHydratedDumpSchemaCycleStart;
  due_at: string;
  id: string;
  identifier: string;
  item_subtotal: string;
  line_items: InvoiceLineItemDumpSchema[];
  modifiers: ModifierDumpSchema[];
  order: LinkIDSchema;
  order_id: string;
  parent_invoice?: InvoiceHydratedDumpSchemaParentInvoice;
  parent_invoice_id?: InvoiceHydratedDumpSchemaParentInvoiceId;
  payments: InvoicePaymentDumpSchema[];
  status: InvoiceStatus;
  summary: OrderSummaryLine[];
  tax_subtotal: string;
  type: InvoiceType;
}

export type ExpenseClassBaseDumpSchemaRevenueAccountId = string | null;

export type ExpenseClassBaseDumpSchemaProductTaxCodeGroupId = string | null;

export interface ExpenseClassBaseDumpSchema {
  display_type: ExpenseClassDisplayType;
  id: string;
  name: string;
  product_tax_code_group_id?: ExpenseClassBaseDumpSchemaProductTaxCodeGroupId;
  revenue_account_id?: ExpenseClassBaseDumpSchemaRevenueAccountId;
  type: ExpenseClassType;
}

export interface ModifierDumpSchema {
  amount: string;
  expense_class: ExpenseClassBaseDumpSchema;
  expense_rule: ExpenseRuleDumpSchema;
  expense_rule_id?: ModifierDumpSchemaExpenseRuleId;
  rate: string;
}

export type PaginatedInvoiceSchemaNext = number | null;

export interface PaginatedInvoiceSchema {
  elements: InvoiceDumpSchema[];
  next?: PaginatedInvoiceSchemaNext;
  total_count: number;
}

export interface PaymentDepositReconciliationSchema {
  payment_id: string;
}

export interface DepositUpdateSchema {
  deposit_at: string;
  name: string;
  payments: PaymentDepositReconciliationSchema[];
  status: DepositStatus;
}

export type PaginatedDepositSchemaNext = number | null;

export interface PaymentDepositReconciliationDumpSchema {
  payment: PaymentDumpSchema;
  payment_id: string;
}

export type DepositType = typeof DepositType[keyof typeof DepositType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepositType = {
  manual: 'manual',
  stripe: 'stripe',
} as const;

export interface CreateDepositSchema {
  deposit_at: string;
  name: string;
  payments: PaymentDepositReconciliationSchema[];
  type: DepositType;
}

export interface DumpDepositSchema {
  amount: string;
  deposit_at: string;
  id: string;
  name: string;
  payments: PaymentDepositReconciliationDumpSchema[];
  status: DepositStatus;
  type: DepositType;
}

export interface PaginatedDepositSchema {
  elements: DumpDepositSchema[];
  next?: PaginatedDepositSchemaNext;
  total_count: number;
}

export interface KlassAvailabilitySegmentSchema {
  count_available: number;
  count_total: number;
  count_unavailable: number;
  date: string;
}

export interface KlassAvailabilityDumpSchema {
  segments: KlassAvailabilitySegmentSchema[];
}

export type KlassAvailabilitySchemaLocationId = string | null;

export type KlassAvailabilitySchemaLocationGroupId = string | null;

export type KlassAvailabilitySchemaKlassId = string | null;

export type KlassAvailabilitySchemaEndDate = string | null;

export type KlassAvailabilitySchemaAssetId = string | null;

export interface KlassAvailabilitySchema {
  asset_id?: KlassAvailabilitySchemaAssetId;
  end_date?: KlassAvailabilitySchemaEndDate;
  klass_id?: KlassAvailabilitySchemaKlassId;
  location_group_id?: KlassAvailabilitySchemaLocationGroupId;
  location_id?: KlassAvailabilitySchemaLocationId;
  start_date: string;
}

export interface ReportResponse {
  headers: ReportResponseHeaders;
  response: ReportResponseResponse;
}

export type ReportResponseResponseAnyOf = { [key: string]: any };

export type ReportResponseResponse = string | Blob | ReportResponseResponseAnyOf;

export type ReportResponseHeadersAnyOf = {[key: string]: string};

export type ReportResponseHeaders = ReportResponseHeadersAnyOf | null;

export interface ItemReceiptUpdateSchema {
  expense_line_items: string[];
  item_line_items: string[];
  posting_date: string;
  purchase_order_id: string;
}

export interface ItemReceiptCreateSchema {
  expense_line_items: string[];
  item_line_items: string[];
  posting_date: string;
  purchase_order_id: string;
}

export interface ItemReceiptHydratedDumpSchema {
  expense_line_items: ExpenseLineItemDumpSchema[];
  id: string;
  item_line_items: ItemLineItemDumpSchema[];
  posting_date: string;
  purchase_order: LinkIDSchema;
  purchase_order_id: string;
}

export type PaginatedItemReceiptSchemaNext = number | null;

export interface ItemReceiptDumpSchema {
  id: string;
  posting_date: string;
  purchase_order: LinkIDSchema;
  purchase_order_id: string;
}

export interface PaginatedItemReceiptSchema {
  elements: ItemReceiptDumpSchema[];
  next?: PaginatedItemReceiptSchemaNext;
  total_count: number;
}

export interface VendorBillUpdateSchema {
  due_date: string;
  expense_line_items: string[];
  item_line_items: string[];
  posting_date: string;
  purchase_order_id: string;
}

export interface VendorBillCreateSchema {
  due_date: string;
  expense_line_items: string[];
  item_line_items: string[];
  posting_date: string;
  purchase_order_id: string;
}

export type PaginatedVendorBillSchemaNext = number | null;

export interface VendorBillDumpSchema {
  due_date: string;
  id: string;
  posting_date: string;
  purchase_order: LinkIDSchema;
  purchase_order_id: string;
}

export interface PaginatedVendorBillSchema {
  elements: VendorBillDumpSchema[];
  next?: PaginatedVendorBillSchemaNext;
  total_count: number;
}

export type PurchaseOrderUpdateSchemaInternalNotes = string | null;

export type PurchaseOrderCreateSchemaInternalNotes = string | null;

export interface ItemLineItemCreateSchema {
  grouping_key?: string;
  klass_id: string;
  quantity: number;
  unit_price: string;
}

export interface ExpenseLineItemCreateSchema {
  grouping_key?: string;
  is_capitalized: boolean;
  label: string;
  quantity: number;
  unit_price: string;
}

export interface PurchaseOrderUpdateSchema {
  expense_line_items: ExpenseLineItemCreateSchema[];
  internal_notes?: PurchaseOrderUpdateSchemaInternalNotes;
  item_line_items: ItemLineItemCreateSchema[];
  location_id: string;
  posting_date: string;
  tags: string[];
  vendor_contact_id: string;
  vendor_id: string;
}

export interface PurchaseOrderCreateSchema {
  expense_line_items: ExpenseLineItemCreateSchema[];
  internal_notes?: PurchaseOrderCreateSchemaInternalNotes;
  item_line_items: ItemLineItemCreateSchema[];
  location_id: string;
  posting_date: string;
  tags: string[];
  vendor_contact_id: string;
  vendor_id: string;
}

export type PurchaseOrderHydratedDumpSchemaInternalNotes = string | null;

export interface ItemLineItemDumpSchema {
  count_billed: number;
  count_received: number;
  count_total: number;
  grouping_key: string;
  id: string;
  klass: LinkIDNameSchema;
  klass_id: string;
  quantity: number;
  total_price: string;
  unit_price: string;
}

export interface ExpenseLineItemDumpSchema {
  count_billed: number;
  count_received: number;
  count_total: number;
  grouping_key: string;
  id: string;
  is_capitalized: boolean;
  label: string;
  quantity: number;
  total_price: string;
  unit_price: string;
}

export interface VendorBillHydratedDumpSchema {
  due_date: string;
  expense_line_items: ExpenseLineItemDumpSchema[];
  id: string;
  item_line_items: ItemLineItemDumpSchema[];
  posting_date: string;
  purchase_order: LinkIDSchema;
  purchase_order_id: string;
}

export interface PurchaseOrderHydratedDumpSchema {
  activity_log_id: string;
  autogenerated_identifier: string;
  expense_line_items: ExpenseLineItemDumpSchema[];
  id: string;
  internal_notes?: PurchaseOrderHydratedDumpSchemaInternalNotes;
  item_line_items: ItemLineItemDumpSchema[];
  location: LinkIDNameSchema;
  location_id: string;
  posting_date: string;
  status: PurchaseOrderStatus;
  tags: string[];
  vendor: LinkIDNameSchema;
  vendor_contact: VendorContactDumpSchema;
  vendor_contact_id: string;
  vendor_id: string;
}

export type PaginatedPurchaseOrderSchemaNext = number | null;

export type PurchaseOrderStatus = typeof PurchaseOrderStatus[keyof typeof PurchaseOrderStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderStatus = {
  draft: 'draft',
  cancelled: 'cancelled',
  pending_receipt: 'pending_receipt',
  partially_received: 'partially_received',
  partially_received_partially_billed: 'partially_received_partially_billed',
  pending_bill: 'pending_bill',
  fully_billed: 'fully_billed',
  closed: 'closed',
} as const;

export type PurchaseOrderDumpSchemaInternalNotes = string | null;

export interface PurchaseOrderDumpSchema {
  activity_log_id: string;
  autogenerated_identifier: string;
  id: string;
  internal_notes?: PurchaseOrderDumpSchemaInternalNotes;
  location: LinkIDNameSchema;
  location_id: string;
  posting_date: string;
  status: PurchaseOrderStatus;
  tags: string[];
  vendor: LinkIDNameSchema;
  vendor_contact: VendorContactDumpSchema;
  vendor_contact_id: string;
  vendor_id: string;
}

export interface PaginatedPurchaseOrderSchema {
  elements: PurchaseOrderDumpSchema[];
  next?: PaginatedPurchaseOrderSchemaNext;
  total_count: number;
}

export type VendorCreateSchemaWebAddress = string | null;

export type VendorCreateSchemaRemoteObjectId = string | null;

export type VendorCreateSchemaProducts = string | null;

export type VendorCreateSchemaPhone = string | null;

export type VendorCreateSchemaNotes = string | null;

export type VendorCreateSchemaEmail = string | null;

export type VendorCreateSchemaDisplayName = string | null;

export type VendorCreateSchemaAddress = AddressCreateSchema | null;

export type VendorCreateSchemaAccountNumber = string | null;

export interface VendorCreateSchema {
  account_number?: VendorCreateSchemaAccountNumber;
  address?: VendorCreateSchemaAddress;
  contacts: VendorContactCreateSchema[];
  display_name?: VendorCreateSchemaDisplayName;
  email?: VendorCreateSchemaEmail;
  name: string;
  notes?: VendorCreateSchemaNotes;
  payment_method: PaymentMethodType;
  payment_terms: PaymentTerms;
  phone?: VendorCreateSchemaPhone;
  products?: VendorCreateSchemaProducts;
  remote_object_id?: VendorCreateSchemaRemoteObjectId;
  type: VendorTypeEnum;
  web_address?: VendorCreateSchemaWebAddress;
}

export type VendorContactCreateSchemaPhone = string | null;

export type VendorContactCreateSchemaEmail = string | null;

export interface VendorContactCreateSchema {
  email?: VendorContactCreateSchemaEmail;
  first_name: string;
  last_name: string;
  phone?: VendorContactCreateSchemaPhone;
}

export type PaginatedVendorSchemaNext = number | null;

export interface PaginatedVendorSchema {
  elements: VendorDumpSchema[];
  next?: PaginatedVendorSchemaNext;
  total_count: number;
}

export interface PaymentSurchargeAmountDump {
  amount: string;
  fee_amount: string;
  total_amount: string;
}

export interface PaymentSurchargeAmountCheck {
  amount: string;
  payment_type: PaymentTypeEnum;
}

export type PaymentSurchargeBaseSchemaCapDollarAmount = string | null;

export interface PaymentSurchargeBaseSchema {
  cap_dollar_amount?: PaymentSurchargeBaseSchemaCapDollarAmount;
  payment_type: PaymentTypeEnum;
  percentage_amount: string;
}

export interface PaymentSurchargeListSchema {
  surcharges?: PaymentSurchargeBaseSchema[];
}

export type PaymentUpdateSchemaPaidAt = string | null;

export type PaymentUpdateSchemaNotes = string | null;

export type PaymentUpdateSchemaCheckNumber = string | null;

export interface PaymentUpdateSchema {
  amount: string;
  check_number?: PaymentUpdateSchemaCheckNumber;
  notes?: PaymentUpdateSchemaNotes;
  paid_at?: PaymentUpdateSchemaPaidAt;
  payment_type: PaymentTypeEnum;
}

export type PaginatedPaymentSchemaNext = number | null;

export interface PaginatedPaymentSchema {
  elements: PaymentDumpSchema[];
  next?: PaginatedPaymentSchemaNext;
  total_count: number;
}

export type PaymentCreateBodySchemaPayment = PaymentCreateSchema | null;

export type PaymentCreateBodySchemaIncludesAllReconciliationsForPaymentId = string | null;

export type PaymentCreateBodySchemaIncludesAllReconciliationsForInvoiceId = string | null;

export interface PaymentReconciliationBodySchema {
  amount: string;
  payment_id: string;
}

export interface PaymentCreateBodySchema {
  customer_balance_used: string;
  customer_id: string;
  includes_all_reconciliations_for_invoice_id?: PaymentCreateBodySchemaIncludesAllReconciliationsForInvoiceId;
  includes_all_reconciliations_for_payment_id?: PaymentCreateBodySchemaIncludesAllReconciliationsForPaymentId;
  invoices: InvoiceReconciliationBodySchema[];
  payment?: PaymentCreateBodySchemaPayment;
  payments: PaymentReconciliationBodySchema[];
}

export type PaymentCreateSchemaStripePaymentMethodId = string | null;

export type PaymentCreateSchemaPaidAt = string | null;

export type PaymentCreateSchemaNotes = string | null;

export type PaymentCreateSchemaCheckNumber = string | null;

export interface PaymentCreateSchema {
  amount: string;
  check_number?: PaymentCreateSchemaCheckNumber;
  customer_id: string;
  notes?: PaymentCreateSchemaNotes;
  paid_at?: PaymentCreateSchemaPaidAt;
  payment_provider: PaymentProvider;
  payment_type: PaymentTypeEnum;
  stripe_payment_method_id?: PaymentCreateSchemaStripePaymentMethodId;
}

export interface InvoiceReconciliationBodySchema {
  amount: string;
  invoice_id: string;
}

export type PaymentCreateResponseSchemaPayment = PaymentHydratedDumpSchema | null;

export interface StripeClientSecretSchema {
  account_id: string;
  client_secret: string;
}

export type PaymentCreateResponseSchemaStripeSecret = StripeClientSecretSchema | null;

export interface PaymentCreateResponseSchema {
  payment?: PaymentCreateResponseSchemaPayment;
  stripe_secret?: PaymentCreateResponseSchemaStripeSecret;
}

export interface PaymentInvoiceDumpSchema {
  amount: string;
  invoice: InvoiceDumpSchema;
}

export type PaymentHydratedDumpSchemaPaymentMethod = PaymentMethodDumpSchema | null;

export type PaymentHydratedDumpSchemaPaidAt = string | null;

export type PaymentHydratedDumpSchemaNotes = string | null;

export type PaymentHydratedDumpSchemaDepositStatus = DepositStatus | null;

export type PaymentHydratedDumpSchemaCheckNumber = string | null;

export interface PaymentHydratedDumpSchema {
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  check_number?: PaymentHydratedDumpSchemaCheckNumber;
  customer: LinkIDNameSchema;
  customer_id: string;
  deposit_status?: PaymentHydratedDumpSchemaDepositStatus;
  id: string;
  invoices: PaymentInvoiceDumpSchema[];
  notes?: PaymentHydratedDumpSchemaNotes;
  paid_at?: PaymentHydratedDumpSchemaPaidAt;
  payment_method?: PaymentHydratedDumpSchemaPaymentMethod;
  payment_provider: PaymentProvider;
  payment_type: PaymentTypeEnum;
  status: PaymentStatus;
  unreconciled_amount: string;
  unreconciled_deposit_amount: string;
}

export type PaymentSimpleCreateSchemaStripePaymentMethodId = string | null;

export type PaymentSimpleCreateSchemaPaymentType = typeof PaymentSimpleCreateSchemaPaymentType[keyof typeof PaymentSimpleCreateSchemaPaymentType] ;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSimpleCreateSchemaPaymentType = {  card: 'card',
  bank: 'bank',
} as const;

export type PaymentSimpleCreateSchemaPaymentProvider = typeof PaymentSimpleCreateSchemaPaymentProvider[keyof typeof PaymentSimpleCreateSchemaPaymentProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSimpleCreateSchemaPaymentProvider = {
  stripe: 'stripe',
} as const;

export type PaymentSimpleCreateSchemaPaidAt = string | null;

export interface PaymentSimpleCreateSchema {
  customer_id: string;
  paid_at?: PaymentSimpleCreateSchemaPaidAt;
  payment_provider: PaymentSimpleCreateSchemaPaymentProvider;
  payment_type: PaymentSimpleCreateSchemaPaymentType;
  stripe_payment_method_id?: PaymentSimpleCreateSchemaStripePaymentMethodId;
}

export type PaymentReconcileResponseSchemaNewPaymentMethodId = string | null;

export type PaymentTypeEnum = typeof PaymentTypeEnum[keyof typeof PaymentTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentTypeEnum = {
  check: 'check',
  cash: 'cash',
  bank: 'bank',
  card: 'card',
} as const;

export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentStatus = {
  pending: 'pending',
  paid: 'paid',
  failed: 'failed',
  refunded: 'refunded',
  partially_refunded: 'partially_refunded',
  voided: 'voided',
} as const;

export type PaymentProvider = typeof PaymentProvider[keyof typeof PaymentProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentProvider = {
  stripe: 'stripe',
  manual: 'manual',
} as const;

export type PaymentDumpSchemaPaymentMethod = PaymentMethodDumpSchema | null;

export type PaymentDumpSchemaPaidAt = string | null;

export type PaymentDumpSchemaNotes = string | null;

export type PaymentDumpSchemaDepositStatus = DepositStatus | null;

export type PaymentDumpSchemaCheckNumber = string | null;

export interface PaymentDumpSchema {
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  check_number?: PaymentDumpSchemaCheckNumber;
  customer: LinkIDNameSchema;
  customer_id: string;
  deposit_status?: PaymentDumpSchemaDepositStatus;
  id: string;
  notes?: PaymentDumpSchemaNotes;
  paid_at?: PaymentDumpSchemaPaidAt;
  payment_method?: PaymentDumpSchemaPaymentMethod;
  payment_provider: PaymentProvider;
  payment_type: PaymentTypeEnum;
  status: PaymentStatus;
  unreconciled_amount: string;
  unreconciled_deposit_amount: string;
}

export interface PaymentApplicationSchema {
  amount: string;
  payment: PaymentDumpSchema;
}

export interface PaymentReconcileResponseSchema {
  customer_balance_available: string;
  customer_balance_used: string;
  invoices: InvoiceApplicationSchema[];
  new_payment_amount: string;
  new_payment_method_id?: PaymentReconcileResponseSchemaNewPaymentMethodId;
  payments: PaymentApplicationSchema[];
}

export type InvoiceType = typeof InvoiceType[keyof typeof InvoiceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceType = {
  cycle: 'cycle',
  payment_surcharge: 'payment_surcharge',
  prepayment: 'prepayment',
  credit_note: 'credit_note',
  debit_note: 'debit_note',
} as const;

export type InvoiceStatus = typeof InvoiceStatus[keyof typeof InvoiceStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceStatus = {
  scheduled: 'scheduled',
  pending: 'pending',
  paid: 'paid',
  voided: 'voided',
  delinquent: 'delinquent',
  uncollectible: 'uncollectible',
} as const;

export type InvoiceDumpSchemaParentInvoiceId = string | null;

export type InvoiceDumpSchemaParentInvoice = LinkIDSchema | null;

export type InvoiceDumpSchemaCycleStart = string | null;

export type InvoiceDumpSchemaCycleEnd = string | null;

export type InvoiceDumpSchemaCustomerPoNumber = string | null;

export interface InvoiceDumpSchema {
  activity_log_id: string;
  amount: string;
  amount_due: string;
  amount_paid: string;
  charge_at: string;
  created_at: string;
  customer: LinkIDNameSchema;
  customer_id: string;
  customer_po_number?: InvoiceDumpSchemaCustomerPoNumber;
  cycle_end?: InvoiceDumpSchemaCycleEnd;
  cycle_start?: InvoiceDumpSchemaCycleStart;
  due_at: string;
  id: string;
  identifier: string;
  order: LinkIDSchema;
  order_id: string;
  parent_invoice?: InvoiceDumpSchemaParentInvoice;
  parent_invoice_id?: InvoiceDumpSchemaParentInvoiceId;
  status: InvoiceStatus;
  type: InvoiceType;
}

export interface InvoiceApplicationSchema {
  amount: string;
  invoice: InvoiceDumpSchema;
}

export type DepositStatus = typeof DepositStatus[keyof typeof DepositStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepositStatus = {
  pending: 'pending',
  paid: 'paid',
  failed: 'failed',
} as const;

export type PaymentReconcileInitialStateBodySchemaPaymentId = string | null;

export type PaymentReconcileInitialStateBodySchemaOrderId = string | null;

export type PaymentReconcileInitialStateBodySchemaInvoiceId = string | null;

export type PaymentReconcileInitialStateBodySchemaCustomerId = string | null;

export interface PaymentReconcileInitialStateBodySchema {
  customer_id?: PaymentReconcileInitialStateBodySchemaCustomerId;
  invoice_id?: PaymentReconcileInitialStateBodySchemaInvoiceId;
  order_id?: PaymentReconcileInitialStateBodySchemaOrderId;
  payment_id?: PaymentReconcileInitialStateBodySchemaPaymentId;
}

export interface KitLineItemCreateSchema {
  klass_id: string;
  quantity: number;
}

export interface KitCreateSchema {
  line_items: KitLineItemCreateSchema[];
  name: string;
}

export type PaginatedKitSchemaNext = number | null;

export interface KitLineItemDumpSchema {
  id: string;
  klass: KlassDumpSchema;
  quantity: number;
}

export interface KitDumpSchema {
  id: string;
  line_items: KitLineItemDumpSchema[];
  name: string;
}

export interface PaginatedKitSchema {
  elements: KitDumpSchema[];
  next?: PaginatedKitSchemaNext;
  total_count: number;
}

export interface BaseRateTemplateLineItemSchema {
  frequency: KlassPricingFrequency;
  multiplier: string;
}

export interface RateTemplateCreateSchema {
  is_business_default: boolean;
  line_items: BaseRateTemplateLineItemSchema[];
  name: string;
}

export type PaginatedRateTemplateSchemaNext = number | null;

export interface RateTemplateLineItemDumpSchema {
  frequency: KlassPricingFrequency;
  id: string;
  multiplier: string;
}

export interface RateTemplateDumpSchema {
  id: string;
  is_business_default: boolean;
  line_items: RateTemplateLineItemDumpSchema[];
  name: string;
}

export interface PaginatedRateTemplateSchema {
  elements: RateTemplateDumpSchema[];
  next?: PaginatedRateTemplateSchemaNext;
  total_count: number;
}

export type BaseRateProfileSchemaMeterHoursPerWeek = number | null;

export type BaseRateProfileSchemaMeterHoursPerMonth = number | null;

export type BaseRateProfileSchemaMeterHoursPerDay = number | null;

export type BaseRateProfileSchemaIsProrateExtensionsEnabled = boolean | null;

export type BaseRateProfileSchemaIsProrateEarlyCallOffsEnabled = boolean | null;

export type BaseRateProfileSchemaClockOvertimePenalty = string | null;

export type BaseRateProfileSchemaClockHoursPerWeek = number | null;

export type BaseRateProfileSchemaClockHoursPerMonth = number | null;

export type BaseRateProfileSchemaClockHoursPerDay = number | null;

export interface ShiftSchema {
  max_hours_per_day: number;
  max_hours_per_month: number;
  max_hours_per_week: number;
  multiplier: string;
  name: string;
}

export interface BaseRateProfileSchema {
  always_subtract_grace_period: boolean;
  clock_grace_period: number;
  clock_hours_per_day?: BaseRateProfileSchemaClockHoursPerDay;
  clock_hours_per_month?: BaseRateProfileSchemaClockHoursPerMonth;
  clock_hours_per_week?: BaseRateProfileSchemaClockHoursPerWeek;
  clock_overtime_methodology: ClockOvertimeMethodology;
  clock_overtime_penalty?: BaseRateProfileSchemaClockOvertimePenalty;
  is_business_default: boolean;
  is_prorate_early_call_offs_enabled?: BaseRateProfileSchemaIsProrateEarlyCallOffsEnabled;
  is_prorate_extensions_enabled?: BaseRateProfileSchemaIsProrateExtensionsEnabled;
  meter_hours_per_day?: BaseRateProfileSchemaMeterHoursPerDay;
  meter_hours_per_month?: BaseRateProfileSchemaMeterHoursPerMonth;
  meter_hours_per_week?: BaseRateProfileSchemaMeterHoursPerWeek;
  meter_overtime_methodology: MeterOvertimeMethodology;
  name: string;
  shifts: ShiftSchema[];
}

export type PaginatedRateProfileSchemaNext = number | null;

export interface PaginatedRateProfileSchema {
  elements: RateProfileDumpSchema[];
  next?: PaginatedRateProfileSchemaNext;
  total_count: number;
}

export interface UsageMetricTypeUpdateSchema {
  metric: UsageMetricUnit;
  name: string;
  type: UsageMetricTypeEnum;
}

export interface UsageMetricTypeCreateSchema {
  metric: UsageMetricUnit;
  name: string;
  type: UsageMetricTypeEnum;
}

export type PaginatedUsageMetricTypeResponseSchemaNext = number | null;

export interface PaginatedUsageMetricTypeResponseSchema {
  elements: UsageMetricTypeDumpSchema[];
  next?: PaginatedUsageMetricTypeResponseSchemaNext;
  total_count: number;
}

export type PaginatedKlassOrderDumpSchemaNext = number | null;

export type KlassOrderListBodySchemaLocationId = string | null;

export type KlassOrderListBodySchemaLocationGroupId = string | null;

export type KlassOrderListBodySchemaCustomerId = string | null;

export type KlassOrderListBodySchemaBillingStartsAt = string | null;

export type KlassOrderListBodySchemaBillingProfileId = string | null;

export type KlassOrderListBodySchemaBillingEndsAt = string | null;

export interface KlassOrderListBodySchema {
  billing_ends_at?: KlassOrderListBodySchemaBillingEndsAt;
  billing_profile_id?: KlassOrderListBodySchemaBillingProfileId;
  billing_starts_at?: KlassOrderListBodySchemaBillingStartsAt;
  customer_id?: KlassOrderListBodySchemaCustomerId;
  location_group_id?: KlassOrderListBodySchemaLocationGroupId;
  location_id?: KlassOrderListBodySchemaLocationId;
  quantity: number;
}

export interface UsedPricingDumpSchema {
  base_price_per_unit: string;
  extended_price_per_unit: string;
  frequency: KlassPricingFrequency;
  number_used: number;
  prorated: boolean;
}

export interface KlassOrderResponseSchema {
  base_price_per_unit: string;
  count_assets: number;
  count_available_global: number;
  count_available_location: number;
  count_available_region: number;
  extended_base_price: string;
  extended_price: string;
  id: string;
  klass: KlassDumpSchema;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  total_price_per_unit: string;
  used_pricings: UsedPricingDumpSchema[];
}

export interface PaginatedKlassOrderDumpSchema {
  elements: KlassOrderResponseSchema[];
  next?: PaginatedKlassOrderDumpSchemaNext;
  total_count: number;
}

export type KlassPricingDumpSchemaLocationId = string | null;

export type KlassPricingDumpSchemaLineItemId = string | null;

export type KlassPricingDumpSchemaKlassId = string | null;

export type KlassPricingDumpSchemaCustomerId = string | null;

export interface KlassPricingDumpSchema {
  amount: string;
  customer_id?: KlassPricingDumpSchemaCustomerId;
  frequency: KlassPricingFrequency;
  id: string;
  klass_id?: KlassPricingDumpSchemaKlassId;
  line_item_id?: KlassPricingDumpSchemaLineItemId;
  location_id?: KlassPricingDumpSchemaLocationId;
  type: KlassPricingType;
}

export type KlassOrderDetailBodySchemaShiftId = string | null;

export type KlassOrderDetailBodySchemaLocationId = string | null;

export type KlassOrderDetailBodySchemaLocationGroupId = string | null;

export type KlassOrderDetailBodySchemaLineItemId = string | null;

export type KlassOrderDetailBodySchemaCustomerId = string | null;

export type KlassOrderDetailBodySchemaBillingStartsAt = string | null;

export type KlassOrderDetailBodySchemaBillingProfileId = string | null;

export type KlassOrderDetailBodySchemaBillingEndsAt = string | null;

export type ModifierSchemaRate = string | null;

export type ModifierSchemaExpenseRuleId = string | null;

export interface ModifierSchema {
  expense_rule_id?: ModifierSchemaExpenseRuleId;
  rate?: ModifierSchemaRate;
}

export interface KlassOrderDetailBodySchema {
  billing_ends_at?: KlassOrderDetailBodySchemaBillingEndsAt;
  billing_profile_id?: KlassOrderDetailBodySchemaBillingProfileId;
  billing_starts_at?: KlassOrderDetailBodySchemaBillingStartsAt;
  customer_id?: KlassOrderDetailBodySchemaCustomerId;
  line_item_id?: KlassOrderDetailBodySchemaLineItemId;
  location_group_id?: KlassOrderDetailBodySchemaLocationGroupId;
  location_id?: KlassOrderDetailBodySchemaLocationId;
  modifiers?: ModifierSchema[];
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  shift_id?: KlassOrderDetailBodySchemaShiftId;
}

export type KlassUpdateSchemaUsefulLife = number | null;

export type KlassUpdateSchemaRateProfileId = string | null;

export type KlassUpdateSchemaProductTaxCodeGroupId = string | null;

export type KlassUpdateSchemaPickupCustomFormId = string | null;

export type KlassUpdateSchemaMinimumRentalPeriodDelivery = number | null;

export type KlassUpdateSchemaInventoryProfileId = string | null;

export type KlassUpdateSchemaInternalDescription = string | null;

export type KlassUpdateSchemaIdentifier = string | null;

export type KlassUpdateSchemaExternalDescription = string | null;

export type KlassUpdateSchemaDropoffCustomFormId = string | null;

export type KlassUpdateSchemaDepreciationSettingId = string | null;

export interface KlassUpdateSchema {
  attachments: InventoryUploadCreateSchema[];
  category_id: string;
  depreciation_setting_id?: KlassUpdateSchemaDepreciationSettingId;
  dropoff_custom_form_id?: KlassUpdateSchemaDropoffCustomFormId;
  external_description?: KlassUpdateSchemaExternalDescription;
  identifier?: KlassUpdateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  internal_description?: KlassUpdateSchemaInternalDescription;
  inventory_profile_id?: KlassUpdateSchemaInventoryProfileId;
  is_print_meter_gauge: boolean;
  minimum_rental_period_delivery?: KlassUpdateSchemaMinimumRentalPeriodDelivery;
  modifiers: ExpenseClassAssociationCreateSchema[];
  name: string;
  pickup_custom_form_id?: KlassUpdateSchemaPickupCustomFormId;
  pricings: KlassPricingCreateSchema[];
  print_visibility_type: PrintVisibilityType;
  product_tax_code_group_id?: KlassUpdateSchemaProductTaxCodeGroupId;
  products: ProductUpdateSchema[];
  rate_profile_id?: KlassUpdateSchemaRateProfileId;
  specifications: KlassSpecificationSchema[];
  tags: string[];
  terms_and_conditions_addendum_ids: string[];
  tracking: TrackingType;
  useful_life?: KlassUpdateSchemaUsefulLife;
  visibility: VisibilityType;
}

export type UsageMetricConfigurationUpdateSchemaMaxCapacity = string | null;

export type UsageMetricConfigurationUpdateSchemaId = string | null;

export type UsageMetricConfigurationUpdateSchemaFuelType = FuelType | null;

export interface UsageMetricConfigurationUpdateSchema {
  fuel_type?: UsageMetricConfigurationUpdateSchemaFuelType;
  id?: UsageMetricConfigurationUpdateSchemaId;
  max_capacity?: UsageMetricConfigurationUpdateSchemaMaxCapacity;
  primary: boolean;
  usage_metric_type_id: string;
}

export type SerialNumberConfigurationUpdateSchemaId = string | null;

export interface SerialNumberConfigurationUpdateSchema {
  id?: SerialNumberConfigurationUpdateSchemaId;
  is_primary: boolean;
  name: string;
}

export type ProductUpdateSchemaModel = string | null;

export type ProductUpdateSchemaMake = string | null;

export type ProductUpdateSchemaId = string | null;

export interface ProductUpdateSchema {
  id?: ProductUpdateSchemaId;
  make?: ProductUpdateSchemaMake;
  model?: ProductUpdateSchemaModel;
  name: string;
  serial_number_configurations: SerialNumberConfigurationUpdateSchema[];
  usage_metric_configurations: UsageMetricConfigurationUpdateSchema[];
  vendors: BaseProductVendorSchema[];
}

export type KlassCreateSchemaUsefulLife = number | null;

export type KlassCreateSchemaRateProfileId = string | null;

export type KlassCreateSchemaProductTaxCodeGroupId = string | null;

export type KlassCreateSchemaPickupCustomFormId = string | null;

export type KlassCreateSchemaMinimumRentalPeriodDelivery = number | null;

export type KlassCreateSchemaInventoryProfileId = string | null;

export type KlassCreateSchemaInternalDescription = string | null;

export type KlassCreateSchemaIdentifier = string | null;

export type KlassCreateSchemaExternalDescription = string | null;

export type KlassCreateSchemaDropoffCustomFormId = string | null;

export type KlassCreateSchemaDepreciationSettingId = string | null;

export interface KlassCreateSchema {
  attachments: InventoryUploadCreateSchema[];
  category_id: string;
  depreciation_setting_id?: KlassCreateSchemaDepreciationSettingId;
  dropoff_custom_form_id?: KlassCreateSchemaDropoffCustomFormId;
  external_description?: KlassCreateSchemaExternalDescription;
  identifier?: KlassCreateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  internal_description?: KlassCreateSchemaInternalDescription;
  inventory_profile_id?: KlassCreateSchemaInventoryProfileId;
  is_print_meter_gauge: boolean;
  minimum_rental_period_delivery?: KlassCreateSchemaMinimumRentalPeriodDelivery;
  modifiers: ExpenseClassAssociationCreateSchema[];
  name: string;
  pickup_custom_form_id?: KlassCreateSchemaPickupCustomFormId;
  pricings: KlassPricingCreateSchema[];
  print_visibility_type: PrintVisibilityType;
  product_tax_code_group_id?: KlassCreateSchemaProductTaxCodeGroupId;
  products: ProductCreateSchema[];
  rate_profile_id?: KlassCreateSchemaRateProfileId;
  specifications: KlassSpecificationSchema[];
  tags: string[];
  terms_and_conditions_addendum_ids: string[];
  tracking: TrackingType;
  useful_life?: KlassCreateSchemaUsefulLife;
  visibility: VisibilityType;
}

export type UsageMetricConfigurationCreateSchemaMaxCapacity = string | null;

export type UsageMetricConfigurationCreateSchemaFuelType = FuelType | null;

export interface UsageMetricConfigurationCreateSchema {
  fuel_type?: UsageMetricConfigurationCreateSchemaFuelType;
  max_capacity?: UsageMetricConfigurationCreateSchemaMaxCapacity;
  primary: boolean;
  usage_metric_type_id: string;
}

export interface SerialNumberConfigurationCreateSchema {
  is_primary: boolean;
  name: string;
}

export type ProductCreateSchemaModel = string | null;

export type ProductCreateSchemaMake = string | null;

export interface ProductCreateSchema {
  make?: ProductCreateSchemaMake;
  model?: ProductCreateSchemaModel;
  name: string;
  serial_number_configurations: SerialNumberConfigurationCreateSchema[];
  usage_metric_configurations: UsageMetricConfigurationCreateSchema[];
  vendors: BaseProductVendorSchema[];
}

export type BaseProductVendorSchemaVendorSku = string | null;

export interface BaseProductVendorSchema {
  vendor_id: string;
  vendor_sku?: BaseProductVendorSchemaVendorSku;
}

export type KlassHydratedDumpSchemaUsefulLife = number | null;

export type KlassHydratedDumpSchemaRateProfileId = string | null;

export type KlassHydratedDumpSchemaRateProfile = RateProfileDumpSchema | null;

export type KlassHydratedDumpSchemaProductTaxCodeGroupId = string | null;

export type KlassHydratedDumpSchemaProductTaxCodeGroup = ProductTaxCodeGroupDumpSchema | null;

export type KlassHydratedDumpSchemaPickupCustomForm = LinkNameSchema | null;

export type KlassHydratedDumpSchemaMinimumRentalPeriodDelivery = number | null;

export type KlassHydratedDumpSchemaInventoryProfileId = string | null;

export type KlassHydratedDumpSchemaInventoryProfile = InventoryProfileDumpSchema | null;

export type KlassHydratedDumpSchemaInternalDescription = string | null;

export type KlassHydratedDumpSchemaExternalDescription = string | null;

export type KlassHydratedDumpSchemaDropoffCustomForm = LinkNameSchema | null;

export type KlassHydratedDumpSchemaDepreciationSettingId = string | null;

export type KlassHydratedDumpSchemaDepreciationSetting = DepreciationSettingDumpSchema | null;

export interface KlassSpecificationSchema {
  name: string;
  primary: boolean;
  value: string;
}

export interface KlassHydratedDumpSchema {
  attachments: InventoryUploadDumpSchema[];
  category: CategoryDumpSchema;
  category_id: string;
  count_assets: number;
  count_available_assets: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  depreciation_setting?: KlassHydratedDumpSchemaDepreciationSetting;
  depreciation_setting_id?: KlassHydratedDumpSchemaDepreciationSettingId;
  dropoff_custom_form?: KlassHydratedDumpSchemaDropoffCustomForm;
  external_description?: KlassHydratedDumpSchemaExternalDescription;
  id: string;
  identifier: string;
  images: CustomerFileDumpSchema[];
  internal_description?: KlassHydratedDumpSchemaInternalDescription;
  inventory_profile?: KlassHydratedDumpSchemaInventoryProfile;
  inventory_profile_id?: KlassHydratedDumpSchemaInventoryProfileId;
  is_print_meter_gauge: boolean;
  minimum_rental_period_delivery?: KlassHydratedDumpSchemaMinimumRentalPeriodDelivery;
  modifiers: ExpenseClassDumpSchemaWithRule[];
  name: string;
  pickup_custom_form?: KlassHydratedDumpSchemaPickupCustomForm;
  pricings: KlassPricingHydratedDumpSchema[];
  print_visibility_type: PrintVisibilityType;
  product_tax_code_group?: KlassHydratedDumpSchemaProductTaxCodeGroup;
  product_tax_code_group_id?: KlassHydratedDumpSchemaProductTaxCodeGroupId;
  products: ProductDumpSchema[];
  rate_profile?: KlassHydratedDumpSchemaRateProfile;
  rate_profile_id?: KlassHydratedDumpSchemaRateProfileId;
  specifications: KlassSpecificationSchema[];
  tags: string[];
  terms_and_conditions_addendums: LinkNameSchema[];
  tracking: TrackingType;
  type: KlassType;
  useful_life?: KlassHydratedDumpSchemaUsefulLife;
  visibility: VisibilityType;
}

export type PaginatedKlassDumpSchemaNext = number | null;

export interface PaginatedKlassDumpSchema {
  elements: KlassDumpSchema[];
  next?: PaginatedKlassDumpSchemaNext;
  total_count: number;
}

export interface AssetUsageMetricBodySchema {
  usage_metric_values: UsageMetricValueCreateSchema[];
}

export type BulkAssetUpdateSchemaModelYear = number | null;

export interface BulkAssetUpdateSchema {
  model_year?: BulkAssetUpdateSchemaModelYear;
  tags: string[];
}

export type PaginatedAssetOrderSchemaNext = number | null;

export interface PaginatedAssetOrderSchema {
  elements: AssetOrderDumpSchema[];
  next?: PaginatedAssetOrderSchemaNext;
  total_count: number;
}

export type AssetOrderDumpSchemaTitleNumber = string | null;

export type AssetOrderDumpSchemaTitleName = string | null;

export type AssetOrderDumpSchemaTitleDate = string | null;

export type AssetOrderDumpSchemaResidualValue = string | null;

export type AssetOrderDumpSchemaRegistrationDate = string | null;

export type AssetOrderDumpSchemaModelYear = number | null;

export type AssetOrderDumpSchemaLicensePlateNumber = string | null;

export type AssetOrderDumpSchemaEngineModel = string | null;

export type AssetOrderDumpSchemaEngineMake = string | null;

export type AssetOrderDumpSchemaEngineCapacity = string | null;

export type AssetOrderDumpSchemaDepreciationStartDate = string | null;

export type AssetOrderDumpSchemaDateRegistrationExpires = string | null;

export type AssetOrderDumpSchemaBarcodeType = BarcodeType | null;

export type AssetOrderDumpSchemaBarcodeCode = string | null;

export interface AssetOrderDumpSchema {
  acquisition_date: string;
  acquisition_value: string;
  available: boolean;
  barcode_code?: AssetOrderDumpSchemaBarcodeCode;
  barcode_type?: AssetOrderDumpSchemaBarcodeType;
  count_assets: number;
  count_available_assets: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  date_registration_expires?: AssetOrderDumpSchemaDateRegistrationExpires;
  depreciation_start_date?: AssetOrderDumpSchemaDepreciationStartDate;
  engine_capacity?: AssetOrderDumpSchemaEngineCapacity;
  engine_make?: AssetOrderDumpSchemaEngineMake;
  engine_model?: AssetOrderDumpSchemaEngineModel;
  id: string;
  identifier: string;
  klass: KlassDumpSchema;
  klass_id: string;
  license_plate_number?: AssetOrderDumpSchemaLicensePlateNumber;
  location: LinkIDNameSchema;
  location_id: string;
  model_year?: AssetOrderDumpSchemaModelYear;
  product: LinkNameSchema;
  product_id: string;
  registration_date?: AssetOrderDumpSchemaRegistrationDate;
  residual_value?: AssetOrderDumpSchemaResidualValue;
  title_date?: AssetOrderDumpSchemaTitleDate;
  title_name?: AssetOrderDumpSchemaTitleName;
  title_number?: AssetOrderDumpSchemaTitleNumber;
  vendor: LinkNameSchema;
  vendor_id: string;
}

export type AssetOrderSearchParamsStart = number | null;

export type AssetOrderSearchParamsSearch = string | null;

export type AssetOrderSearchParamsPageSize = number | null;

export type AssetOrderSearchParamsLocationIds = string[] | null;

export type AssetOrderSearchParamsLocationGroupId = string | null;

export type AssetOrderSearchParamsEndDatetime = string | null;

export interface AssetOrderSearchParams {
  end_datetime?: AssetOrderSearchParamsEndDatetime;
  klass_ids: string[];
  location_group_id?: AssetOrderSearchParamsLocationGroupId;
  location_ids?: AssetOrderSearchParamsLocationIds;
  page_size?: AssetOrderSearchParamsPageSize;
  search?: AssetOrderSearchParamsSearch;
  start?: AssetOrderSearchParamsStart;
  start_datetime: string;
}

export type AssetUpdateSchemaTitleNumber = string | null;

export type AssetUpdateSchemaTitleName = string | null;

export type AssetUpdateSchemaTitleLocation = AddressCreateSchema | null;

export type AssetUpdateSchemaTitleDate = string | null;

export type AssetUpdateSchemaResidualValue = string | null;

export type AssetUpdateSchemaRegistrationDate = string | null;

export type AssetUpdateSchemaModelYear = number | null;

export type AssetUpdateSchemaLicensePlateNumber = string | null;

export type AssetUpdateSchemaIdentifier = string | null;

export type AssetUpdateSchemaEngineModel = string | null;

export type AssetUpdateSchemaEngineMake = string | null;

export type AssetUpdateSchemaEngineCapacity = string | null;

export type AssetUpdateSchemaDepreciationStartDate = string | null;

export type AssetUpdateSchemaDateRegistrationExpires = string | null;

export type AssetUpdateSchemaBarcodeType = BarcodeType | null;

export type AssetUpdateSchemaBarcodeCode = string | null;

export interface AssetUpdateSchema {
  acquisition_date: string;
  acquisition_value: string;
  attachments: InventoryUploadCreateSchema[];
  barcode_code?: AssetUpdateSchemaBarcodeCode;
  barcode_type?: AssetUpdateSchemaBarcodeType;
  date_registration_expires?: AssetUpdateSchemaDateRegistrationExpires;
  depreciation_start_date?: AssetUpdateSchemaDepreciationStartDate;
  engine_capacity?: AssetUpdateSchemaEngineCapacity;
  engine_make?: AssetUpdateSchemaEngineMake;
  engine_model?: AssetUpdateSchemaEngineModel;
  identifier?: AssetUpdateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  is_delivery_truck?: boolean;
  klass_id: string;
  license_plate_number?: AssetUpdateSchemaLicensePlateNumber;
  location_id: string;
  model_year?: AssetUpdateSchemaModelYear;
  product_id: string;
  registration_date?: AssetUpdateSchemaRegistrationDate;
  residual_value?: AssetUpdateSchemaResidualValue;
  serial_number_values: SerialNumberValueCreateSchema[];
  tags: string[];
  title_date?: AssetUpdateSchemaTitleDate;
  title_location?: AssetUpdateSchemaTitleLocation;
  title_name?: AssetUpdateSchemaTitleName;
  title_number?: AssetUpdateSchemaTitleNumber;
  vendor_id: string;
}

export type AssetCreateSchemaTitleNumber = string | null;

export type AssetCreateSchemaTitleName = string | null;

export type AssetCreateSchemaTitleLocation = AddressCreateSchema | null;

export type AssetCreateSchemaTitleDate = string | null;

export type AssetCreateSchemaResidualValue = string | null;

export type AssetCreateSchemaRegistrationDate = string | null;

export type AssetCreateSchemaModelYear = number | null;

export type AssetCreateSchemaLicensePlateNumber = string | null;

export type AssetCreateSchemaIdentifier = string | null;

export type AssetCreateSchemaEngineModel = string | null;

export type AssetCreateSchemaEngineMake = string | null;

export type AssetCreateSchemaEngineCapacity = string | null;

export type AssetCreateSchemaDepreciationStartDate = string | null;

export type AssetCreateSchemaDateRegistrationExpires = string | null;

export type AssetCreateSchemaBarcodeType = BarcodeType | null;

export type AssetCreateSchemaBarcodeCode = string | null;

export interface UsageMetricValueCreateSchema {
  usage_metric_configuration_id: string;
  value: string;
}

export interface SerialNumberValueCreateSchema {
  serial_number_configuration_id: string;
  value: string;
}

export interface AssetCreateSchema {
  acquisition_date: string;
  acquisition_value: string;
  attachments: InventoryUploadCreateSchema[];
  barcode_code?: AssetCreateSchemaBarcodeCode;
  barcode_type?: AssetCreateSchemaBarcodeType;
  date_registration_expires?: AssetCreateSchemaDateRegistrationExpires;
  depreciation_start_date?: AssetCreateSchemaDepreciationStartDate;
  engine_capacity?: AssetCreateSchemaEngineCapacity;
  engine_make?: AssetCreateSchemaEngineMake;
  engine_model?: AssetCreateSchemaEngineModel;
  identifier?: AssetCreateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  is_delivery_truck?: boolean;
  klass_id: string;
  license_plate_number?: AssetCreateSchemaLicensePlateNumber;
  location_id: string;
  model_year?: AssetCreateSchemaModelYear;
  product_id: string;
  quantity?: number;
  registration_date?: AssetCreateSchemaRegistrationDate;
  residual_value?: AssetCreateSchemaResidualValue;
  serial_number_values: SerialNumberValueCreateSchema[];
  tags: string[];
  title_date?: AssetCreateSchemaTitleDate;
  title_location?: AssetCreateSchemaTitleLocation;
  title_name?: AssetCreateSchemaTitleName;
  title_number?: AssetCreateSchemaTitleNumber;
  usage_metric_values: UsageMetricValueCreateSchema[];
  vendor_id: string;
}

export type PaginatedUsageMetricDumpSchemaNext = number | null;

export interface UsageMetricValueBaseDumpSchema {
  created_at: string;
  usage_metric_configuration_id: string;
  user: LinkNameSchema;
  user_id: string;
  value: string;
}

export interface PaginatedUsageMetricDumpSchema {
  elements: UsageMetricValueBaseDumpSchema[];
  next?: PaginatedUsageMetricDumpSchemaNext;
  total_count: number;
  usage_metric_configuration: UsageMetricConfigurationDumpSchema;
}

export type AssetHydratedDumpSchemaTitleNumber = string | null;

export type AssetHydratedDumpSchemaTitleName = string | null;

export type AssetHydratedDumpSchemaTitleLocation = AddressDumpSchema | null;

export type AssetHydratedDumpSchemaTitleDate = string | null;

export type AssetHydratedDumpSchemaThumbnail = CustomerFileDumpSchema | null;

export type AssetHydratedDumpSchemaResidualValue = string | null;

export type AssetHydratedDumpSchemaRegistrationDate = string | null;

export type AssetHydratedDumpSchemaModelYear = number | null;

export type AssetHydratedDumpSchemaLicensePlateNumber = string | null;

export type AssetHydratedDumpSchemaEngineModel = string | null;

export type AssetHydratedDumpSchemaEngineMake = string | null;

export type AssetHydratedDumpSchemaEngineCapacity = string | null;

export type AssetHydratedDumpSchemaDepreciationStartDate = string | null;

export type AssetHydratedDumpSchemaDateRegistrationExpires = string | null;

export type AssetHydratedDumpSchemaBarcodeType = BarcodeType | null;

export type AssetHydratedDumpSchemaBarcodeCode = string | null;

export type AssetHydratedDumpSchemaAverageAcquisitionValue = string | null;

export interface UsageMetricValueDumpSchema {
  created_at: string;
  usage_metric_configuration: UsageMetricConfigurationDumpSchema;
  usage_metric_configuration_id: string;
  user: LinkNameSchema;
  user_id: string;
  value: string;
}

export interface SerialNumberValueDumpSchema {
  serial_number_configuration: SerialNumberConfigurationDumpSchema;
  serial_number_configuration_id: string;
  value: string;
}

export interface AssetHydratedDumpSchema {
  acquisition_date: string;
  acquisition_value: string;
  attachments: InventoryUploadDumpSchema[];
  average_acquisition_value?: AssetHydratedDumpSchemaAverageAcquisitionValue;
  barcode_code?: AssetHydratedDumpSchemaBarcodeCode;
  barcode_type?: AssetHydratedDumpSchemaBarcodeType;
  count_assets: number;
  count_available_assets: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  date_registration_expires?: AssetHydratedDumpSchemaDateRegistrationExpires;
  depreciation_start_date?: AssetHydratedDumpSchemaDepreciationStartDate;
  engine_capacity?: AssetHydratedDumpSchemaEngineCapacity;
  engine_make?: AssetHydratedDumpSchemaEngineMake;
  engine_model?: AssetHydratedDumpSchemaEngineModel;
  id: string;
  identifier: string;
  images: CustomerFileDumpSchema[];
  is_delivery_truck: boolean;
  klass: KlassDumpSchema;
  klass_id: string;
  license_plate_number?: AssetHydratedDumpSchemaLicensePlateNumber;
  location: LocationDumpSchema;
  location_id: string;
  model_year?: AssetHydratedDumpSchemaModelYear;
  product: ProductDumpSchema;
  product_id: string;
  registration_date?: AssetHydratedDumpSchemaRegistrationDate;
  residual_value?: AssetHydratedDumpSchemaResidualValue;
  serial_numbers: SerialNumberValueDumpSchema[];
  tags: string[];
  thumbnail?: AssetHydratedDumpSchemaThumbnail;
  title_date?: AssetHydratedDumpSchemaTitleDate;
  title_location?: AssetHydratedDumpSchemaTitleLocation;
  title_name?: AssetHydratedDumpSchemaTitleName;
  title_number?: AssetHydratedDumpSchemaTitleNumber;
  usage_metrics: UsageMetricValueDumpSchema[];
  vendor: VendorDumpSchema;
  vendor_id: string;
}

export type PaginatedAssetSchemaNext = number | null;

export interface PaginatedAssetSchema {
  elements: AssetDumpSchema[];
  next?: PaginatedAssetSchemaNext;
  total_count: number;
}

export type PaginatedInventoryDumpSchemaNext = number | null;

export interface PaginatedInventoryDumpSchema {
  elements: InventoryUploadDumpSchema[];
  next?: PaginatedInventoryDumpSchemaNext;
  total_count: number;
}

export type ProductDumpSchemaModel = string | null;

export type ProductDumpSchemaMake = string | null;

export interface ProductDumpSchema {
  asset_count: number;
  can_delete: boolean;
  id: string;
  make?: ProductDumpSchemaMake;
  model?: ProductDumpSchemaModel;
  name: string;
  serial_number_configurations: SerialNumberConfigurationDumpSchema[];
  usage_gauge_configurations: UsageMetricConfigurationDumpSchema[];
  usage_metric_configurations: UsageMetricConfigurationDumpSchema[];
  vendors: ProductVendorDumpSchema[];
}

export type VendorTypeEnum = typeof VendorTypeEnum[keyof typeof VendorTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorTypeEnum = {
  business: 'business',
  individual: 'individual',
} as const;

export type VendorDumpSchemaWebAddress = string | null;

export type VendorDumpSchemaRemoteObject = RemoteObjectDumpSchema | null;

export type VendorDumpSchemaProducts = string | null;

export type VendorDumpSchemaPhone = string | null;

export type VendorDumpSchemaNotes = string | null;

export type VendorDumpSchemaEmail = string | null;

export type VendorDumpSchemaDisplayName = string | null;

export type VendorDumpSchemaAddress = AddressDumpSchema | null;

export type VendorDumpSchemaAccountNumber = string | null;

export type VendorContactDumpSchemaPhone = string | null;

export type VendorContactDumpSchemaEmail = string | null;

export interface VendorContactDumpSchema {
  email?: VendorContactDumpSchemaEmail;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  phone?: VendorContactDumpSchemaPhone;
}

export interface VendorDumpSchema {
  account_number?: VendorDumpSchemaAccountNumber;
  activity_log_id: string;
  address?: VendorDumpSchemaAddress;
  autogenerated_identifier: string;
  contacts: VendorContactDumpSchema[];
  display_name?: VendorDumpSchemaDisplayName;
  email?: VendorDumpSchemaEmail;
  id: string;
  name: string;
  notes?: VendorDumpSchemaNotes;
  original_name: string;
  payment_method: PaymentMethodType;
  payment_terms: PaymentTerms;
  phone?: VendorDumpSchemaPhone;
  products?: VendorDumpSchemaProducts;
  remote_object?: VendorDumpSchemaRemoteObject;
  type: VendorTypeEnum;
  web_address?: VendorDumpSchemaWebAddress;
}

export type UsageMetricUnit = typeof UsageMetricUnit[keyof typeof UsageMetricUnit];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageMetricUnit = {
  miles: 'miles',
  hours: 'hours',
  gallons: 'gallons',
  liters: 'liters',
} as const;

export type UsageMetricTypeEnum = typeof UsageMetricTypeEnum[keyof typeof UsageMetricTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageMetricTypeEnum = {
  standard: 'standard',
  fuel: 'fuel',
} as const;

export interface UsageMetricTypeDumpSchema {
  id: string;
  metric: UsageMetricUnit;
  name: string;
  type: UsageMetricTypeEnum;
}

export type UsageMetricConfigurationDumpSchemaMaxCapacity = string | null;

export type UsageMetricConfigurationDumpSchemaFuelType = FuelType | null;

export interface UsageMetricConfigurationDumpSchema {
  fuel_type?: UsageMetricConfigurationDumpSchemaFuelType;
  id: string;
  max_capacity?: UsageMetricConfigurationDumpSchemaMaxCapacity;
  primary: boolean;
  usage_metric_type: UsageMetricTypeDumpSchema;
  usage_metric_type_id: string;
}

export interface SerialNumberConfigurationDumpSchema {
  id: string;
  is_primary: boolean;
  name: string;
}

export type ProductVendorDumpSchemaVendorSku = string | null;

export interface ProductVendorDumpSchema {
  vendor: VendorDumpSchema;
  vendor_id: string;
  vendor_sku?: ProductVendorDumpSchemaVendorSku;
}

export type PaymentMethodType = typeof PaymentMethodType[keyof typeof PaymentMethodType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodType = {
  card: 'card',
  ach: 'ach',
  invoice: 'invoice',
} as const;

export type FuelType = typeof FuelType[keyof typeof FuelType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FuelType = {
  diesel: 'diesel',
  gasoline: 'gasoline',
  propane: 'propane',
  oil: 'oil',
} as const;

export interface ExpenseClassOrderDumpSchema {
  excluded_modifiers: ExpenseClassDumpSchema[];
  optional_modifiers: ExpenseClassDumpSchemaWithRule[];
  required_modifiers: ExpenseClassDumpSchemaWithRule[];
}

export type ExpenseClassDumpSchemaWithRuleRevenueAccountId = string | null;

export type ExpenseClassDumpSchemaWithRuleRevenueAccount = LedgerAccountDumpSchema | null;

export type ExpenseClassDumpSchemaWithRuleProductTaxCodeGroupId = string | null;

export interface ExpenseClassDumpSchemaWithRule {
  display_type: ExpenseClassDisplayType;
  id: string;
  name: string;
  product_tax_code_group_id?: ExpenseClassDumpSchemaWithRuleProductTaxCodeGroupId;
  revenue_account?: ExpenseClassDumpSchemaWithRuleRevenueAccount;
  revenue_account_id?: ExpenseClassDumpSchemaWithRuleRevenueAccountId;
  rule: ExpenseRuleDumpSchema;
  type: ExpenseClassType;
}

export type ExpenseClassSearchParamsTypeSearch = string | null;

export type ExpenseClassSearchParamsTypeKlassId = string | null;

export type ExpenseClassSearchParamsTypeKitLineItemId = string | null;

export type ExpenseClassSearchParamsTypeFeesOnly = boolean | null;

export type ExpenseClassSearchParamsTypeDiscountsOnly = boolean | null;

export type ExpenseClassSearchParamsTypeCustomerId = string | null;

export type ExpenseClassSearchParamsTypeBillingProfileId = string | null;

export type ExpenseClassSearchType = typeof ExpenseClassSearchType[keyof typeof ExpenseClassSearchType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseClassSearchType = {
  order__rental: 'order__rental',
  order__sale: 'order__sale',
  order__delivery: 'order__delivery',
  item__rental: 'item__rental',
  item__sale: 'item__sale',
  rental__all: 'rental__all',
  sale__all: 'sale__all',
  order__all: 'order__all',
} as const;

export interface ExpenseClassSearchParamsType {
  billing_profile_id?: ExpenseClassSearchParamsTypeBillingProfileId;
  customer_id?: ExpenseClassSearchParamsTypeCustomerId;
  discounts_only?: ExpenseClassSearchParamsTypeDiscountsOnly;
  fees_only?: ExpenseClassSearchParamsTypeFeesOnly;
  kit_line_item_id?: ExpenseClassSearchParamsTypeKitLineItemId;
  klass_id?: ExpenseClassSearchParamsTypeKlassId;
  search?: ExpenseClassSearchParamsTypeSearch;
  type: ExpenseClassSearchType;
}

export type ExpenseClassUpdateSchemaRule = ExpenseRuleCreateSchema | null;

export type ExpenseClassUpdateSchemaRevenueAccountId = string | null;

export type ExpenseClassUpdateSchemaProductTaxCodeGroupId = string | null;

export interface ExpenseClassUpdateSchema {
  display_type: ExpenseClassDisplayType;
  name: string;
  product_tax_code_group_id?: ExpenseClassUpdateSchemaProductTaxCodeGroupId;
  revenue_account_id?: ExpenseClassUpdateSchemaRevenueAccountId;
  rule?: ExpenseClassUpdateSchemaRule;
  type: ExpenseClassType;
}

export type ExpenseClassCreateSchemaRule = ExpenseRuleCreateSchema | null;

export type ExpenseClassCreateSchemaRevenueAccountId = string | null;

export type ExpenseClassCreateSchemaProductTaxCodeGroupId = string | null;

export interface ExpenseClassCreateSchema {
  display_type: ExpenseClassDisplayType;
  name: string;
  product_tax_code_group_id?: ExpenseClassCreateSchemaProductTaxCodeGroupId;
  revenue_account_id?: ExpenseClassCreateSchemaRevenueAccountId;
  rule?: ExpenseClassCreateSchemaRule;
  type: ExpenseClassType;
}

export type ExpenseRuleCreateSchemaKlassId = string | null;

export type ExpenseRuleCreateSchemaKitLineItemId = string | null;

export type ExpenseRuleCreateSchemaCustomerId = string | null;

export type ExpenseRuleCreateSchemaBillingProfileId = string | null;

export interface ExpenseRuleCreateSchema {
  billing_profile_id?: ExpenseRuleCreateSchemaBillingProfileId;
  customer_id?: ExpenseRuleCreateSchemaCustomerId;
  is_editable: boolean;
  kit_line_item_id?: ExpenseRuleCreateSchemaKitLineItemId;
  klass_id?: ExpenseRuleCreateSchemaKlassId;
  percentage: string;
  type: ExpenseRuleType;
}

export type PaginatedExpenseClassSchemaNext = number | null;

export type ExpenseRuleDumpSchemaKlassId = string | null;

export type ExpenseRuleDumpSchemaKitLineItemId = string | null;

export type ExpenseRuleDumpSchemaCustomerId = string | null;

export type ExpenseRuleDumpSchemaBillingProfileId = string | null;

export interface ExpenseRuleDumpSchema {
  billing_profile_id?: ExpenseRuleDumpSchemaBillingProfileId;
  customer_id?: ExpenseRuleDumpSchemaCustomerId;
  id: string;
  is_editable: boolean;
  kit_line_item_id?: ExpenseRuleDumpSchemaKitLineItemId;
  klass_id?: ExpenseRuleDumpSchemaKlassId;
  percentage: string;
  type: ExpenseRuleType;
}

export type ExpenseClassType = typeof ExpenseClassType[keyof typeof ExpenseClassType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseClassType = {
  order__rental: 'order__rental',
  order__sale: 'order__sale',
  order__delivery: 'order__delivery',
  item__rental: 'item__rental',
  item__sale: 'item__sale',
} as const;

export type ExpenseClassDumpSchemaRule = ExpenseRuleDumpSchema | null;

export type ExpenseClassDumpSchemaRevenueAccountId = string | null;

export type ExpenseClassDumpSchemaRevenueAccount = LedgerAccountDumpSchema | null;

export type ExpenseClassDumpSchemaProductTaxCodeGroupId = string | null;

export type ExpenseClassDisplayType = typeof ExpenseClassDisplayType[keyof typeof ExpenseClassDisplayType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseClassDisplayType = {
  independent: 'independent',
  grouped: 'grouped',
  merged: 'merged',
} as const;

export interface ExpenseClassDumpSchema {
  display_type: ExpenseClassDisplayType;
  id: string;
  name: string;
  product_tax_code_group_id?: ExpenseClassDumpSchemaProductTaxCodeGroupId;
  revenue_account?: ExpenseClassDumpSchemaRevenueAccount;
  revenue_account_id?: ExpenseClassDumpSchemaRevenueAccountId;
  rule?: ExpenseClassDumpSchemaRule;
  type: ExpenseClassType;
}

export interface PaginatedExpenseClassSchema {
  elements: ExpenseClassDumpSchema[];
  next?: PaginatedExpenseClassSchemaNext;
  total_count: number;
}

export interface RouteResponse {
  geometry: string;
  stops: string[];
}

export type OptimizationType = typeof OptimizationType[keyof typeof OptimizationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OptimizationType = {
  no_optimization: 'no_optimization',
  optimize: 'optimize',
} as const;

export interface RouteBody {
  optimization: OptimizationType;
}

export interface ArcGISToken {
  token: string;
}

export type PaginatedDeliveryTrucksDumpSchemaNext = number | null;

export interface PaginatedDeliveryTrucksDumpSchema {
  elements: DeliveryTruckDumpSchema[];
  next?: PaginatedDeliveryTrucksDumpSchemaNext;
  total_count: number;
}

export type PaginatedDeliveryDriversDumpSchemaNext = number | null;

export interface PaginatedDeliveryDriversDumpSchema {
  elements: DeliveryDriverDumpSchema[];
  next?: PaginatedDeliveryDriversDumpSchemaNext;
  total_count: number;
}

export type DeliveryRouteInsertSchemaInsertIndex = number | null;

export interface DeliveryRouteInsertSchema {
  insert_index?: DeliveryRouteInsertSchemaInsertIndex;
  stops: DeliveryRouteStopInsertSchema[];
}

export type DeliveryRouteStopInsertSchemaType = typeof DeliveryRouteStopInsertSchemaType[keyof typeof DeliveryRouteStopInsertSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteStopInsertSchemaType = {
  customer_address: 'customer_address',
} as const;

export interface DeliveryRouteStopInsertSchema {
  delivery_request_id: string;
  type: DeliveryRouteStopInsertSchemaType;
}

export type DeliveryRouteUpdateSchemaTruckId = string | null;

export type DeliveryRouteUpdateSchemaDriverId = string | null;

export interface DeliveryRouteUpdateSchema {
  delivery_date: string;
  driver_id?: DeliveryRouteUpdateSchemaDriverId;
  name: string;
  stops: string[];
  truck_id?: DeliveryRouteUpdateSchemaTruckId;
}

export type DeliveryRouteStopsDumpSchemaStopsItem = DeliveryRouteBranchStopDumpSchema | DeliveryRouteCustomerStopDumpSchema;

export interface DeliveryRouteStopsDumpSchema {
  stops: DeliveryRouteStopsDumpSchemaStopsItem[];
}

export interface DeliveryStopsUpdateStatusSchema {
  status: DeliveryRouteStopStatus;
  stops: string[];
}

export interface LoadRequestsDumpSchema {
  load_requests: LoadRequestHydratedDumpSchema[];
}

export type LoadRequestStatus = typeof LoadRequestStatus[keyof typeof LoadRequestStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoadRequestStatus = {
  picked_up: 'picked_up',
  dropped_off: 'dropped_off',
} as const;

export interface LoadRequestPatchSchema {
  action: LoadRequestStatus;
  load_request_ids: string[];
}

export interface DeliveryRoutesDumpSchema {
  routes: DeliveryRouteDumpSchema[];
}

export interface DeliveryRoutesUpdateStatusSchema {
  routes: string[];
  status: DeliveryRouteStatus;
}

export type DeliveryRouteCreateSchemaTruckId = string | null;

export type DeliveryRouteCreateSchemaDriverId = string | null;

export type DeliveryRouteOrderStopCreateSchemaType = typeof DeliveryRouteOrderStopCreateSchemaType[keyof typeof DeliveryRouteOrderStopCreateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteOrderStopCreateSchemaType = {
  customer_address: 'customer_address',
} as const;

export interface DeliveryRouteOrderStopCreateSchema {
  delivery_request_id: string;
  position: number;
  type: DeliveryRouteOrderStopCreateSchemaType;
}

export interface DeliveryRouteCreateSchema {
  delivery_date: string;
  driver_id?: DeliveryRouteCreateSchemaDriverId;
  location_id: string;
  name: string;
  stops: DeliveryRouteOrderStopCreateSchema[];
  truck_id?: DeliveryRouteCreateSchemaTruckId;
}

export type DeliveryRouteHydratedDumpSchemaTruck = DeliveryTruckDumpSchema | null;

export type DeliveryRouteHydratedDumpSchemaStopsItem = DeliveryRouteBranchStopDumpSchema | DeliveryRouteCustomerStopDumpSchema;

export type DeliveryRouteHydratedDumpSchemaDriver = DeliveryDriverDumpSchema | null;

export interface DeliveryRouteHydratedDumpSchema {
  count_customer_stops: number;
  delivery_date: string;
  driver?: DeliveryRouteHydratedDumpSchemaDriver;
  id: string;
  name: string;
  status: DeliveryRouteStatus;
  stops: DeliveryRouteHydratedDumpSchemaStopsItem[];
  truck?: DeliveryRouteHydratedDumpSchemaTruck;
}

export type LoadRequestHydratedDumpSchemaPickupCompletedAt = string | null;

export type LoadRequestHydratedDumpSchemaDropoffCompletedAt = string | null;

export type LoadRequestHydratedDumpSchemaCustomFormResponse = CustomFormResponseSlimDumpSchema | null;

export type LoadRequestHydratedDumpSchemaAsset = LinkIDSchema | null;

export interface LoadRequestHydratedDumpSchema {
  asset?: LoadRequestHydratedDumpSchemaAsset;
  custom_form_response?: LoadRequestHydratedDumpSchemaCustomFormResponse;
  delivery_request_id: string;
  dropoff_completed_at?: LoadRequestHydratedDumpSchemaDropoffCompletedAt;
  id: string;
  klass: LinkNameSchema;
  line_item_id: string;
  pickup_completed_at?: LoadRequestHydratedDumpSchemaPickupCompletedAt;
}

export type DeliveryRouteStopStatus = typeof DeliveryRouteStopStatus[keyof typeof DeliveryRouteStopStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteStopStatus = {
  scheduled: 'scheduled',
  arrived: 'arrived',
  completed: 'completed',
  cancelled: 'cancelled',
} as const;

export type DeliveryRouteCustomerStopDumpSchemaType = typeof DeliveryRouteCustomerStopDumpSchemaType[keyof typeof DeliveryRouteCustomerStopDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteCustomerStopDumpSchemaType = {
  customer_address: 'customer_address',
} as const;

export type DeliveryRouteCustomerStopDumpSchemaDepartedAt = string | null;

export type DeliveryRouteCustomerStopDumpSchemaCustomFormCustomerResponse = CustomFormResponseSlimDumpSchema | null;

export type DeliveryRouteCustomerStopDumpSchemaCustomFormBusinessResponses = CustomFormConfigurationResponse[] | null;

export type DeliveryRouteCustomerStopDumpSchemaArrivedAt = string | null;

export interface DeliveryRouteCustomerStopDumpSchema {
  arrived_at?: DeliveryRouteCustomerStopDumpSchemaArrivedAt;
  custom_form_business_responses?: DeliveryRouteCustomerStopDumpSchemaCustomFormBusinessResponses;
  custom_form_customer_response?: DeliveryRouteCustomerStopDumpSchemaCustomFormCustomerResponse;
  customer: LinkIDNameSchema;
  delivery_address: DeliveryAddressDumpSchema;
  delivery_address_id: string;
  departed_at?: DeliveryRouteCustomerStopDumpSchemaDepartedAt;
  dropoff_load_requests: LoadRequestHydratedDumpSchema[];
  id: string;
  order: LinkIDSchema;
  pickup_load_requests: LoadRequestHydratedDumpSchema[];
  position: number;
  status: DeliveryRouteStopStatus;
  type: DeliveryRouteCustomerStopDumpSchemaType;
}

export type DeliveryRouteBranchStopDumpSchemaType = typeof DeliveryRouteBranchStopDumpSchemaType[keyof typeof DeliveryRouteBranchStopDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteBranchStopDumpSchemaType = {
  location: 'location',
} as const;

export type DeliveryRouteBranchStopDumpSchemaDepartedAt = string | null;

export type DeliveryRouteBranchStopDumpSchemaCustomFormBusinessResponses = CustomFormConfigurationResponse[] | null;

export type DeliveryRouteBranchStopDumpSchemaArrivedAt = string | null;

export interface CustomFormResponseSlimDumpSchema {
  id: string;
  status: CustomFormResponseStatus;
}

export type DeliveryRouteBranchStopDumpSchemaCustomFormCustomerResponse = CustomFormResponseSlimDumpSchema | null;

export interface DeliveryRouteBranchStopDumpSchema {
  arrived_at?: DeliveryRouteBranchStopDumpSchemaArrivedAt;
  custom_form_business_responses?: DeliveryRouteBranchStopDumpSchemaCustomFormBusinessResponses;
  custom_form_customer_response?: DeliveryRouteBranchStopDumpSchemaCustomFormCustomerResponse;
  departed_at?: DeliveryRouteBranchStopDumpSchemaDepartedAt;
  dropoff_load_requests: LoadRequestHydratedDumpSchema[];
  id: string;
  location: LocationDumpSchema;
  location_id: string;
  pickup_load_requests: LoadRequestHydratedDumpSchema[];
  position: number;
  status: DeliveryRouteStopStatus;
  type: DeliveryRouteBranchStopDumpSchemaType;
}

export type CustomFormConfigurationResponseCustomFormResponse = CustomFormResponseSlimDumpSchema | null;

export interface CustomFormConfigurationResponse {
  action: CustomFormActionConfigurationType;
  custom_form_response?: CustomFormConfigurationResponseCustomFormResponse;
}

export type PaginatedDeliveryRouteSchemaNext = number | null;

export interface PaginatedDeliveryRouteSchema {
  elements: DeliveryRouteDumpSchema[];
  next?: PaginatedDeliveryRouteSchemaNext;
  total_count: number;
}

export type VisibilityType = typeof VisibilityType[keyof typeof VisibilityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VisibilityType = {
  active: 'active',
  hidden: 'hidden',
} as const;

export type TrackingType = typeof TrackingType[keyof typeof TrackingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrackingType = {
  serialized: 'serialized',
  bulk: 'bulk',
} as const;

export interface ShiftDumpSchema {
  id: string;
  max_hours_per_day: number;
  max_hours_per_month: number;
  max_hours_per_week: number;
  multiplier: string;
  name: string;
}

export type PrintVisibilityType = typeof PrintVisibilityType[keyof typeof PrintVisibilityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrintVisibilityType = {
  consolidated: 'consolidated',
  broken_out: 'broken_out',
} as const;

export type MeterOvertimeMethodology = typeof MeterOvertimeMethodology[keyof typeof MeterOvertimeMethodology];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeterOvertimeMethodology = {
  default: 'default',
  simple: 'simple',
  rer: 'rer',
} as const;

export interface RateProfileDumpSchema {
  clock_hours_per_day: number;
  clock_hours_per_month: number;
  clock_hours_per_week: number;
  clock_overtime_methodology: ClockOvertimeMethodology;
  clock_overtime_penalty: string;
  id: string;
  is_business_default: boolean;
  is_prorate_early_call_offs_enabled: boolean;
  is_prorate_extensions_enabled: boolean;
  meter_hours_per_day: number;
  meter_hours_per_month: number;
  meter_hours_per_week: number;
  meter_overtime_methodology: MeterOvertimeMethodology;
  name: string;
  shifts: ShiftDumpSchema[];
}

export type KlassDumpSchemaUsefulLife = number | null;

export type KlassDumpSchemaRateProfileId = string | null;

export type KlassDumpSchemaRateProfile = RateProfileDumpSchema | null;

export type KlassDumpSchemaProductTaxCodeGroupId = string | null;

export type KlassDumpSchemaMinimumRentalPeriodDelivery = number | null;

export type KlassDumpSchemaInventoryProfileId = string | null;

export type KlassDumpSchemaInternalDescription = string | null;

export type KlassDumpSchemaExternalDescription = string | null;

export type KlassDumpSchemaDepreciationSettingId = string | null;

export interface KlassDumpSchema {
  category: LinkIDNameSchema;
  category_id: string;
  count_assets: number;
  count_available_assets: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  depreciation_setting_id?: KlassDumpSchemaDepreciationSettingId;
  external_description?: KlassDumpSchemaExternalDescription;
  id: string;
  identifier: string;
  internal_description?: KlassDumpSchemaInternalDescription;
  inventory_profile_id?: KlassDumpSchemaInventoryProfileId;
  is_print_meter_gauge: boolean;
  minimum_rental_period_delivery?: KlassDumpSchemaMinimumRentalPeriodDelivery;
  name: string;
  print_visibility_type: PrintVisibilityType;
  product_tax_code_group_id?: KlassDumpSchemaProductTaxCodeGroupId;
  rate_profile?: KlassDumpSchemaRateProfile;
  rate_profile_id?: KlassDumpSchemaRateProfileId;
  tracking: TrackingType;
  type: KlassType;
  useful_life?: KlassDumpSchemaUsefulLife;
  visibility: VisibilityType;
}

export interface DeliveryTruckDumpSchema {
  asset: AssetDumpSchema;
  id: string;
}

export type DeliveryRouteStatus = typeof DeliveryRouteStatus[keyof typeof DeliveryRouteStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteStatus = {
  draft: 'draft',
  scheduled: 'scheduled',
  driver_notified: 'driver_notified',
  en_route: 'en_route',
  completed: 'completed',
  cancelled: 'cancelled',
} as const;

export type DeliveryRouteDumpSchemaTruck = DeliveryTruckDumpSchema | null;

export interface DeliveryRouteDumpSchema {
  count_customer_stops: number;
  delivery_date: string;
  driver?: DeliveryRouteDumpSchemaDriver;
  id: string;
  name: string;
  status: DeliveryRouteStatus;
  truck?: DeliveryRouteDumpSchemaTruck;
}

export interface DeliveryDriverDumpSchema {
  id: string;
  user: UserDumpSchema;
}

export type DeliveryRouteDumpSchemaDriver = DeliveryDriverDumpSchema | null;

export type ClockOvertimeMethodology = typeof ClockOvertimeMethodology[keyof typeof ClockOvertimeMethodology];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClockOvertimeMethodology = {
  default: 'default',
  simple: 'simple',
} as const;

export type BarcodeType = typeof BarcodeType[keyof typeof BarcodeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarcodeType = {
  code_39: 'code_39',
  code_128: 'code_128',
  qr_code: 'qr_code',
  data_matrix: 'data_matrix',
  pdf_417: 'pdf_417',
  aztec: 'aztec',
} as const;

export type AssetDumpSchemaTitleNumber = string | null;

export type AssetDumpSchemaTitleName = string | null;

export type AssetDumpSchemaTitleDate = string | null;

export type AssetDumpSchemaResidualValue = string | null;

export type AssetDumpSchemaRegistrationDate = string | null;

export type AssetDumpSchemaModelYear = number | null;

export type AssetDumpSchemaLicensePlateNumber = string | null;

export type AssetDumpSchemaEngineModel = string | null;

export type AssetDumpSchemaEngineMake = string | null;

export type AssetDumpSchemaEngineCapacity = string | null;

export type AssetDumpSchemaDepreciationStartDate = string | null;

export type AssetDumpSchemaDateRegistrationExpires = string | null;

export type AssetDumpSchemaBarcodeType = BarcodeType | null;

export type AssetDumpSchemaBarcodeCode = string | null;

export interface AssetDumpSchema {
  acquisition_date: string;
  acquisition_value: string;
  barcode_code?: AssetDumpSchemaBarcodeCode;
  barcode_type?: AssetDumpSchemaBarcodeType;
  count_assets: number;
  count_available_assets: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  date_registration_expires?: AssetDumpSchemaDateRegistrationExpires;
  depreciation_start_date?: AssetDumpSchemaDepreciationStartDate;
  engine_capacity?: AssetDumpSchemaEngineCapacity;
  engine_make?: AssetDumpSchemaEngineMake;
  engine_model?: AssetDumpSchemaEngineModel;
  id: string;
  identifier: string;
  klass: KlassDumpSchema;
  klass_id: string;
  license_plate_number?: AssetDumpSchemaLicensePlateNumber;
  location: LinkIDNameSchema;
  location_id: string;
  model_year?: AssetDumpSchemaModelYear;
  product: LinkNameSchema;
  product_id: string;
  registration_date?: AssetDumpSchemaRegistrationDate;
  residual_value?: AssetDumpSchemaResidualValue;
  title_date?: AssetDumpSchemaTitleDate;
  title_name?: AssetDumpSchemaTitleName;
  title_number?: AssetDumpSchemaTitleNumber;
  vendor: LinkNameSchema;
  vendor_id: string;
}

export type PaginatedDeliveryRequestSchemaNext = number | null;

export interface PaginatedDeliveryRequestSchema {
  elements: DeliveryRequestDumpSchema[];
  next?: PaginatedDeliveryRequestSchemaNext;
  total_count: number;
}

export type DeliveryRequestDumpSchemaCustomer = LinkIDNameSchema | null;

export type DeliveryRequestDirection = typeof DeliveryRequestDirection[keyof typeof DeliveryRequestDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRequestDirection = {
  dropoff: 'dropoff',
  pickup: 'pickup',
} as const;

export interface DeliveryRequestDumpSchema {
  customer?: DeliveryRequestDumpSchemaCustomer;
  delivery_address: DeliveryAddressDumpSchema;
  direction: DeliveryRequestDirection;
  id: string;
  location: LinkIDNameSchema;
  order: LinkIDSchema;
}

export interface DeliveryConfigurationCalculationResponseSchema {
  miles: string;
  minutes: number;
  price: string;
}

export interface DeliveryConfigurationCalculationRequestSchema {
  delivery_configuration_id: string;
  destination_address_id: string;
  location_id: string;
}

export interface DistanceResponseSchema {
  miles: string;
  minutes: number;
}

export interface DistanceRequestSchema {
  destination_address_id: string;
  location_id: string;
}

export type DeliveryConfigurationUpdateSchemaSecondaryRate = string | null;

export type DeliveryConfigurationUpdateSchemaMinimum = string | null;

export type DeliveryConfigurationUpdateSchemaDeliveryRevenueAccountId = string | null;

export type DeliveryConfigurationCreateSchemaSecondaryRate = string | null;

export type DeliveryConfigurationCreateSchemaMinimum = string | null;

export type DeliveryConfigurationCreateSchemaDeliveryRevenueAccountId = string | null;

export interface DeliveryConfigurationCreateSchema {
  delivery_revenue_account_id?: DeliveryConfigurationCreateSchemaDeliveryRevenueAccountId;
  is_business_default: boolean;
  minimum?: DeliveryConfigurationCreateSchemaMinimum;
  name: string;
  product_tax_code_group_id: string;
  rate: string;
  secondary_rate?: DeliveryConfigurationCreateSchemaSecondaryRate;
  split_delivery_across_first_and_last_invoice: boolean;
  type: DeliveryTypeEnum;
}

export type PaginatedDeliveryConfigurationsDumpSchemaNext = number | null;

export interface PaginatedDeliveryConfigurationsDumpSchema {
  elements: DeliveryConfigurationDumpSchema[];
  next?: PaginatedDeliveryConfigurationsDumpSchemaNext;
  total_count: number;
}

export type DeliveryTypeEnum = typeof DeliveryTypeEnum[keyof typeof DeliveryTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryTypeEnum = {
  flat: 'flat',
  per_mile: 'per_mile',
  per_mile_and_per_minute: 'per_mile_and_per_minute',
} as const;

export interface DeliveryConfigurationUpdateSchema {
  delivery_revenue_account_id?: DeliveryConfigurationUpdateSchemaDeliveryRevenueAccountId;
  is_business_default: boolean;
  minimum?: DeliveryConfigurationUpdateSchemaMinimum;
  name: string;
  product_tax_code_group_id: string;
  rate: string;
  secondary_rate?: DeliveryConfigurationUpdateSchemaSecondaryRate;
  split_delivery_across_first_and_last_invoice: boolean;
  type: DeliveryTypeEnum;
}

export type DeliveryConfigurationDumpSchemaSecondaryRate = string | null;

export type DeliveryConfigurationDumpSchemaMinimum = string | null;

export type DeliveryConfigurationDumpSchemaDeliveryRevenueAccount = LedgerAccountDumpSchema | null;

export interface DeliveryConfigurationDumpSchema {
  delivery_revenue_account?: DeliveryConfigurationDumpSchemaDeliveryRevenueAccount;
  id: string;
  is_business_default: boolean;
  minimum?: DeliveryConfigurationDumpSchemaMinimum;
  name: string;
  product_tax_code_group_id: string;
  rate: string;
  secondary_rate?: DeliveryConfigurationDumpSchemaSecondaryRate;
  split_delivery_across_first_and_last_invoice: boolean;
  type: DeliveryTypeEnum;
}

export interface CustomFormResponseUpdateSchema {
  questions: QuestionResponseUpdateSchema[];
  status: CustomFormResponseStatus;
}

export type QuestionResponseUpdateSchemaTextResponse = string | null;

export type QuestionResponseUpdateSchemaSignatureResponse = string | null;

export type QuestionResponseUpdateSchemaResponseChoices = string[] | null;

export type QuestionResponseUpdateSchemaNumberResponse = string | null;

export type QuestionResponseUpdateSchemaImageResponses = string[] | null;

export type QuestionResponseUpdateSchemaId = string | null;

export type QuestionResponseUpdateSchemaFileResponses = string[] | null;

export type QuestionResponseUpdateSchemaDateResponse = string | null;

export type QuestionResponseUpdateSchemaChildFormResponseId = string | null;

export type QuestionResponseUpdateSchemaBooleanResponse = boolean | null;

export interface QuestionResponseUpdateSchema {
  boolean_response?: QuestionResponseUpdateSchemaBooleanResponse;
  child_form_response_id?: QuestionResponseUpdateSchemaChildFormResponseId;
  completed?: boolean;
  date_response?: QuestionResponseUpdateSchemaDateResponse;
  file_responses?: QuestionResponseUpdateSchemaFileResponses;
  form_response_id: string;
  id?: QuestionResponseUpdateSchemaId;
  image_responses?: QuestionResponseUpdateSchemaImageResponses;
  number_response?: QuestionResponseUpdateSchemaNumberResponse;
  question_id: string;
  response_choices?: QuestionResponseUpdateSchemaResponseChoices;
  signature_response?: QuestionResponseUpdateSchemaSignatureResponse;
  text_response?: QuestionResponseUpdateSchemaTextResponse;
}

export type CustomFormActionConfigurationType = typeof CustomFormActionConfigurationType[keyof typeof CustomFormActionConfigurationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFormActionConfigurationType = {
  begin_dropoff: 'begin_dropoff',
  end_dropoff: 'end_dropoff',
  begin_pickup: 'begin_pickup',
  end_pickup: 'end_pickup',
} as const;

export interface CustomFormConfigurationCreateSchema {
  action: CustomFormActionConfigurationType;
  custom_form_id: string;
}

export interface CustomFormConfigurationsUpdateSchema {
  configurations: CustomFormConfigurationCreateSchema[];
}

export interface CustomFormConfigurationDumpSchema {
  action: CustomFormActionConfigurationType;
  custom_form: LinkNameSchema;
}

export interface CustomFormConfigurationsDumpSchema {
  configurations: CustomFormConfigurationDumpSchema[];
}

export interface CustomFormResponseDumpSchema {
  created_at: string;
  custom_form: CustomFormDumpSchema;
  custom_form_id: string;
  id: string;
  question_responses: QuestionResponseDumpSchema[];
  status: CustomFormResponseStatus;
}

export type QuestionResponseDumpSchemaTextResponse = string | null;

export type QuestionResponseDumpSchemaSignatureResponse = CustomerFileDumpSchema | null;

export type QuestionResponseDumpSchemaResponseChoices = AnswerChoiceDumpSchema[] | null;

export type QuestionResponseDumpSchemaNumberResponse = string | null;

export type QuestionResponseDumpSchemaImageResponses = CustomerFileDumpSchema[] | null;

export type QuestionResponseDumpSchemaFileResponses = CustomerFileDumpSchema[] | null;

export type QuestionResponseDumpSchemaDateResponse = string | null;

export type QuestionResponseDumpSchemaCompletedByUser = LinkNameSchema | null;

export type QuestionResponseDumpSchemaChildFormResponse = LinkNameSchema | null;

export type QuestionResponseDumpSchemaBooleanResponse = boolean | null;

export interface QuestionResponseDumpSchema {
  boolean_response?: QuestionResponseDumpSchemaBooleanResponse;
  child_form_response?: QuestionResponseDumpSchemaChildFormResponse;
  completed_by_user?: QuestionResponseDumpSchemaCompletedByUser;
  created_at: string;
  date_response?: QuestionResponseDumpSchemaDateResponse;
  file_responses?: QuestionResponseDumpSchemaFileResponses;
  form_response_id: string;
  id: string;
  image_responses?: QuestionResponseDumpSchemaImageResponses;
  number_response?: QuestionResponseDumpSchemaNumberResponse;
  question_id: string;
  response_choices?: QuestionResponseDumpSchemaResponseChoices;
  signature_response?: QuestionResponseDumpSchemaSignatureResponse;
  text_response?: QuestionResponseDumpSchemaTextResponse;
}

export interface CustomFormResponseCreateSchema {
  questions: QuestionResponseCreateSchema[];
  status: CustomFormResponseStatus;
}

export type QuestionResponseCreateSchemaTextResponse = string | null;

export type QuestionResponseCreateSchemaSignatureResponse = string | null;

export type QuestionResponseCreateSchemaResponseChoices = string[] | null;

export type QuestionResponseCreateSchemaNumberResponse = string | null;

export type QuestionResponseCreateSchemaImageResponses = string[] | null;

export type QuestionResponseCreateSchemaFileResponses = string[] | null;

export type QuestionResponseCreateSchemaDateResponse = string | null;

export type QuestionResponseCreateSchemaChildFormResponseId = string | null;

export type QuestionResponseCreateSchemaBooleanResponse = boolean | null;

export interface QuestionResponseCreateSchema {
  boolean_response?: QuestionResponseCreateSchemaBooleanResponse;
  child_form_response_id?: QuestionResponseCreateSchemaChildFormResponseId;
  completed?: boolean;
  date_response?: QuestionResponseCreateSchemaDateResponse;
  file_responses?: QuestionResponseCreateSchemaFileResponses;
  form_response_id: string;
  image_responses?: QuestionResponseCreateSchemaImageResponses;
  number_response?: QuestionResponseCreateSchemaNumberResponse;
  question_id: string;
  response_choices?: QuestionResponseCreateSchemaResponseChoices;
  signature_response?: QuestionResponseCreateSchemaSignatureResponse;
  text_response?: QuestionResponseCreateSchemaTextResponse;
}

export type CustomFormResponseStatus = typeof CustomFormResponseStatus[keyof typeof CustomFormResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFormResponseStatus = {
  created: 'created',
  started: 'started',
  submitted: 'submitted',
  locked: 'locked',
} as const;

export type QuestionUpdateSchemaId = string | null;

export type QuestionUpdateSchemaDescription = string | null;

export type QuestionUpdateSchemaChildFormId = string | null;

export type QuestionUpdateSchemaAnswerChoices = AnswerChoiceUpdateSchema[] | null;

export interface QuestionUpdateSchema {
  answer_choices?: QuestionUpdateSchemaAnswerChoices;
  child_form_id?: QuestionUpdateSchemaChildFormId;
  description?: QuestionUpdateSchemaDescription;
  id?: QuestionUpdateSchemaId;
  is_editable: boolean;
  is_required: boolean;
  label: string;
  position: number;
  type: QuestionType;
}

export interface CustomFormUpdateSchema {
  label: string;
  questions: QuestionUpdateSchema[];
  type: CustomFormType;
}

export type AnswerChoiceUpdateSchemaId = string | null;

export type AnswerChoiceUpdateSchemaDescription = string | null;

export interface AnswerChoiceUpdateSchema {
  description?: AnswerChoiceUpdateSchemaDescription;
  id?: AnswerChoiceUpdateSchemaId;
  label: string;
}

export interface CustomFormDumpSchema {
  created_at: string;
  id: string;
  label: string;
  questions: QuestionDumpSchema[];
  type: CustomFormType;
}

export type QuestionDumpSchemaDescription = string | null;

export type QuestionDumpSchemaChildForm = LinkNameSchema | null;

export type AnswerChoiceDumpSchemaDescription = string | null;

export interface AnswerChoiceDumpSchema {
  description?: AnswerChoiceDumpSchemaDescription;
  id: string;
  label: string;
}

export type QuestionDumpSchemaAnswerChoices = AnswerChoiceDumpSchema[] | null;

export interface CustomFormCreateSchema {
  label: string;
  questions: QuestionCreateSchema[];
  type: CustomFormType;
}

export type QuestionType = typeof QuestionType[keyof typeof QuestionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionType = {
  short_text: 'short_text',
  long_text: 'long_text',
  number: 'number',
  checkbox: 'checkbox',
  file_upload: 'file_upload',
  image_upload: 'image_upload',
  signature: 'signature',
  single_select: 'single_select',
  multi_select: 'multi_select',
  date_select: 'date_select',
  child_form: 'child_form',
} as const;

export interface QuestionDumpSchema {
  answer_choices?: QuestionDumpSchemaAnswerChoices;
  child_form?: QuestionDumpSchemaChildForm;
  description?: QuestionDumpSchemaDescription;
  id: string;
  is_editable: boolean;
  is_required: boolean;
  label: string;
  position: number;
  type: QuestionType;
}

export type QuestionCreateSchemaDescription = string | null;

export type QuestionCreateSchemaChildFormId = string | null;

export type AnswerChoiceCreateSchemaDescription = string | null;

export interface AnswerChoiceCreateSchema {
  description?: AnswerChoiceCreateSchemaDescription;
  label: string;
}

export type QuestionCreateSchemaAnswerChoices = AnswerChoiceCreateSchema[] | null;

export interface QuestionCreateSchema {
  answer_choices?: QuestionCreateSchemaAnswerChoices;
  child_form_id?: QuestionCreateSchemaChildFormId;
  description?: QuestionCreateSchemaDescription;
  is_editable: boolean;
  is_required: boolean;
  label: string;
  position: number;
  type: QuestionType;
}

export type PaginatedCustomFormSchemaNext = number | null;

export type CustomFormType = typeof CustomFormType[keyof typeof CustomFormType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFormType = {
  regular: 'regular',
  checklist: 'checklist',
} as const;

export interface CustomFormBaseDumpSchema {
  created_at: string;
  id: string;
  label: string;
  type: CustomFormType;
}

export interface PaginatedCustomFormSchema {
  elements: CustomFormBaseDumpSchema[];
  next?: PaginatedCustomFormSchemaNext;
  total_count: number;
}

export type PaginatedDeliveryAddressesSchemaNext = number | null;

export interface PaginatedDeliveryAddressesSchema {
  elements: DeliveryAddressDumpSchema[];
  next?: PaginatedDeliveryAddressesSchemaNext;
  total_count: number;
}

export type CustomerUpdateSchemaStatementDate = number | null;

export type CustomerUpdateSchemaRemoteObjectId = string | null;

export type CustomerUpdateSchemaParentCustomerId = string | null;

export type CustomerUpdateSchemaLeadSource = string | null;

export type CustomerUpdateSchemaDisplayName = string | null;

export type CustomerUpdateSchemaDefaultSalesRepId = string | null;

export type CustomerUpdateSchemaCustomFormId = string | null;

export type CustomerUpdateSchemaCreditLimit = string | null;

export type CustomerUpdateSchemaCoiPolicyholderName = string | null;

export type CustomerUpdateSchemaCoiPolicyNumber = string | null;

export type CustomerUpdateSchemaCoiIssuedDate = string | null;

export type CustomerUpdateSchemaCoiIssuedBy = string | null;

export type CustomerUpdateSchemaCoiExpirationDate = string | null;

export type CustomerUpdateSchemaCoiAmount = string | null;

export type CustomerUpdateSchemaBillingProfileId = string | null;

export type CustomerUpdateSchemaBillingPocName = string | null;

export type CustomerUpdateSchemaBillingPhone = string | null;

export type CustomerUpdateSchemaBillingEmail = string | null;

export type CustomerUpdateSchemaBillingAddress = AddressCreateSchema | null;

export type DeliveryAddressUpdateSchemaPointOfContactPhone = string | null;

export type DeliveryAddressUpdateSchemaPointOfContactEmail = string | null;

export type DeliveryAddressUpdateSchemaNotes = string | null;

export type DeliveryAddressUpdateSchemaId = string | null;

export interface DeliveryAddressUpdateSchema {
  address: AddressCreateSchema;
  id?: DeliveryAddressUpdateSchemaId;
  notes?: DeliveryAddressUpdateSchemaNotes;
  point_of_contact_email?: DeliveryAddressUpdateSchemaPointOfContactEmail;
  point_of_contact_name: string;
  point_of_contact_phone?: DeliveryAddressUpdateSchemaPointOfContactPhone;
}

export type CustomerContactUpdateSchemaPictureFrontId = string | null;

export type CustomerContactUpdateSchemaPictureBackId = string | null;

export type CustomerContactUpdateSchemaPhone = string | null;

export type CustomerContactUpdateSchemaLicenseNumber = string | null;

export type CustomerContactUpdateSchemaId = string | null;

export type CustomerContactUpdateSchemaEmail = string | null;

export type CustomerContactUpdateSchemaDateOfBirth = string | null;

export type CustomerContactUpdateSchemaAddress = AddressCreateSchema | null;

export interface CustomerContactUpdateSchema {
  address?: CustomerContactUpdateSchemaAddress;
  date_of_birth?: CustomerContactUpdateSchemaDateOfBirth;
  email?: CustomerContactUpdateSchemaEmail;
  first_name: string;
  id?: CustomerContactUpdateSchemaId;
  last_name: string;
  license_number?: CustomerContactUpdateSchemaLicenseNumber;
  phone?: CustomerContactUpdateSchemaPhone;
  picture_back_id?: CustomerContactUpdateSchemaPictureBackId;
  picture_front_id?: CustomerContactUpdateSchemaPictureFrontId;
}

export type CustomerCreateSchemaStatementDate = number | null;

export type CustomerCreateSchemaRemoteObjectId = string | null;

export type CustomerCreateSchemaParentCustomerId = string | null;

export type CustomerCreateSchemaLeadSource = string | null;

export type CustomerCreateSchemaIdentifier = string | null;

export type CustomerCreateSchemaDisplayName = string | null;

export type CustomerCreateSchemaDefaultSalesRepId = string | null;

export type CustomerCreateSchemaCustomFormId = string | null;

export type CustomerCreateSchemaCreditLimit = string | null;

export type CustomerCreateSchemaCoiPolicyholderName = string | null;

export type CustomerCreateSchemaCoiPolicyNumber = string | null;

export type CustomerCreateSchemaCoiIssuedDate = string | null;

export type CustomerCreateSchemaCoiIssuedBy = string | null;

export type CustomerCreateSchemaCoiExpirationDate = string | null;

export type CustomerCreateSchemaCoiAmount = string | null;

export type CustomerCreateSchemaBillingProfileId = string | null;

export type CustomerCreateSchemaBillingPocName = string | null;

export type CustomerCreateSchemaBillingPhone = string | null;

export type CustomerCreateSchemaBillingEmail = string | null;

export type CustomerCreateSchemaBillingAddress = AddressCreateSchema | null;

export interface CustomerCreateSchema {
  automatic_statements: boolean;
  billing_address?: CustomerCreateSchemaBillingAddress;
  billing_email?: CustomerCreateSchemaBillingEmail;
  billing_phone?: CustomerCreateSchemaBillingPhone;
  billing_poc_name?: CustomerCreateSchemaBillingPocName;
  billing_profile_id?: CustomerCreateSchemaBillingProfileId;
  coi_amount?: CustomerCreateSchemaCoiAmount;
  coi_expiration_date?: CustomerCreateSchemaCoiExpirationDate;
  coi_issued_by?: CustomerCreateSchemaCoiIssuedBy;
  coi_issued_date?: CustomerCreateSchemaCoiIssuedDate;
  coi_policy_number?: CustomerCreateSchemaCoiPolicyNumber;
  coi_policyholder_name?: CustomerCreateSchemaCoiPolicyholderName;
  contacts: CustomerContactCreateSchema[];
  credit_limit?: CustomerCreateSchemaCreditLimit;
  custom_form_id?: CustomerCreateSchemaCustomFormId;
  default_sales_rep_id?: CustomerCreateSchemaDefaultSalesRepId;
  delivery_addresses: DeliveryAddressCreateSchema[];
  display_name?: CustomerCreateSchemaDisplayName;
  has_coi: boolean;
  identifier?: CustomerCreateSchemaIdentifier;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  lead_source?: CustomerCreateSchemaLeadSource;
  modifiers: ExpenseClassAssociationCreateSchema[];
  name: string;
  parent_customer_id?: CustomerCreateSchemaParentCustomerId;
  pricings: KlassPricingCreateSchema[];
  remote_object_id?: CustomerCreateSchemaRemoteObjectId;
  statement_date?: CustomerCreateSchemaStatementDate;
  tags: string[];
  type: CustomerTypeEnum;
  uploads: string[];
}

export type KlassPricingCreateSchemaLocationId = string | null;

export type KlassPricingCreateSchemaLineItemId = string | null;

export type KlassPricingCreateSchemaKlassId = string | null;

export type KlassPricingCreateSchemaCustomerId = string | null;

export interface KlassPricingCreateSchema {
  amount: string;
  customer_id?: KlassPricingCreateSchemaCustomerId;
  frequency: KlassPricingFrequency;
  klass_id?: KlassPricingCreateSchemaKlassId;
  line_item_id?: KlassPricingCreateSchemaLineItemId;
  location_id?: KlassPricingCreateSchemaLocationId;
  type: KlassPricingType;
}

export interface CustomerUpdateSchema {
  automatic_statements: boolean;
  billing_address?: CustomerUpdateSchemaBillingAddress;
  billing_email?: CustomerUpdateSchemaBillingEmail;
  billing_phone?: CustomerUpdateSchemaBillingPhone;
  billing_poc_name?: CustomerUpdateSchemaBillingPocName;
  billing_profile_id?: CustomerUpdateSchemaBillingProfileId;
  coi_amount?: CustomerUpdateSchemaCoiAmount;
  coi_expiration_date?: CustomerUpdateSchemaCoiExpirationDate;
  coi_issued_by?: CustomerUpdateSchemaCoiIssuedBy;
  coi_issued_date?: CustomerUpdateSchemaCoiIssuedDate;
  coi_policy_number?: CustomerUpdateSchemaCoiPolicyNumber;
  coi_policyholder_name?: CustomerUpdateSchemaCoiPolicyholderName;
  contacts: CustomerContactUpdateSchema[];
  credit_limit?: CustomerUpdateSchemaCreditLimit;
  custom_form_id?: CustomerUpdateSchemaCustomFormId;
  default_sales_rep_id?: CustomerUpdateSchemaDefaultSalesRepId;
  delivery_addresses: DeliveryAddressUpdateSchema[];
  display_name?: CustomerUpdateSchemaDisplayName;
  has_coi: boolean;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  lead_source?: CustomerUpdateSchemaLeadSource;
  modifiers: ExpenseClassAssociationCreateSchema[];
  name: string;
  parent_customer_id?: CustomerUpdateSchemaParentCustomerId;
  pricings: KlassPricingCreateSchema[];
  remote_object_id?: CustomerUpdateSchemaRemoteObjectId;
  statement_date?: CustomerUpdateSchemaStatementDate;
  tags: string[];
  type: CustomerTypeEnum;
  uploads: string[];
}

export type ExpenseRuleType = typeof ExpenseRuleType[keyof typeof ExpenseRuleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseRuleType = {
  require: 'require',
  optional: 'optional',
  exclude: 'exclude',
} as const;

export type DeliveryAddressCreateSchemaPointOfContactPhone = string | null;

export type DeliveryAddressCreateSchemaPointOfContactEmail = string | null;

export type DeliveryAddressCreateSchemaNotes = string | null;

export interface DeliveryAddressCreateSchema {
  address: AddressCreateSchema;
  notes?: DeliveryAddressCreateSchemaNotes;
  point_of_contact_email?: DeliveryAddressCreateSchemaPointOfContactEmail;
  point_of_contact_name: string;
  point_of_contact_phone?: DeliveryAddressCreateSchemaPointOfContactPhone;
}

export type CustomerContactCreateSchemaPictureFrontId = string | null;

export type CustomerContactCreateSchemaPictureBackId = string | null;

export type CustomerContactCreateSchemaPhone = string | null;

export type CustomerContactCreateSchemaLicenseNumber = string | null;

export type CustomerContactCreateSchemaEmail = string | null;

export type CustomerContactCreateSchemaDateOfBirth = string | null;

export type CustomerContactCreateSchemaAddress = AddressCreateSchema | null;

export interface CustomerContactCreateSchema {
  address?: CustomerContactCreateSchemaAddress;
  date_of_birth?: CustomerContactCreateSchemaDateOfBirth;
  email?: CustomerContactCreateSchemaEmail;
  first_name: string;
  last_name: string;
  license_number?: CustomerContactCreateSchemaLicenseNumber;
  phone?: CustomerContactCreateSchemaPhone;
  picture_back_id?: CustomerContactCreateSchemaPictureBackId;
  picture_front_id?: CustomerContactCreateSchemaPictureFrontId;
}

export interface BaseExpenseRuleSchema {
  is_editable: boolean;
  percentage: string;
  type: ExpenseRuleType;
}

export interface ExpenseClassAssociationCreateSchema {
  expense_class_id: string;
  expense_rule: BaseExpenseRuleSchema;
}

export type CustomerHydratedDumpSchemaStatementDate = number | null;

export type CustomerHydratedDumpSchemaRemoteObjectId = string | null;

export type CustomerHydratedDumpSchemaRemoteObject = RemoteObjectDumpSchema | null;

export type CustomerHydratedDumpSchemaParentCustomerId = string | null;

export type CustomerHydratedDumpSchemaParentCustomer = LinkIDNameSchema | null;

export type CustomerHydratedDumpSchemaLeadSource = string | null;

export type CustomerHydratedDumpSchemaDefaultSalesRepId = string | null;

export type CustomerHydratedDumpSchemaDefaultSalesRep = UserDumpSchema | null;

export type CustomerHydratedDumpSchemaCustomForm = LinkNameSchema | null;

export type CustomerHydratedDumpSchemaCreditLimit = string | null;

export type CustomerHydratedDumpSchemaCoiPolicyholderName = string | null;

export type CustomerHydratedDumpSchemaCoiPolicyNumber = string | null;

export type CustomerHydratedDumpSchemaCoiIssuedDate = string | null;

export type CustomerHydratedDumpSchemaCoiIssuedBy = string | null;

export type CustomerHydratedDumpSchemaCoiExpirationDate = string | null;

export type CustomerHydratedDumpSchemaCoiAmount = string | null;

export type CustomerHydratedDumpSchemaBillingProfileId = string | null;

export type CustomerHydratedDumpSchemaBillingProfile = BillingProfileDumpSchema | null;

export type CustomerHydratedDumpSchemaBillingPocName = string | null;

export type CustomerHydratedDumpSchemaBillingPhone = string | null;

export type CustomerHydratedDumpSchemaBillingEmail = string | null;

export type CustomerHydratedDumpSchemaBillingAddress = AddressDumpSchema | null;

export interface CustomerHydratedDumpSchema {
  activity_log_id: string;
  automatic_statements: boolean;
  balance: string;
  billing_address?: CustomerHydratedDumpSchemaBillingAddress;
  billing_email?: CustomerHydratedDumpSchemaBillingEmail;
  billing_phone?: CustomerHydratedDumpSchemaBillingPhone;
  billing_poc_name?: CustomerHydratedDumpSchemaBillingPocName;
  billing_profile?: CustomerHydratedDumpSchemaBillingProfile;
  billing_profile_id?: CustomerHydratedDumpSchemaBillingProfileId;
  coi_amount?: CustomerHydratedDumpSchemaCoiAmount;
  coi_expiration_date?: CustomerHydratedDumpSchemaCoiExpirationDate;
  coi_issued_by?: CustomerHydratedDumpSchemaCoiIssuedBy;
  coi_issued_date?: CustomerHydratedDumpSchemaCoiIssuedDate;
  coi_policy_number?: CustomerHydratedDumpSchemaCoiPolicyNumber;
  coi_policyholder_name?: CustomerHydratedDumpSchemaCoiPolicyholderName;
  contacts: CustomerContactHydratedDumpSchema[];
  credit_limit?: CustomerHydratedDumpSchemaCreditLimit;
  custom_form?: CustomerHydratedDumpSchemaCustomForm;
  default_sales_rep?: CustomerHydratedDumpSchemaDefaultSalesRep;
  default_sales_rep_id?: CustomerHydratedDumpSchemaDefaultSalesRepId;
  delivery_addresses: DeliveryAddressDumpSchema[];
  has_coi: boolean;
  id: string;
  identifier: string;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  lead_source?: CustomerHydratedDumpSchemaLeadSource;
  name: string;
  original_name: string;
  parent_customer?: CustomerHydratedDumpSchemaParentCustomer;
  parent_customer_id?: CustomerHydratedDumpSchemaParentCustomerId;
  payment_methods: PaymentMethodDumpSchema[];
  pricings: KlassPricingHydratedDumpSchema[];
  remote_object?: CustomerHydratedDumpSchemaRemoteObject;
  remote_object_id?: CustomerHydratedDumpSchemaRemoteObjectId;
  statement_date?: CustomerHydratedDumpSchemaStatementDate;
  tags: string[];
  type: CustomerTypeEnum;
  uploads: CustomerFileDumpSchema[];
}

export type PaymentType = typeof PaymentType[keyof typeof PaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentType = {
  card: 'card',
  us_bank_account: 'us_bank_account',
} as const;

export type PaymentTerms = typeof PaymentTerms[keyof typeof PaymentTerms];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentTerms = {
  zero: 'zero',
  net_30: 'net_30',
  net_60: 'net_60',
  net_90: 'net_90',
  net_120: 'net_120',
} as const;

export type PaymentMethodProvider = typeof PaymentMethodProvider[keyof typeof PaymentMethodProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodProvider = {
  stripe: 'stripe',
} as const;

export type PaymentMethodDumpSchemaDetail = CardPaymentMethodDetailSchema | ACHPaymentMethodDetailSchema;

export interface PaymentMethodDumpSchema {
  detail: PaymentMethodDumpSchemaDetail;
  id: string;
  provider: PaymentMethodProvider;
  remote_id: string;
  type: PaymentType;
}

export type PaymentMethodCardDisplayBrand = typeof PaymentMethodCardDisplayBrand[keyof typeof PaymentMethodCardDisplayBrand];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodCardDisplayBrand = {
  american_express: 'american_express',
  cartes_bancaires: 'cartes_bancaires',
  diners_club: 'diners_club',
  discover: 'discover',
  eftpos_australia: 'eftpos_australia',
  interac: 'interac',
  jcb: 'jcb',
  mastercard: 'mastercard',
  union_pay: 'union_pay',
  visa: 'visa',
  other: 'other',
} as const;

export type PaymentMethodBankAccountType = typeof PaymentMethodBankAccountType[keyof typeof PaymentMethodBankAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodBankAccountType = {
  checking: 'checking',
  savings: 'savings',
} as const;

export type PaymentMethodBankAccountHolderType = typeof PaymentMethodBankAccountHolderType[keyof typeof PaymentMethodBankAccountHolderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodBankAccountHolderType = {
  individual: 'individual',
  company: 'company',
} as const;

export type PaymentDue = typeof PaymentDue[keyof typeof PaymentDue];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentDue = {
  in_arrears: 'in_arrears',
  align_with_rates: 'align_with_rates',
} as const;

export type KlassPricingType = typeof KlassPricingType[keyof typeof KlassPricingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KlassPricingType = {
  fixed: 'fixed',
  markup: 'markup',
} as const;

export type KlassPricingHydratedDumpSchemaLocationId = string | null;

export type KlassPricingHydratedDumpSchemaLocation = LinkIDNameSchema | null;

export type KlassPricingHydratedDumpSchemaLineItemId = string | null;

export type KlassPricingHydratedDumpSchemaKlassId = string | null;

export type KlassPricingHydratedDumpSchemaKlass = LinkIDNameSchema | null;

export type KlassPricingHydratedDumpSchemaCustomerId = string | null;

export type KlassPricingFrequency = typeof KlassPricingFrequency[keyof typeof KlassPricingFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KlassPricingFrequency = {
  sale: 'sale',
  minimum: 'minimum',
  hourly: 'hourly',
  two_hourly: 'two_hourly',
  four_hourly: 'four_hourly',
  daily: 'daily',
  two_daily: 'two_daily',
  weekly: 'weekly',
  twenty_eight_days: 'twenty_eight_days',
} as const;

export interface KlassPricingHydratedDumpSchema {
  amount: string;
  customer_id?: KlassPricingHydratedDumpSchemaCustomerId;
  frequency: KlassPricingFrequency;
  id: string;
  klass?: KlassPricingHydratedDumpSchemaKlass;
  klass_id?: KlassPricingHydratedDumpSchemaKlassId;
  line_item_id?: KlassPricingHydratedDumpSchemaLineItemId;
  location?: KlassPricingHydratedDumpSchemaLocation;
  location_id?: KlassPricingHydratedDumpSchemaLocationId;
  type: KlassPricingType;
}

export type DeliveryAddressDumpSchemaPointOfContactPhone = string | null;

export type DeliveryAddressDumpSchemaPointOfContactEmail = string | null;

export type DeliveryAddressDumpSchemaNotes = string | null;

export interface DeliveryAddressDumpSchema {
  address: AddressDumpSchema;
  id: string;
  notes?: DeliveryAddressDumpSchemaNotes;
  point_of_contact_email?: DeliveryAddressDumpSchemaPointOfContactEmail;
  point_of_contact_name: string;
  point_of_contact_phone?: DeliveryAddressDumpSchemaPointOfContactPhone;
}

export type CustomerContactHydratedDumpSchemaPictureFront = CustomerFileDumpSchema | null;

export type CustomerContactHydratedDumpSchemaPictureBack = CustomerFileDumpSchema | null;

export type CustomerContactHydratedDumpSchemaPhone = string | null;

export type CustomerContactHydratedDumpSchemaLicenseNumber = string | null;

export type CustomerContactHydratedDumpSchemaEmail = string | null;

export type CustomerContactHydratedDumpSchemaDateOfBirth = string | null;

export type CustomerContactHydratedDumpSchemaAddress = AddressDumpSchema | null;

export interface CustomerContactHydratedDumpSchema {
  address?: CustomerContactHydratedDumpSchemaAddress;
  date_of_birth?: CustomerContactHydratedDumpSchemaDateOfBirth;
  email?: CustomerContactHydratedDumpSchemaEmail;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  license_number?: CustomerContactHydratedDumpSchemaLicenseNumber;
  phone?: CustomerContactHydratedDumpSchemaPhone;
  picture_back?: CustomerContactHydratedDumpSchemaPictureBack;
  picture_front?: CustomerContactHydratedDumpSchemaPictureFront;
}

export type CardPaymentMethodDetailSchemaType = typeof CardPaymentMethodDetailSchemaType[keyof typeof CardPaymentMethodDetailSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardPaymentMethodDetailSchemaType = {
  card: 'card',
} as const;

export interface CardPaymentMethodDetailSchema {
  card_brand: PaymentMethodCardDisplayBrand;
  country: string;
  exp_month: number;
  exp_year: number;
  last_four: string;
  type: CardPaymentMethodDetailSchemaType;
}

export type BillingProfileType = typeof BillingProfileType[keyof typeof BillingProfileType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingProfileType = {
  rental: 'rental',
  sale: 'sale',
} as const;

export type BillingProfileDumpSchemaSpecificDay = number | null;

export type BillingCycle = typeof BillingCycle[keyof typeof BillingCycle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingCycle = {
  entire: 'entire',
  specific_day_of_month: 'specific_day_of_month',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
} as const;

export interface BillingProfileDumpSchema {
  cycle: BillingCycle;
  due: PaymentDue;
  id: string;
  name: string;
  specific_day?: BillingProfileDumpSchemaSpecificDay;
  terms: PaymentTerms;
  type: BillingProfileType;
}

export type ACHPaymentMethodDetailSchemaType = typeof ACHPaymentMethodDetailSchemaType[keyof typeof ACHPaymentMethodDetailSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHPaymentMethodDetailSchemaType = {
  us_bank_account: 'us_bank_account',
} as const;

export type ACHPaymentMethodDetailSchemaBlockedReason = string | null;

export type ACHPaymentMethodDetailSchemaBlockedNetworkCode = string | null;

export interface ACHPaymentMethodDetailSchema {
  account_holder_type: PaymentMethodBankAccountHolderType;
  account_type: PaymentMethodBankAccountType;
  bank_name: string;
  blocked_network_code?: ACHPaymentMethodDetailSchemaBlockedNetworkCode;
  blocked_reason?: ACHPaymentMethodDetailSchemaBlockedReason;
  last_four: string;
  routing_number: string;
  type: ACHPaymentMethodDetailSchemaType;
}

export type PaginatedCustomerWithContactSchemaNext = number | null;

export interface PaginatedCustomerWithContactSchema {
  elements: CustomerWithContactSchema[];
  next?: PaginatedCustomerWithContactSchemaNext;
  total_count: number;
}

export type CustomerWithContactSchemaStatementDate = number | null;

export type CustomerWithContactSchemaRemoteObjectId = string | null;

export type CustomerWithContactSchemaParentCustomerId = string | null;

export type CustomerWithContactSchemaParentCustomer = LinkIDNameSchema | null;

export type CustomerWithContactSchemaMatchedContact = CustomerContactBaseDumpSchema | null;

export type CustomerWithContactSchemaLeadSource = string | null;

export type CustomerWithContactSchemaDefaultSalesRepId = string | null;

export type CustomerWithContactSchemaCreditLimit = string | null;

export type CustomerWithContactSchemaCoiPolicyholderName = string | null;

export type CustomerWithContactSchemaCoiPolicyNumber = string | null;

export type CustomerWithContactSchemaCoiIssuedDate = string | null;

export type CustomerWithContactSchemaCoiIssuedBy = string | null;

export type CustomerWithContactSchemaCoiExpirationDate = string | null;

export type CustomerWithContactSchemaCoiAmount = string | null;

export type CustomerWithContactSchemaBillingProfileId = string | null;

export type CustomerWithContactSchemaBillingPocName = string | null;

export type CustomerWithContactSchemaBillingPhone = string | null;

export type CustomerWithContactSchemaBillingEmail = string | null;

export interface CustomerWithContactSchema {
  activity_log_id: string;
  automatic_statements: boolean;
  billing_email?: CustomerWithContactSchemaBillingEmail;
  billing_phone?: CustomerWithContactSchemaBillingPhone;
  billing_poc_name?: CustomerWithContactSchemaBillingPocName;
  billing_profile_id?: CustomerWithContactSchemaBillingProfileId;
  coi_amount?: CustomerWithContactSchemaCoiAmount;
  coi_expiration_date?: CustomerWithContactSchemaCoiExpirationDate;
  coi_issued_by?: CustomerWithContactSchemaCoiIssuedBy;
  coi_issued_date?: CustomerWithContactSchemaCoiIssuedDate;
  coi_policy_number?: CustomerWithContactSchemaCoiPolicyNumber;
  coi_policyholder_name?: CustomerWithContactSchemaCoiPolicyholderName;
  credit_limit?: CustomerWithContactSchemaCreditLimit;
  default_sales_rep_id?: CustomerWithContactSchemaDefaultSalesRepId;
  has_coi: boolean;
  id: string;
  identifier: string;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  lead_source?: CustomerWithContactSchemaLeadSource;
  matched_contact?: CustomerWithContactSchemaMatchedContact;
  name: string;
  parent_customer?: CustomerWithContactSchemaParentCustomer;
  parent_customer_id?: CustomerWithContactSchemaParentCustomerId;
  remote_object_id?: CustomerWithContactSchemaRemoteObjectId;
  statement_date?: CustomerWithContactSchemaStatementDate;
  type: CustomerTypeEnum;
}

export type CustomerContactBaseDumpSchemaPhone = string | null;

export type CustomerContactBaseDumpSchemaLicenseNumber = string | null;

export type CustomerContactBaseDumpSchemaEmail = string | null;

export type CustomerContactBaseDumpSchemaDateOfBirth = string | null;

export interface CustomerContactBaseDumpSchema {
  date_of_birth?: CustomerContactBaseDumpSchemaDateOfBirth;
  email?: CustomerContactBaseDumpSchemaEmail;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  license_number?: CustomerContactBaseDumpSchemaLicenseNumber;
  phone?: CustomerContactBaseDumpSchemaPhone;
}

export type PaginatedCustomerSchemaNext = number | null;

export interface PaginatedCustomerSchema {
  elements: CustomerDumpSchema[];
  next?: PaginatedCustomerSchemaNext;
  total_count: number;
}

export type CustomerTypeEnum = typeof CustomerTypeEnum[keyof typeof CustomerTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerTypeEnum = {
  business: 'business',
  individual: 'individual',
} as const;

export type CustomerDumpSchemaStatementDate = number | null;

export type CustomerDumpSchemaRemoteObjectId = string | null;

export type CustomerDumpSchemaParentCustomerId = string | null;

export type CustomerDumpSchemaParentCustomer = LinkIDNameSchema | null;

export type CustomerDumpSchemaLeadSource = string | null;

export type CustomerDumpSchemaDefaultSalesRepId = string | null;

export type CustomerDumpSchemaCreditLimit = string | null;

export type CustomerDumpSchemaCoiPolicyholderName = string | null;

export type CustomerDumpSchemaCoiPolicyNumber = string | null;

export type CustomerDumpSchemaCoiIssuedDate = string | null;

export type CustomerDumpSchemaCoiIssuedBy = string | null;

export type CustomerDumpSchemaCoiExpirationDate = string | null;

export type CustomerDumpSchemaCoiAmount = string | null;

export type CustomerDumpSchemaBillingProfileId = string | null;

export type CustomerDumpSchemaBillingPocName = string | null;

export type CustomerDumpSchemaBillingPhone = string | null;

export type CustomerDumpSchemaBillingEmail = string | null;

export interface CustomerDumpSchema {
  activity_log_id: string;
  automatic_statements: boolean;
  billing_email?: CustomerDumpSchemaBillingEmail;
  billing_phone?: CustomerDumpSchemaBillingPhone;
  billing_poc_name?: CustomerDumpSchemaBillingPocName;
  billing_profile_id?: CustomerDumpSchemaBillingProfileId;
  coi_amount?: CustomerDumpSchemaCoiAmount;
  coi_expiration_date?: CustomerDumpSchemaCoiExpirationDate;
  coi_issued_by?: CustomerDumpSchemaCoiIssuedBy;
  coi_issued_date?: CustomerDumpSchemaCoiIssuedDate;
  coi_policy_number?: CustomerDumpSchemaCoiPolicyNumber;
  coi_policyholder_name?: CustomerDumpSchemaCoiPolicyholderName;
  credit_limit?: CustomerDumpSchemaCreditLimit;
  default_sales_rep_id?: CustomerDumpSchemaDefaultSalesRepId;
  has_coi: boolean;
  id: string;
  identifier: string;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  lead_source?: CustomerDumpSchemaLeadSource;
  name: string;
  parent_customer?: CustomerDumpSchemaParentCustomer;
  parent_customer_id?: CustomerDumpSchemaParentCustomerId;
  remote_object_id?: CustomerDumpSchemaRemoteObjectId;
  statement_date?: CustomerDumpSchemaStatementDate;
  type: CustomerTypeEnum;
}

export type PaginatedTermsAndConditionsAddendumSchemaNext = number | null;

export type TermsAndConditionsAddendumDumpSchemaTermsAndConditionsItem = {};

export interface TermsAndConditionsAddendumDumpSchema {
  id: string;
  name: string;
  terms_and_conditions: TermsAndConditionsAddendumDumpSchemaTermsAndConditionsItem[];
}

export interface PaginatedTermsAndConditionsAddendumSchema {
  elements: TermsAndConditionsAddendumDumpSchema[];
  next?: PaginatedTermsAndConditionsAddendumSchemaNext;
  total_count: number;
}

export type BaseTermsAndConditionsAddendumSchemaTermsAndConditionsItem = {};

export interface BaseTermsAndConditionsAddendumSchema {
  name: string;
  terms_and_conditions: BaseTermsAndConditionsAddendumSchemaTermsAndConditionsItem[];
}

export interface DocumentConfigurationsUpdateSchema {
  document_configurations: DocumentConfigurationUpdateSchema[];
}

export type DocumentConfigurationUpdateSchemaFooterAnyOfItem = {};

export type DocumentConfigurationUpdateSchemaFooter = DocumentConfigurationUpdateSchemaFooterAnyOfItem[] | null;

export interface DocumentConfigurationUpdateSchema {
  attachments: string[];
  document_type: DocumentType;
  footer?: DocumentConfigurationUpdateSchemaFooter;
  include_terms_and_conditions: boolean;
}

export type DocumentConfigurationDumpSchemaFooterAnyOfItem = {};

export type DocumentConfigurationDumpSchemaFooter = DocumentConfigurationDumpSchemaFooterAnyOfItem[] | null;

export interface DocumentConfigurationDumpSchema {
  attachments: CustomerFileDumpSchema[];
  document_type: DocumentType;
  footer?: DocumentConfigurationDumpSchemaFooter;
  include_terms_and_conditions: boolean;
}

export interface DocumentConfigurationsDumpSchema {
  document_configurations: DocumentConfigurationDumpSchema[];
}

export interface LocationGroupUpdateSchema {
  name: string;
}

export interface LocationGroupCreateSchema {
  name: string;
}

export interface LocationGroupHydratedDumpSchema {
  id: string;
  locations: LocationDumpSchema[];
  name: string;
}

export type PaginatedLocationGroupSchemaNext = number | null;

export interface LocationGroupDumpSchema {
  id: string;
  locations: LinkIDNameSchema[];
  name: string;
}

export interface PaginatedLocationGroupSchema {
  elements: LocationGroupDumpSchema[];
  next?: PaginatedLocationGroupSchemaNext;
  total_count: number;
}

export type LocationCreateSchemaRemoteObjectId = string | null;

export type LocationCreateSchemaPhone = string | null;

export type LocationCreateSchemaLocationGroupId = string | null;

export type LocationCreateSchemaIdentifier = string | null;

export interface LocationCreateSchema {
  address: AddressCreateSchema;
  holidays: HolidaySchema[];
  identifier?: LocationCreateSchemaIdentifier;
  location_group_id?: LocationCreateSchemaLocationGroupId;
  name: string;
  phone?: LocationCreateSchemaPhone;
  remote_object_id?: LocationCreateSchemaRemoteObjectId;
  timezone: TimezoneEnum;
}

export type LocationHydratedDumpSchemaWednesdayRollTime = string | null;

export type LocationHydratedDumpSchemaWednesdayOpenTime = string | null;

export type LocationHydratedDumpSchemaWednesdayCloseTime = string | null;

export type LocationHydratedDumpSchemaTuesdayRollTime = string | null;

export type LocationHydratedDumpSchemaTuesdayOpenTime = string | null;

export type LocationHydratedDumpSchemaTuesdayCloseTime = string | null;

export type LocationHydratedDumpSchemaThursdayRollTime = string | null;

export type LocationHydratedDumpSchemaThursdayOpenTime = string | null;

export type LocationHydratedDumpSchemaThursdayCloseTime = string | null;

export type LocationHydratedDumpSchemaSundayRollTime = string | null;

export type LocationHydratedDumpSchemaSundayOpenTime = string | null;

export type LocationHydratedDumpSchemaSundayCloseTime = string | null;

export type LocationHydratedDumpSchemaSaturdayRollTime = string | null;

export type LocationHydratedDumpSchemaSaturdayOpenTime = string | null;

export type LocationHydratedDumpSchemaSaturdayCloseTime = string | null;

export type LocationHydratedDumpSchemaRemoteObject = RemoteObjectDumpSchema | null;

export type LocationHydratedDumpSchemaPhone = string | null;

export type LocationHydratedDumpSchemaMondayRollTime = string | null;

export type LocationHydratedDumpSchemaMondayOpenTime = string | null;

export type LocationHydratedDumpSchemaMondayCloseTime = string | null;

export type LocationHydratedDumpSchemaLocationGroupId = string | null;

export type LocationHydratedDumpSchemaLocationGroup = LinkNameSchema | null;

export type LocationHydratedDumpSchemaFridayRollTime = string | null;

export type LocationHydratedDumpSchemaFridayOpenTime = string | null;

export type LocationHydratedDumpSchemaFridayCloseTime = string | null;

export interface LocationHydratedDumpSchema {
  address: AddressDumpSchema;
  friday_close_time?: LocationHydratedDumpSchemaFridayCloseTime;
  friday_open_time?: LocationHydratedDumpSchemaFridayOpenTime;
  friday_roll_time?: LocationHydratedDumpSchemaFridayRollTime;
  holidays: HolidaySchema[];
  id: string;
  identifier: string;
  is_open_friday: boolean;
  is_open_monday: boolean;
  is_open_saturday: boolean;
  is_open_sunday: boolean;
  is_open_thursday: boolean;
  is_open_tuesday: boolean;
  is_open_wednesday: boolean;
  location_group?: LocationHydratedDumpSchemaLocationGroup;
  location_group_id?: LocationHydratedDumpSchemaLocationGroupId;
  monday_close_time?: LocationHydratedDumpSchemaMondayCloseTime;
  monday_open_time?: LocationHydratedDumpSchemaMondayOpenTime;
  monday_roll_time?: LocationHydratedDumpSchemaMondayRollTime;
  name: string;
  phone?: LocationHydratedDumpSchemaPhone;
  remote_object?: LocationHydratedDumpSchemaRemoteObject;
  saturday_close_time?: LocationHydratedDumpSchemaSaturdayCloseTime;
  saturday_open_time?: LocationHydratedDumpSchemaSaturdayOpenTime;
  saturday_roll_time?: LocationHydratedDumpSchemaSaturdayRollTime;
  sunday_close_time?: LocationHydratedDumpSchemaSundayCloseTime;
  sunday_open_time?: LocationHydratedDumpSchemaSundayOpenTime;
  sunday_roll_time?: LocationHydratedDumpSchemaSundayRollTime;
  thursday_close_time?: LocationHydratedDumpSchemaThursdayCloseTime;
  thursday_open_time?: LocationHydratedDumpSchemaThursdayOpenTime;
  thursday_roll_time?: LocationHydratedDumpSchemaThursdayRollTime;
  timezone: TimezoneEnum;
  tuesday_close_time?: LocationHydratedDumpSchemaTuesdayCloseTime;
  tuesday_open_time?: LocationHydratedDumpSchemaTuesdayOpenTime;
  tuesday_roll_time?: LocationHydratedDumpSchemaTuesdayRollTime;
  wednesday_close_time?: LocationHydratedDumpSchemaWednesdayCloseTime;
  wednesday_open_time?: LocationHydratedDumpSchemaWednesdayOpenTime;
  wednesday_roll_time?: LocationHydratedDumpSchemaWednesdayRollTime;
}

export type HolidaySchemaRollTime = string | null;

export type HolidaySchemaOpenTime = string | null;

export type HolidaySchemaCloseTime = string | null;

export interface HolidaySchema {
  close_time?: HolidaySchemaCloseTime;
  date: string;
  open_time?: HolidaySchemaOpenTime;
  roll_time?: HolidaySchemaRollTime;
}

export type PaginatedLocationSchemaNext = number | null;

export interface PaginatedLocationSchema {
  elements: LocationDumpSchema[];
  next?: PaginatedLocationSchemaNext;
  total_count: number;
}

export type BusinessUpdateSchemaWatermark = string | null;

export type BusinessUpdateSchemaTermsAndConditionsAnyOfItem = {};

export type BusinessUpdateSchemaTermsAndConditions = BusinessUpdateSchemaTermsAndConditionsAnyOfItem[] | null;

export type BusinessUpdateSchemaReplyToEmail = string | null;

export type BusinessUpdateSchemaPhone = string | null;

export type BusinessUpdateSchemaLogo = string | null;

export interface BusinessUpdateSchema {
  address?: BusinessUpdateSchemaAddress;
  clock_hours_per_day: number;
  clock_hours_per_month: number;
  clock_hours_per_week: number;
  logo?: BusinessUpdateSchemaLogo;
  meter_hours_per_day: number;
  meter_hours_per_month: number;
  meter_hours_per_week: number;
  name: string;
  phone?: BusinessUpdateSchemaPhone;
  reply_to_email?: BusinessUpdateSchemaReplyToEmail;
  terms_and_conditions?: BusinessUpdateSchemaTermsAndConditions;
  watermark?: BusinessUpdateSchemaWatermark;
}

export type AddressCreateSchemaAddressTwo = string | null;

export interface AddressCreateSchema {
  address_one: string;
  address_two?: AddressCreateSchemaAddressTwo;
  city: string;
  country: CountryEnum;
  state: StateEnum;
  zip: string;
}

export type BusinessUpdateSchemaAddress = AddressCreateSchema | null;

export type BusinessDumpSchemaWatermark = CustomerFileDumpSchema | null;

export type BusinessDumpSchemaTermsAndConditionsAnyOfItem = {};

export type BusinessDumpSchemaTermsAndConditions = BusinessDumpSchemaTermsAndConditionsAnyOfItem[] | null;

export type BusinessDumpSchemaReplyToEmail = string | null;

export type BusinessDumpSchemaPhone = string | null;

export type BusinessDumpSchemaLogo = CustomerFileDumpSchema | null;

export type BusinessDumpSchemaAddress = AddressDumpSchema | null;

export interface BusinessDumpSchema {
  address?: BusinessDumpSchemaAddress;
  clock_hours_per_day: number;
  clock_hours_per_month: number;
  clock_hours_per_week: number;
  locations: LocationDumpSchema[];
  logo?: BusinessDumpSchemaLogo;
  meter_hours_per_day: number;
  meter_hours_per_month: number;
  meter_hours_per_week: number;
  name: string;
  phone?: BusinessDumpSchemaPhone;
  reply_to_email?: BusinessDumpSchemaReplyToEmail;
  terms_and_conditions?: BusinessDumpSchemaTermsAndConditions;
  watermark?: BusinessDumpSchemaWatermark;
}

export type TimezoneEnum = typeof TimezoneEnum[keyof typeof TimezoneEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimezoneEnum = {
  'America/Los_Angeles': 'America/Los_Angeles',
  'America/Denver': 'America/Denver',
  'America/Phoenix': 'America/Phoenix',
  'America/Chicago': 'America/Chicago',
  'America/New_York': 'America/New_York',
} as const;

export type LocationDumpSchemaWednesdayRollTime = string | null;

export type LocationDumpSchemaWednesdayOpenTime = string | null;

export type LocationDumpSchemaWednesdayCloseTime = string | null;

export type LocationDumpSchemaTuesdayRollTime = string | null;

export type LocationDumpSchemaTuesdayOpenTime = string | null;

export type LocationDumpSchemaTuesdayCloseTime = string | null;

export type LocationDumpSchemaThursdayRollTime = string | null;

export type LocationDumpSchemaThursdayOpenTime = string | null;

export type LocationDumpSchemaThursdayCloseTime = string | null;

export type LocationDumpSchemaSundayRollTime = string | null;

export type LocationDumpSchemaSundayOpenTime = string | null;

export type LocationDumpSchemaSundayCloseTime = string | null;

export type LocationDumpSchemaSaturdayRollTime = string | null;

export type LocationDumpSchemaSaturdayOpenTime = string | null;

export type LocationDumpSchemaSaturdayCloseTime = string | null;

export type LocationDumpSchemaPhone = string | null;

export type LocationDumpSchemaMondayRollTime = string | null;

export type LocationDumpSchemaMondayOpenTime = string | null;

export type LocationDumpSchemaMondayCloseTime = string | null;

export type LocationDumpSchemaLocationGroupId = string | null;

export type LocationDumpSchemaLocationGroup = LinkNameSchema | null;

export type LocationDumpSchemaFridayRollTime = string | null;

export type LocationDumpSchemaFridayOpenTime = string | null;

export type LocationDumpSchemaFridayCloseTime = string | null;

export interface LocationDumpSchema {
  address: AddressDumpSchema;
  friday_close_time?: LocationDumpSchemaFridayCloseTime;
  friday_open_time?: LocationDumpSchemaFridayOpenTime;
  friday_roll_time?: LocationDumpSchemaFridayRollTime;
  id: string;
  identifier: string;
  is_open_friday: boolean;
  is_open_monday: boolean;
  is_open_saturday: boolean;
  is_open_sunday: boolean;
  is_open_thursday: boolean;
  is_open_tuesday: boolean;
  is_open_wednesday: boolean;
  location_group?: LocationDumpSchemaLocationGroup;
  location_group_id?: LocationDumpSchemaLocationGroupId;
  monday_close_time?: LocationDumpSchemaMondayCloseTime;
  monday_open_time?: LocationDumpSchemaMondayOpenTime;
  monday_roll_time?: LocationDumpSchemaMondayRollTime;
  name: string;
  phone?: LocationDumpSchemaPhone;
  saturday_close_time?: LocationDumpSchemaSaturdayCloseTime;
  saturday_open_time?: LocationDumpSchemaSaturdayOpenTime;
  saturday_roll_time?: LocationDumpSchemaSaturdayRollTime;
  sunday_close_time?: LocationDumpSchemaSundayCloseTime;
  sunday_open_time?: LocationDumpSchemaSundayOpenTime;
  sunday_roll_time?: LocationDumpSchemaSundayRollTime;
  thursday_close_time?: LocationDumpSchemaThursdayCloseTime;
  thursday_open_time?: LocationDumpSchemaThursdayOpenTime;
  thursday_roll_time?: LocationDumpSchemaThursdayRollTime;
  timezone: TimezoneEnum;
  tuesday_close_time?: LocationDumpSchemaTuesdayCloseTime;
  tuesday_open_time?: LocationDumpSchemaTuesdayOpenTime;
  tuesday_roll_time?: LocationDumpSchemaTuesdayRollTime;
  wednesday_close_time?: LocationDumpSchemaWednesdayCloseTime;
  wednesday_open_time?: LocationDumpSchemaWednesdayOpenTime;
  wednesday_roll_time?: LocationDumpSchemaWednesdayRollTime;
}

export interface LinkNameSchema {
  id: string;
  name: string;
}

export type CommunicationPreference = typeof CommunicationPreference[keyof typeof CommunicationPreference];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommunicationPreference = {
  order__user: 'order__user',
  order__admin: 'order__admin',
  order__customer: 'order__customer',
  comments: 'comments',
  driver_dispatch_notification: 'driver_dispatch_notification',
} as const;

export interface BasePreferenceSchema {
  enabled: boolean;
  key: CommunicationPreference;
  user_id: string;
}

export interface PreferencesSchema {
  preferences: BasePreferenceSchema[];
}

export interface EventConfigurationsCreateSchema {
  event_configurations: EventConfigurationCreateSchema[];
}

export type EventConfigurationCreateSchemaMessageTemplateId = string | null;

export interface EventConfigurationsDumpSchema {
  event_configurations: EventConfigurationDumpSchema[];
}

export type EventType = typeof EventType[keyof typeof EventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  invoice_issued: 'invoice_issued',
  credit_note_issued: 'credit_note_issued',
  auto_charge_reminder: 'auto_charge_reminder',
  success_payment_refund: 'success_payment_refund',
  failed_payment_refund: 'failed_payment_refund',
  contract_esign: 'contract_esign',
  customer_statement: 'customer_statement',
} as const;

export interface EventConfigurationCreateSchema {
  enabled: boolean;
  event_type: EventType;
  message_template_id?: EventConfigurationCreateSchemaMessageTemplateId;
}

export type EventConfigurationDumpSchemaMessageTemplateId = string | null;

export interface EventConfigurationDumpSchema {
  enabled: boolean;
  event_type: EventType;
  message_template_id?: EventConfigurationDumpSchemaMessageTemplateId;
  object_type: MessageTemplateObject;
}

export type ResolvedMessageTemplateDumpSchemaResolvedRecipientsItem = ResolvedUserRecipient | ResolvedCustomerContactRecipient | UserRecipient | ManualRecipient;

export type ResolvedMessageTemplateDumpSchemaResolvedCcRecipientsItem = ResolvedUserRecipient | ResolvedCustomerContactRecipient | UserRecipient | ManualRecipient;

export type ResolvedMessageTemplateDumpSchemaResolvedBccRecipientsItem = ResolvedUserRecipient | ResolvedCustomerContactRecipient | UserRecipient | ManualRecipient;

export type ResolvedMessageTemplateDumpSchemaPotentialRecipientsItem = ResolvedUserRecipient | ResolvedCustomerContactRecipient;

export type ResolvedMessageTemplateDumpSchemaMessageTemplate = MessageTemplateDumpSchema | null;

export interface ResolvedMessageTemplateDumpSchema {
  message_template?: ResolvedMessageTemplateDumpSchemaMessageTemplate;
  potential_attachments: ResolvedAttachmentSchema[];
  potential_recipients: ResolvedMessageTemplateDumpSchemaPotentialRecipientsItem[];
  potential_variables: BaseVariableSchema[];
  resolved_attachments: ResolvedAttachmentSchema[];
  resolved_bcc_recipients: ResolvedMessageTemplateDumpSchemaResolvedBccRecipientsItem[];
  resolved_cc_recipients: ResolvedMessageTemplateDumpSchemaResolvedCcRecipientsItem[];
  resolved_recipients: ResolvedMessageTemplateDumpSchemaResolvedRecipientsItem[];
  resolved_variables: ResolvedVariableSchema[];
}

export type TimeVariableSchemaType = typeof TimeVariableSchemaType[keyof typeof TimeVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeVariableSchemaType = {
  time: 'time',
} as const;

export type TimeVariableSchemaDateValue = string | null;

export interface TimeVariableSchema {
  date_value?: TimeVariableSchemaDateValue;
  type: TimeVariableSchemaType;
}

export type StringVariableSchemaType = typeof StringVariableSchemaType[keyof typeof StringVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StringVariableSchemaType = {
  string: 'string',
} as const;

export type StringVariableSchemaStringValue = string | null;

export interface StringVariableSchema {
  string_value?: StringVariableSchemaStringValue;
  type: StringVariableSchemaType;
}

export type ResolvedVariableSchemaValue = AddressVariableSchema | StringVariableSchema | DateTimeVariableSchema | DateVariableSchema | TimeVariableSchema | MoneyVariableSchema;

export interface ResolvedVariableSchema {
  name: string;
  type: TemplateVariableType;
  value: ResolvedVariableSchemaValue;
}

export type ResolvedUserRecipientType = typeof ResolvedUserRecipientType[keyof typeof ResolvedUserRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResolvedUserRecipientType = {
  resolved_user: 'resolved_user',
} as const;

export type ResolvedUserRecipientLastName = string | null;

export type ResolvedUserRecipientFirstName = string | null;

export interface ResolvedUserRecipient {
  email: string;
  first_name?: ResolvedUserRecipientFirstName;
  last_name?: ResolvedUserRecipientLastName;
  type: RecipientType;
  type_: ResolvedUserRecipientType;
  user_id: string;
}

export type ResolvedCustomerContactRecipientType = typeof ResolvedCustomerContactRecipientType[keyof typeof ResolvedCustomerContactRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResolvedCustomerContactRecipientType = {
  customer_contact: 'customer_contact',
} as const;

export type ResolvedCustomerContactRecipientEmail = string | null;

export interface ResolvedCustomerContactRecipient {
  customer_contact_id: string;
  email?: ResolvedCustomerContactRecipientEmail;
  first_name: string;
  last_name: string;
  type: RecipientType;
  type_: ResolvedCustomerContactRecipientType;
}

export type MoneyVariableSchemaType = typeof MoneyVariableSchemaType[keyof typeof MoneyVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MoneyVariableSchemaType = {
  money: 'money',
} as const;

export type MoneyVariableSchemaMoneyValue = string | null;

export interface MoneyVariableSchema {
  money_value?: MoneyVariableSchemaMoneyValue;
  type: MoneyVariableSchemaType;
}

export type DateVariableSchemaType = typeof DateVariableSchemaType[keyof typeof DateVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateVariableSchemaType = {
  date: 'date',
} as const;

export type DateVariableSchemaDateValue = string | null;

export interface DateVariableSchema {
  date_value?: DateVariableSchemaDateValue;
  type: DateVariableSchemaType;
}

export type DateTimeVariableSchemaType = typeof DateTimeVariableSchemaType[keyof typeof DateTimeVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateTimeVariableSchemaType = {
  datetime: 'datetime',
} as const;

export type DateTimeVariableSchemaDatetimeValue = string | null;

export interface DateTimeVariableSchema {
  datetime_value?: DateTimeVariableSchemaDatetimeValue;
  type: DateTimeVariableSchemaType;
}

export interface CoordinateDumpSchema {
  latitude: string;
  longitude: string;
}

export type AddressVariableSchemaType = typeof AddressVariableSchemaType[keyof typeof AddressVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressVariableSchemaType = {
  address: 'address',
} as const;

export interface AddressVariableSchema {
  address_value?: AddressVariableSchemaAddressValue;
  type: AddressVariableSchemaType;
}

export type AddressDumpSchemaCoordinate = CoordinateDumpSchema | null;

export type AddressDumpSchemaAddressTwo = string | null;

export interface AddressDumpSchema {
  address_one: string;
  address_two?: AddressDumpSchemaAddressTwo;
  city: string;
  coordinate?: AddressDumpSchemaCoordinate;
  country: CountryEnum;
  id: string;
  state: StateEnum;
  zip: string;
}

export type AddressVariableSchemaAddressValue = AddressDumpSchema | null;

export type ResolveMessageTemplateBodySchemaOtherDataAnyOf = { [key: string]: any };

export type ResolveMessageTemplateBodySchemaOtherData = ResolveMessageTemplateBodySchemaOtherDataAnyOf | null;

export type ResolveMessageTemplateBodySchemaObjectType = MessageTemplateObject | null;

export type ResolveMessageTemplateBodySchemaMessageTemplateId = string | null;

export interface ResolveMessageTemplateBodySchema {
  message_template_id?: ResolveMessageTemplateBodySchemaMessageTemplateId;
  object_id: string;
  object_type?: ResolveMessageTemplateBodySchemaObjectType;
  other_data?: ResolveMessageTemplateBodySchemaOtherData;
}

export type TemplateVariableType = typeof TemplateVariableType[keyof typeof TemplateVariableType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TemplateVariableType = {
  string: 'string',
  datetime: 'datetime',
  date: 'date',
  time: 'time',
  money: 'money',
  address: 'address',
} as const;

export interface BaseVariableSchema {
  name: string;
  type: TemplateVariableType;
}

export interface PotentialVariablesAttachmentsSchema {
  potential_attachments: AttachmentType[];
  potential_recipients: TypeRecipient[];
  potential_variables: BaseVariableSchema[];
}

export interface UnresolvedBodySchema {
  object_type: MessageTemplateObject;
}

export type BaseMessageTemplateSchemaSubjectItem = {};

export type BaseMessageTemplateSchemaBodyItem = {};

export type BaseMessageTemplateSchemaBccRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export interface BaseMessageTemplateSchema {
  attachments: AttachmentType[];
  bcc_recipients: BaseMessageTemplateSchemaBccRecipientsItem[];
  body: BaseMessageTemplateSchemaBodyItem[];
  cc_recipients: BaseMessageTemplateSchemaCcRecipientsItem[];
  name: string;
  obj: MessageTemplateObject;
  recipients: BaseMessageTemplateSchemaRecipientsItem[];
  subject: BaseMessageTemplateSchemaSubjectItem[];
}

export type PaginatedMessageTemplateSchemaNext = number | null;

export interface PaginatedMessageTemplateSchema {
  elements: MessageTemplateDumpSchema[];
  next?: PaginatedMessageTemplateSchemaNext;
  total_count: number;
}

export type UserRecipientType = typeof UserRecipientType[keyof typeof UserRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRecipientType = {
  user: 'user',
} as const;

export type UserRecipientLastName = string | null;

export type UserRecipientFirstName = string | null;

export interface UserRecipient {
  email: string;
  first_name?: UserRecipientFirstName;
  last_name?: UserRecipientLastName;
  type_: UserRecipientType;
  user_id: string;
}

export type BaseMessageTemplateSchemaCcRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export type TypeRecipientType = typeof TypeRecipientType[keyof typeof TypeRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TypeRecipientType = {
  type: 'type',
} as const;

export type RecipientType = typeof RecipientType[keyof typeof RecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecipientType = {
  quote__sales_rep: 'quote__sales_rep',
  quote__secondary_sales_rep: 'quote__secondary_sales_rep',
  quote__customer_contact: 'quote__customer_contact',
  order__sales_rep: 'order__sales_rep',
  order__secondary_sales_rep: 'order__secondary_sales_rep',
  order__customer_contact: 'order__customer_contact',
  invoice__sales_rep: 'invoice__sales_rep',
  invoice__secondary_sales_rep: 'invoice__secondary_sales_rep',
  invoice__customer_contact: 'invoice__customer_contact',
  payment__customer_contact: 'payment__customer_contact',
  customer__contact: 'customer__contact',
} as const;

export interface TypeRecipient {
  type: RecipientType;
  type_: TypeRecipientType;
}

export type BaseMessageTemplateSchemaRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export type MessageTemplateDumpSchemaSubjectItem = {};

export type MessageTemplateDumpSchemaRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export type MessageTemplateDumpSchemaCcRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export type MessageTemplateDumpSchemaBodyItem = {};

export interface MessageTemplateDumpSchema {
  attachments: AttachmentType[];
  bcc_recipients: MessageTemplateDumpSchemaBccRecipientsItem[];
  body: MessageTemplateDumpSchemaBodyItem[];
  cc_recipients: MessageTemplateDumpSchemaCcRecipientsItem[];
  id: string;
  name: string;
  obj: MessageTemplateObject;
  recipients: MessageTemplateDumpSchemaRecipientsItem[];
  subject: MessageTemplateDumpSchemaSubjectItem[];
}

export type ManualRecipientType = typeof ManualRecipientType[keyof typeof ManualRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualRecipientType = {
  manual: 'manual',
} as const;

export interface ManualRecipient {
  email: string;
  type_: ManualRecipientType;
}

export type MessageTemplateDumpSchemaBccRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export interface PDFBodySchema {
  document_type: DocumentType;
  obj_id: string;
}

export type FileResponseSchemaType = typeof FileResponseSchemaType[keyof typeof FileResponseSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileResponseSchemaType = {
  file: 'file',
} as const;

export interface FileResponseSchema {
  file: CustomerFileDumpSchema;
  type: FileResponseSchemaType;
}

export type PDFResponseSchemaBody = FileResponseSchema | AsyncResponseSchema;

export interface PDFResponseSchema {
  body: PDFResponseSchemaBody;
}

export type AsyncResponseSchemaType = typeof AsyncResponseSchemaType[keyof typeof AsyncResponseSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AsyncResponseSchemaType = {
  async: 'async',
} as const;

export interface AsyncResponseSchema {
  message_id: string;
  type: AsyncResponseSchemaType;
}

export type SendEmailBodySchemaSubjectItem = {};

export type SendEmailBodySchemaCta = CTATypeEnum | null;

export type SendEmailBodySchemaBodyItem = {};

export interface SendEmailBodySchema {
  additional_attachments: string[];
  attachments: ResolvedAttachmentSchema[];
  bcc: SendingRecipientType[];
  body: SendEmailBodySchemaBodyItem[];
  cc: SendingRecipientType[];
  cta?: SendEmailBodySchemaCta;
  obj: MessageTemplateObject;
  obj_id: string;
  recipients: SendingRecipientType[];
  subject: SendEmailBodySchemaSubjectItem[];
}

export type SendingRecipientTypeUserId = string | null;

export type SendingRecipientTypeEmail = string | null;

export interface SendingRecipientType {
  email?: SendingRecipientTypeEmail;
  user_id?: SendingRecipientTypeUserId;
}

export type MessageTemplateObject = typeof MessageTemplateObject[keyof typeof MessageTemplateObject];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageTemplateObject = {
  quote: 'quote',
  order: 'order',
  invoice: 'invoice',
  payment: 'payment',
  customer: 'customer',
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentType = {
  quote: 'quote',
  order: 'order',
  invoice: 'invoice',
  invoice__credit_note: 'invoice__credit_note',
  invoice__debit_note: 'invoice__debit_note',
  payment: 'payment',
  customer: 'customer',
} as const;

export type CTATypeEnum = typeof CTATypeEnum[keyof typeof CTATypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CTATypeEnum = {
  esign: 'esign',
  payment: 'payment',
  order: 'order',
} as const;

export type AttachmentType = typeof AttachmentType[keyof typeof AttachmentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttachmentType = {
  quote__quote_pdf: 'quote__quote_pdf',
  order__order_pdf: 'order__order_pdf',
  order__invoices: 'order__invoices',
  order__latest_invoice: 'order__latest_invoice',
  invoice__invoice_pdf: 'invoice__invoice_pdf',
  customer__statement_pdf: 'customer__statement_pdf',
  customer__invoices: 'customer__invoices',
} as const;

export interface ResolvedAttachmentSchema {
  attachment_obj_id: string;
  document_type: DocumentType;
  key: AttachmentType;
  name: string;
  object_type: MessageTemplateObject;
}

export interface ActivityLogDumpSchema {
  events: EventSchema[];
  followers: UserBaseDumpSchema[];
  id: string;
}

export type UserDumpSchemaPhone = string | null;

export type UserDumpSchemaLastName = string | null;

export type UserDumpSchemaFirstName = string | null;

export interface UserDumpSchema {
  email: string;
  first_name?: UserDumpSchemaFirstName;
  full_name: string;
  id: string;
  last_name?: UserDumpSchemaLastName;
  phone?: UserDumpSchemaPhone;
  username: string;
}

export type UserBaseDumpSchemaPhone = string | null;

export type UserBaseDumpSchemaLastName = string | null;

export type UserBaseDumpSchemaFirstName = string | null;

export interface UserBaseDumpSchema {
  email: string;
  first_name?: UserBaseDumpSchemaFirstName;
  full_name: string;
  id: string;
  last_name?: UserBaseDumpSchemaLastName;
  phone?: UserBaseDumpSchemaPhone;
  username: string;
}

/**
 * The state machine to rule them all.

This will require many more states.

Consider breaking this up into different types of state,
e.g. payment state, quote state, etc
 */
export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderStatus = {
  quote: 'quote',
  reservation: 'reservation',
  contract: 'contract',
  active: 'active',
  returned: 'returned',
  closed: 'closed',
  deleted: 'deleted',
} as const;

export type OrderDeliveryStatus = typeof OrderDeliveryStatus[keyof typeof OrderDeliveryStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDeliveryStatus = {
  left_branch: 'left_branch',
  one_stop_away: 'one_stop_away',
  arrived: 'arrived',
  delivered: 'delivered',
} as const;

export interface OrderDeliveryEventSchema {
  new_status: OrderDeliveryStatus;
}

export interface OrderCreateActivitySchema {
  initial_status: OrderStatus;
}

export type ObjectStateTransitionSchemaToState = {};

export type ObjectStateTransitionSchemaFromState = {};

export interface ObjectStateTransitionSchema {
  from_state: ObjectStateTransitionSchemaFromState;
  to_state: ObjectStateTransitionSchemaToState;
}

export type EventSchemaUser = UserDumpSchema | null;

export type EventSchemaEventData = OrderDeliveryEventSchema | CommentCreateSchema | CommentBodySchema | ObjectStateTransitionSchema | Diff | OrderCreateActivitySchema;

export interface EventSchema {
  created_at: string;
  event_data: EventSchemaEventData;
  event_type: ActivityEventType;
  id: string;
  user?: EventSchemaUser;
}

export type DiffListType = typeof DiffListType[keyof typeof DiffListType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffListType = {
  list: 'list',
} as const;

export interface DiffList {
  elements: Diff[];
  type_: DiffListType;
}

export type DiffItemStringType = typeof DiffItemStringType[keyof typeof DiffItemStringType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemStringType = {
  string: 'string',
} as const;

export type DiffItemStringOldValue = string | null;

export type DiffItemStringNewValue = string | null;

export interface DiffItemString {
  new_value?: DiffItemStringNewValue;
  old_value?: DiffItemStringOldValue;
  type_: DiffItemStringType;
}

export type DiffItemIntType = typeof DiffItemIntType[keyof typeof DiffItemIntType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemIntType = {
  int: 'int',
} as const;

export type DiffItemIntOldValue = number | null;

export type DiffItemIntNewValue = number | null;

export interface DiffItemInt {
  new_value?: DiffItemIntNewValue;
  old_value?: DiffItemIntOldValue;
  type_: DiffItemIntType;
}

export type DiffItemDictType = typeof DiffItemDictType[keyof typeof DiffItemDictType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemDictType = {
  dict: 'dict',
} as const;

export interface DiffItemDict {
  new_value?: DiffItemDictNewValue;
  old_value?: DiffItemDictOldValue;
  type_: DiffItemDictType;
}

export type DiffItemDictOldValueAnyOf = { [key: string]: any };

export type DiffItemDictOldValue = DiffItemDictOldValueAnyOf | null;

export type DiffItemDictNewValueAnyOf = { [key: string]: any };

export type DiffItemDictNewValue = DiffItemDictNewValueAnyOf | null;

export type DiffItemDecimalType = typeof DiffItemDecimalType[keyof typeof DiffItemDecimalType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemDecimalType = {
  decimal: 'decimal',
} as const;

export type DiffItemDecimalOldValue = string | null;

export type DiffItemDecimalNewValue = string | null;

export interface DiffItemDecimal {
  new_value?: DiffItemDecimalNewValue;
  old_value?: DiffItemDecimalOldValue;
  type_: DiffItemDecimalType;
}

export type DiffItemDatetimeType = typeof DiffItemDatetimeType[keyof typeof DiffItemDatetimeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemDatetimeType = {
  datetime: 'datetime',
} as const;

export type DiffItemDatetimeOldValue = string | null;

export type DiffItemDatetimeNewValue = string | null;

export interface DiffItemDatetime {
  new_value?: DiffItemDatetimeNewValue;
  old_value?: DiffItemDatetimeOldValue;
  type_: DiffItemDatetimeType;
}

export type DiffItemDateType = typeof DiffItemDateType[keyof typeof DiffItemDateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemDateType = {
  date: 'date',
} as const;

export type DiffItemDateOldValue = string | null;

export type DiffItemDateNewValue = string | null;

export interface DiffItemDate {
  new_value?: DiffItemDateNewValue;
  old_value?: DiffItemDateOldValue;
  type_: DiffItemDateType;
}

export type DiffType = typeof DiffType[keyof typeof DiffType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffType = {
  diff: 'diff',
} as const;

export type DiffChanges = {[key: string]: DiffItemString | DiffItemInt | DiffItemDatetime | DiffItemDate | DiffItemDict | DiffItemDecimal | Diff | DiffList};

export interface Diff {
  changes?: DiffChanges;
  type_: DiffType;
}

export type CommentableObjects = typeof CommentableObjects[keyof typeof CommentableObjects];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommentableObjects = {
  order: 'order',
  payment: 'payment',
  customer: 'customer',
  vendor: 'vendor',
  invoice: 'invoice',
  purchase_order: 'purchase_order',
} as const;

export type CommentCreateSchemaCommentItem = {};

export interface CommentCreateSchema {
  comment: CommentCreateSchemaCommentItem[];
  mentioned_users: string[];
  object: CommentableObjects;
  object_id: string;
}

export type CommentBodySchemaCommentItem = {};

export interface CommentBodySchema {
  comment: CommentBodySchemaCommentItem[];
}

export type ActivityEventType = typeof ActivityEventType[keyof typeof ActivityEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActivityEventType = {
  create: 'create',
  comment: 'comment',
  update: 'update',
  state_transition: 'state_transition',
  follow: 'follow',
  unfollow: 'unfollow',
  delete: 'delete',
  order_create: 'order_create',
  order_delivery: 'order_delivery',
} as const;

export type CategoryUpdateSchemaUsefulLife = number | null;

export type CategoryUpdateSchemaProductTaxCodeGroupId = string | null;

export type CategoryUpdateSchemaInventoryProfileId = string | null;

export type CategoryUpdateSchemaIdentifier = string | null;

export type CategoryUpdateSchemaDepreciationSettingId = string | null;

export type CategoryCreateSchemaUsefulLife = number | null;

export type CategoryCreateSchemaProductTaxCodeGroupId = string | null;

export type CategoryCreateSchemaInventoryProfileId = string | null;

export type CategoryCreateSchemaIdentifier = string | null;

export type CategoryCreateSchemaDepreciationSettingId = string | null;

export interface InventoryUploadCreateSchema {
  is_on_order?: boolean;
  upload: string;
}

export interface CategoryUpdateSchema {
  attachments: InventoryUploadCreateSchema[];
  depreciation_setting_id?: CategoryUpdateSchemaDepreciationSettingId;
  identifier?: CategoryUpdateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  inventory_profile_id?: CategoryUpdateSchemaInventoryProfileId;
  name: string;
  product_tax_code_group_id?: CategoryUpdateSchemaProductTaxCodeGroupId;
  tags: string[];
  type: KlassType;
  useful_life?: CategoryUpdateSchemaUsefulLife;
}

export interface CategoryCreateSchema {
  attachments: InventoryUploadCreateSchema[];
  depreciation_setting_id?: CategoryCreateSchemaDepreciationSettingId;
  identifier?: CategoryCreateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  inventory_profile_id?: CategoryCreateSchemaInventoryProfileId;
  name: string;
  product_tax_code_group_id?: CategoryCreateSchemaProductTaxCodeGroupId;
  tags: string[];
  type: KlassType;
  useful_life?: CategoryCreateSchemaUsefulLife;
}

export type CategoryHydratedDumpSchemaUsefulLife = number | null;

export type CategoryHydratedDumpSchemaProductTaxCodeGroupId = string | null;

export type CategoryHydratedDumpSchemaInventoryProfileId = string | null;

export type CategoryHydratedDumpSchemaInventoryProfile = InventoryProfileDumpSchema | null;

export type CategoryHydratedDumpSchemaDepreciationSettingId = string | null;

export type CategoryHydratedDumpSchemaDepreciationSetting = DepreciationSettingDumpSchema | null;

export interface CategoryHydratedDumpSchema {
  attachments: InventoryUploadDumpSchema[];
  count_assets: number;
  depreciation_setting?: CategoryHydratedDumpSchemaDepreciationSetting;
  depreciation_setting_id?: CategoryHydratedDumpSchemaDepreciationSettingId;
  id: string;
  identifier: string;
  images: CustomerFileDumpSchema[];
  inventory_profile?: CategoryHydratedDumpSchemaInventoryProfile;
  inventory_profile_id?: CategoryHydratedDumpSchemaInventoryProfileId;
  name: string;
  product_tax_code_group?: CategoryHydratedDumpSchemaProductTaxCodeGroup;
  product_tax_code_group_id?: CategoryHydratedDumpSchemaProductTaxCodeGroupId;
  tags: string[];
  type: KlassType;
  useful_life?: CategoryHydratedDumpSchemaUsefulLife;
}

export type UploadType = typeof UploadType[keyof typeof UploadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadType = {
  openai_cache: 'openai_cache',
  category_image: 'category_image',
  category_attachment: 'category_attachment',
  klass_image: 'klass_image',
  klass_attachment: 'klass_attachment',
  asset_image: 'asset_image',
  asset_attachment: 'asset_attachment',
  variant_specification: 'variant_specification',
  customer_attachment: 'customer_attachment',
  customer_contact_attachment: 'customer_contact_attachment',
  question_response_signature: 'question_response_signature',
  question_response_photo: 'question_response_photo',
  question_response_file: 'question_response_file',
  purchase_order: 'purchase_order',
  logo: 'logo',
  watermark: 'watermark',
  document_configuration: 'document_configuration',
  line_item_receiving_attachment: 'line_item_receiving_attachment',
  pdf__quote: 'pdf__quote',
  pdf__order: 'pdf__order',
  pdf__invoice: 'pdf__invoice',
  pdf__invoice__credit_note: 'pdf__invoice__credit_note',
  pdf__invoice__debit_note: 'pdf__invoice__debit_note',
  pdf__payment_receipt: 'pdf__payment_receipt',
  pdf__statement: 'pdf__statement',
} as const;

export type TaxTypeEnum = typeof TaxTypeEnum[keyof typeof TaxTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxTypeEnum = {
  sales_tax: 'sales_tax',
  vat: 'vat',
  gst: 'gst',
  custom: 'custom',
} as const;

export type TaxSourceEnum = typeof TaxSourceEnum[keyof typeof TaxSourceEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxSourceEnum = {
  origin: 'origin',
  destination: 'destination',
} as const;

export type TaxConfigurationDumpSchemaTaxLiabilityAccount = LedgerAccountDumpSchema | null;

export type TaxConfigurationDumpSchemaDescription = string | null;

export type TaxConfigurationChildDumpSchemaTaxLiabilityAccount = LedgerAccountDumpSchema | null;

export interface TaxConfigurationChildDumpSchema {
  id: string;
  name: string;
  rate: string;
  tax_liability_account?: TaxConfigurationChildDumpSchemaTaxLiabilityAccount;
  zip_codes: string[];
}

export type StateEnum = typeof StateEnum[keyof typeof StateEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StateEnum = {
  alabama: 'alabama',
  alaska: 'alaska',
  arizona: 'arizona',
  arkansas: 'arkansas',
  california: 'california',
  colorado: 'colorado',
  connecticut: 'connecticut',
  delaware: 'delaware',
  florida: 'florida',
  georgia: 'georgia',
  hawaii: 'hawaii',
  idaho: 'idaho',
  illinois: 'illinois',
  indiana: 'indiana',
  iowa: 'iowa',
  kansas: 'kansas',
  kentucky: 'kentucky',
  louisiana: 'louisiana',
  maine: 'maine',
  maryland: 'maryland',
  massachusetts: 'massachusetts',
  michigan: 'michigan',
  minnesota: 'minnesota',
  mississippi: 'mississippi',
  missouri: 'missouri',
  montana: 'montana',
  nebraska: 'nebraska',
  nevada: 'nevada',
  new_hampshire: 'new_hampshire',
  new_jersey: 'new_jersey',
  new_mexico: 'new_mexico',
  new_york: 'new_york',
  north_carolina: 'north_carolina',
  north_dakota: 'north_dakota',
  ohio: 'ohio',
  oklahoma: 'oklahoma',
  oregon: 'oregon',
  pennsylvania: 'pennsylvania',
  rhode_island: 'rhode_island',
  south_carolina: 'south_carolina',
  south_dakota: 'south_dakota',
  tennessee: 'tennessee',
  texas: 'texas',
  utah: 'utah',
  vermont: 'vermont',
  virginia: 'virginia',
  washington: 'washington',
  west_virginia: 'west_virginia',
  wisconsin: 'wisconsin',
  wyoming: 'wyoming',
  district_of_columbia: 'district_of_columbia',
  american_samoa: 'american_samoa',
  guam: 'guam',
  puerto_rico: 'puerto_rico',
} as const;

export interface ProductTaxCodeGroupDumpSchema {
  configurations: TaxConfigurationDumpSchema[];
  id: string;
  is_business_default: boolean;
  name: string;
}

export type CategoryHydratedDumpSchemaProductTaxCodeGroup = ProductTaxCodeGroupDumpSchema | null;

export interface CustomerFileDumpSchema {
  created_at: string;
  file_type: UploadType;
  filename: string;
  id: string;
  url: string;
}

export interface InventoryUploadDumpSchema {
  id: string;
  is_on_order?: boolean;
  upload: CustomerFileDumpSchema;
}

export type CountryEnum = typeof CountryEnum[keyof typeof CountryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountryEnum = {
  united_states: 'united_states',
  canada: 'canada',
} as const;

export interface TaxConfigurationDumpSchema {
  country_code: CountryEnum;
  description?: TaxConfigurationDumpSchemaDescription;
  id: string;
  name: string;
  rate: string;
  source: TaxSourceEnum;
  state_code: StateEnum;
  sub_configurations: TaxConfigurationChildDumpSchema[];
  tax_liability_account?: TaxConfigurationDumpSchemaTaxLiabilityAccount;
  type: TaxTypeEnum;
}

export type PaginatedCategoryDumpSchemaNext = number | null;

export interface PaginatedCategoryDumpSchema {
  elements: CategoryDumpSchema[];
  next?: PaginatedCategoryDumpSchemaNext;
  total_count: number;
}

export type KlassType = typeof KlassType[keyof typeof KlassType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KlassType = {
  rental: 'rental',
  sale: 'sale',
  service: 'service',
  part: 'part',
  non_rental: 'non_rental',
} as const;

export type CategoryDumpSchemaUsefulLife = number | null;

export type CategoryDumpSchemaProductTaxCodeGroupId = string | null;

export type CategoryDumpSchemaInventoryProfileId = string | null;

export type CategoryDumpSchemaDepreciationSettingId = string | null;

export interface CategoryDumpSchema {
  depreciation_setting_id?: CategoryDumpSchemaDepreciationSettingId;
  id: string;
  identifier: string;
  inventory_profile_id?: CategoryDumpSchemaInventoryProfileId;
  name: string;
  product_tax_code_group_id?: CategoryDumpSchemaProductTaxCodeGroupId;
  type: KlassType;
  useful_life?: CategoryDumpSchemaUsefulLife;
}

export interface LoginBodySchema {
  body: LoginBodySchemaBody;
}

export interface TokenLoginSchema {
  token: string;
}

export interface LoginRequestBody {
  password: string;
  username: string;
}

export type LoginBodySchemaBody = TokenLoginSchema | LoginRequestBody;

export type JournalEntriesSyncSchemaFilterList = JournalEntrySearchParams | null;

export interface JournalEntriesSyncSchema {
  filter_list?: JournalEntriesSyncSchemaFilterList;
  ids_to_sync: string[];
  sync_all?: boolean;
}

export type JournalEntrySearchParamsStatuses = JournalEntrySyncStatus[] | null;

export type JournalEntrySearchParamsStart = number | null;

export type JournalEntrySearchParamsPageSize = number | null;

export interface JournalEntrySearchParams {
  page_size?: JournalEntrySearchParamsPageSize;
  start?: JournalEntrySearchParamsStart;
  statuses?: JournalEntrySearchParamsStatuses;
}

export type JournalEntryUpdateSchemaDescription = string | null;

export interface JournalEntryUpdateSchema {
  date: string;
  description?: JournalEntryUpdateSchemaDescription;
  line_items: JournalEntryLineItemsUpdateSchema[];
}

export type JournalEntryLineItemsUpdateSchemaId = string | null;

export type JournalEntryLineItemsUpdateSchemaDescription = string | null;

export interface JournalEntryLineItemsUpdateSchema {
  amount: string;
  description?: JournalEntryLineItemsUpdateSchemaDescription;
  id: JournalEntryLineItemsUpdateSchemaId;
  line_item_type: LedgerEntryType;
  remote_account_id: string;
  remote_object_ids: string[];
}

export type JournalEntryHydratedDumpSchemaSyncedAt = string | null;

export type JournalEntryHydratedDumpSchemaSyncError = string | null;

export type JournalEntryHydratedDumpSchemaPayments = LinkIDSchema[] | null;

export type JournalEntryHydratedDumpSchemaKlasses = LinkIDNameSchema[] | null;

export type JournalEntryHydratedDumpSchemaErpLink = string | null;

export type JournalEntryHydratedDumpSchemaDescription = string | null;

export interface LinkIDSchema {
  id: string;
  identifier: string;
}

export type JournalEntryHydratedDumpSchemaInvoices = LinkIDSchema[] | null;

export type JournalEntryHydratedDumpSchemaDeposits = LinkIDSchema[] | null;

export interface JournalEntryHydratedDumpSchema {
  date: string;
  deposits?: JournalEntryHydratedDumpSchemaDeposits;
  description?: JournalEntryHydratedDumpSchemaDescription;
  erp_link?: JournalEntryHydratedDumpSchemaErpLink;
  id: string;
  invoices?: JournalEntryHydratedDumpSchemaInvoices;
  klasses?: JournalEntryHydratedDumpSchemaKlasses;
  line_items: JournalEntryLineItemDumpSchema[];
  payments?: JournalEntryHydratedDumpSchemaPayments;
  status: JournalEntrySyncStatus;
  sync_error?: JournalEntryHydratedDumpSchemaSyncError;
  synced_at?: JournalEntryHydratedDumpSchemaSyncedAt;
  total_amount: string;
}

export interface LinkIDNameSchema {
  id: string;
  identifier: string;
  name: string;
}

export type LedgerEntryType = typeof LedgerEntryType[keyof typeof LedgerEntryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LedgerEntryType = {
  debit: 'debit',
  credit: 'credit',
} as const;

export type JournalEntryLineItemDumpSchemaVendor = RemoteObjectDumpSchema | null;

export type JournalEntryLineItemDumpSchemaLocation = RemoteObjectDumpSchema | null;

export type JournalEntryLineItemDumpSchemaDescription = string | null;

export type JournalEntryLineItemDumpSchemaCustomer = RemoteObjectDumpSchema | null;

export interface JournalEntryLineItemDumpSchema {
  amount: string;
  customer?: JournalEntryLineItemDumpSchemaCustomer;
  description?: JournalEntryLineItemDumpSchemaDescription;
  id: string;
  line_item_type: LedgerEntryType;
  location?: JournalEntryLineItemDumpSchemaLocation;
  remote_account: RemoteAccountDumpSchema;
  vendor?: JournalEntryLineItemDumpSchemaVendor;
}

export type PaginatedJournalEntrySchemaNext = number | null;

export type JournalEntrySyncStatus = typeof JournalEntrySyncStatus[keyof typeof JournalEntrySyncStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JournalEntrySyncStatus = {
  pending: 'pending',
  ready_to_sync: 'ready_to_sync',
  failed: 'failed',
  succeeded: 'succeeded',
} as const;

export type JournalEntryBaseDumpSchemaSyncedAt = string | null;

export type JournalEntryBaseDumpSchemaErpLink = string | null;

export type JournalEntryBaseDumpSchemaDescription = string | null;

export interface JournalEntryBaseDumpSchema {
  date: string;
  description?: JournalEntryBaseDumpSchemaDescription;
  erp_link?: JournalEntryBaseDumpSchemaErpLink;
  id: string;
  status: JournalEntrySyncStatus;
  synced_at?: JournalEntryBaseDumpSchemaSyncedAt;
  total_amount: string;
}

export interface PaginatedJournalEntrySchema {
  elements: JournalEntryBaseDumpSchema[];
  next?: PaginatedJournalEntrySchemaNext;
  total_count: number;
}

export interface RemoteObjectBaseSchema {
  active: boolean;
  name: string;
  type: RemoteObjectTypeEnum;
}

export type PaginatedRemoteObjectSchemaNext = number | null;

export interface PaginatedRemoteObjectSchema {
  elements: RemoteObjectDumpSchema[];
  next?: PaginatedRemoteObjectSchemaNext;
  total_count: number;
}

export type RemoteObjectTypeEnum = typeof RemoteObjectTypeEnum[keyof typeof RemoteObjectTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RemoteObjectTypeEnum = {
  customer: 'customer',
  vendor: 'vendor',
  location: 'location',
} as const;

export interface RemoteObjectDumpSchema {
  active: boolean;
  id: string;
  name: string;
  type: RemoteObjectTypeEnum;
}

export type RemoteAccountCreateSchemaParentRemoteId = string | null;

export type RemoteAccountCreateSchemaAccountNumber = string | null;

export interface RemoteAccountCreateSchema {
  account_number?: RemoteAccountCreateSchemaAccountNumber;
  account_sub_type: AccountingSubAccountType;
  account_type: RemoteAccountType;
  active: boolean;
  name: string;
  parent_remote_id?: RemoteAccountCreateSchemaParentRemoteId;
}

export type PaginatedRemoteAccountSchemaNext = number | null;

export interface PaginatedRemoteAccountSchema {
  elements: RemoteAccountDumpSchema[];
  next?: PaginatedRemoteAccountSchemaNext;
  total_count: number;
}

export type BaseLedgerAccountSchemaParentId = string | null;

export type BaseLedgerAccountSchemaAccountType = RemoteAccountType | null;

export type BaseLedgerAccountSchemaAccountSubType = AccountingSubAccountType | null;

export interface BaseLedgerAccountSchema {
  account_number: string;
  account_sub_type?: BaseLedgerAccountSchemaAccountSubType;
  account_type?: BaseLedgerAccountSchemaAccountType;
  active?: boolean;
  name: string;
  parent_id?: BaseLedgerAccountSchemaParentId;
  type: LedgerAccountType;
}

export type PaginatedLedgerAccountSchemaNext = number | null;

export interface PaginatedLedgerAccountSchema {
  elements: LedgerAccountDumpSchema[];
  next?: PaginatedLedgerAccountSchemaNext;
  total_count: number;
}

export type LedgerAccountType = typeof LedgerAccountType[keyof typeof LedgerAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LedgerAccountType = {
  asset: 'asset',
  contra_asset: 'contra_asset',
  equity: 'equity',
  expense: 'expense',
  liability: 'liability',
  revenue: 'revenue',
} as const;

export type LedgerAccountDumpSchemaParentId = string | null;

export type LedgerAccountDumpSchemaParent = LedgerAccountDumpSchema | null;

export type LedgerAccountDumpSchemaAccountSubType = AccountingSubAccountType | null;

export interface LedgerAccountDumpSchema {
  account_number: string;
  account_sub_type?: LedgerAccountDumpSchemaAccountSubType;
  account_type?: LedgerAccountDumpSchemaAccountType;
  active?: boolean;
  balance: string;
  id: string;
  name: string;
  parent?: LedgerAccountDumpSchemaParent;
  parent_id?: LedgerAccountDumpSchemaParentId;
  type: LedgerAccountType;
}

export type PaginatedInventoryProfileSchemaNext = number | null;

export type InventoryProfileType = typeof InventoryProfileType[keyof typeof InventoryProfileType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InventoryProfileType = {
  rental: 'rental',
  sales: 'sales',
  parts: 'parts',
  service: 'service',
  re_rentals: 're_rentals',
  non_rental_assets: 'non_rental_assets',
} as const;

export interface InventoryProfileCreateSchema {
  account_mappings: BaseAccountMappingSchema[];
  asset_type: InventoryProfileType;
  name: string;
}

export interface InventoryProfileDumpSchema {
  account_mappings: AccountMappingDumpSchema[];
  asset_type: InventoryProfileType;
  id: string;
  name: string;
}

export interface PaginatedInventoryProfileSchema {
  elements: InventoryProfileDumpSchema[];
  next?: PaginatedInventoryProfileSchemaNext;
  total_count: number;
}

export type BaseAccountMappingSchemaRemoteAccountId = string | null;

export type BaseAccountMappingSchemaInventoryProfileId = string | null;

export interface BaseAccountMappingSchema {
  inventory_profile_id?: BaseAccountMappingSchemaInventoryProfileId;
  remote_account_id?: BaseAccountMappingSchemaRemoteAccountId;
  type: MoabAccountType;
}

export interface AccountMappingsCreateSchema {
  mappings: BaseAccountMappingSchema[];
}

export interface AccountMappingsDumpSchema {
  mappings: AccountMappingDumpSchema[];
}

export type RemoteAccountType = typeof RemoteAccountType[keyof typeof RemoteAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RemoteAccountType = {
  bank: 'bank',
  other_current_asset: 'other_current_asset',
  fixed_asset: 'fixed_asset',
  other_asset: 'other_asset',
  accounts_receivable: 'accounts_receivable',
  equity: 'equity',
  expense: 'expense',
  other_expense: 'other_expense',
  cost_of_goods_sold: 'cost_of_goods_sold',
  accounts_payable: 'accounts_payable',
  credit_card: 'credit_card',
  long_term_liability: 'long_term_liability',
  other_current_liability: 'other_current_liability',
  income: 'income',
  other_income: 'other_income',
} as const;

export type LedgerAccountDumpSchemaAccountType = RemoteAccountType | null;

export type RemoteAccountDumpSchemaParentAccount = RemoteAccountDumpSchema | null;

export type RemoteAccountDumpSchemaAccountNumber = string | null;

export type RemoteAccountClassification = typeof RemoteAccountClassification[keyof typeof RemoteAccountClassification];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RemoteAccountClassification = {
  asset: 'asset',
  contra_asset: 'contra_asset',
  equity: 'equity',
  expense: 'expense',
  liability: 'liability',
  revenue: 'revenue',
} as const;

export type MoabAccountType = typeof MoabAccountType[keyof typeof MoabAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MoabAccountType = {
  inventory: 'inventory',
  cash: 'cash',
  stripe_cash: 'stripe_cash',
  cash_undeposited_funds: 'cash_undeposited_funds',
  stripe_undeposited_funds: 'stripe_undeposited_funds',
  unbilled_receivables: 'unbilled_receivables',
  accounts_receivable: 'accounts_receivable',
  deferred_revenue: 'deferred_revenue',
  tax_liability: 'tax_liability',
  accumulated_depreciation: 'accumulated_depreciation',
  depreciation_expense: 'depreciation_expense',
  revenue: 'revenue',
  payment_processing_fees: 'payment_processing_fees',
  refund: 'refund',
  bad_debt: 'bad_debt',
  cogs: 'cogs',
} as const;

export type AccountingSubAccountType = typeof AccountingSubAccountType[keyof typeof AccountingSubAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountingSubAccountType = {
  cash_on_hand: 'cash_on_hand',
  checking: 'checking',
  money_market: 'money_market',
  rents_held_in_trust: 'rents_held_in_trust',
  savings: 'savings',
  trust_accounts: 'trust_accounts',
  cash_and_cash_equivalents: 'cash_and_cash_equivalents',
  other_ear_marked_bank_accounts: 'other_ear_marked_bank_accounts',
  allowance_for_bad_debts: 'allowance_for_bad_debts',
  development_costs: 'development_costs',
  employee_cash_advances: 'employee_cash_advances',
  other_current_assets: 'other_current_assets',
  inventory: 'inventory',
  investment_mortgage_real_estate_loans: 'investment_mortgage_real_estate_loans',
  investment_other: 'investment_other',
  investment_tax_exempt_securities: 'investment_tax_exempt_securities',
  investment_us_government_obligations: 'investment_us_government_obligations',
  loans_to_officers: 'loans_to_officers',
  loans_to_others: 'loans_to_others',
  loans_to_stockholders: 'loans_to_stockholders',
  prepaid_expenses: 'prepaid_expenses',
  retainage: 'retainage',
  undeposited_funds: 'undeposited_funds',
  assets_available_for_sale: 'assets_available_for_sale',
  bal_with_govt_authorities: 'bal_with_govt_authorities',
  called_up_share_capital_not_paid: 'called_up_share_capital_not_paid',
  expenditure_authorisations_and_letters_of_credit: 'expenditure_authorisations_and_letters_of_credit',
  global_tax_deferred: 'global_tax_deferred',
  global_tax_refund: 'global_tax_refund',
  internal_transfers: 'internal_transfers',
  other_consumables: 'other_consumables',
  provisions_current_assets: 'provisions_current_assets',
  short_term_investments_in_related_parties: 'short_term_investments_in_related_parties',
  short_term_loans_and_advances_to_related_parties: 'short_term_loans_and_advances_to_related_parties',
  trade_and_other_receivables: 'trade_and_other_receivables',
  accumulated_depletion: 'accumulated_depletion',
  accumulated_depreciation: 'accumulated_depreciation',
  depletable_assets: 'depletable_assets',
  fixed_asset_computers: 'fixed_asset_computers',
  fixed_asset_copiers: 'fixed_asset_copiers',
  fixed_asset_furniture: 'fixed_asset_furniture',
  fixed_asset_phone: 'fixed_asset_phone',
  fixed_asset_photo_video: 'fixed_asset_photo_video',
  fixed_asset_software: 'fixed_asset_software',
  fixed_asset_other_tools_equipment: 'fixed_asset_other_tools_equipment',
  furniture_and_fixtures: 'furniture_and_fixtures',
  land: 'land',
  leasehold_improvements: 'leasehold_improvements',
  other_fixed_assets: 'other_fixed_assets',
  accumulated_amortization: 'accumulated_amortization',
  buildings: 'buildings',
  intangible_assets: 'intangible_assets',
  machinery_and_equipment: 'machinery_and_equipment',
  vehicles: 'vehicles',
  assets_in_course_of_construction: 'assets_in_course_of_construction',
  capital_wip: 'capital_wip',
  cumulative_depreciation_on_intangible_assets: 'cumulative_depreciation_on_intangible_assets',
  intangible_assets_under_development: 'intangible_assets_under_development',
  land_asset: 'land_asset',
  non_current_assets: 'non_current_assets',
  participating_interests: 'participating_interests',
  provisions_fixed_assets: 'provisions_fixed_assets',
  lease_buyout: 'lease_buyout',
  other_long_term_assets: 'other_long_term_assets',
  security_deposits: 'security_deposits',
  accumulated_amortization_of_other_assets: 'accumulated_amortization_of_other_assets',
  goodwill: 'goodwill',
  licenses: 'licenses',
  organizational_costs: 'organizational_costs',
  assets_held_for_sale: 'assets_held_for_sale',
  available_for_sale_financial_assets: 'available_for_sale_financial_assets',
  deferred_tax: 'deferred_tax',
  investments: 'investments',
  long_term_investments: 'long_term_investments',
  long_term_loans_and_advances_to_related_parties: 'long_term_loans_and_advances_to_related_parties',
  other_intangible_assets: 'other_intangible_assets',
  other_long_term_investments: 'other_long_term_investments',
  other_long_term_loans_and_advances: 'other_long_term_loans_and_advances',
  prepayments_and_accrued_income: 'prepayments_and_accrued_income',
  accounts_receivable: 'accounts_receivable',
  opening_balance_equity: 'opening_balance_equity',
  partners_equity: 'partners_equity',
  retained_earnings: 'retained_earnings',
  accumulated_adjustment: 'accumulated_adjustment',
  owners_equity: 'owners_equity',
  paid_in_capital_or_surplus: 'paid_in_capital_or_surplus',
  partner_contributions: 'partner_contributions',
  partner_distributions: 'partner_distributions',
  preferred_stock: 'preferred_stock',
  common_stock: 'common_stock',
  treasury_stock: 'treasury_stock',
  estimated_taxes: 'estimated_taxes',
  healthcare: 'healthcare',
  personal_income: 'personal_income',
  personal_expense: 'personal_expense',
  accumulated_other_comprehensive_income: 'accumulated_other_comprehensive_income',
  called_up_share_capital: 'called_up_share_capital',
  capital_reserves: 'capital_reserves',
  dividend_disbursed: 'dividend_disbursed',
  equity_in_earnings_of_subsiduaries: 'equity_in_earnings_of_subsiduaries',
  investment_grants: 'investment_grants',
  money_received_against_share_warrants: 'money_received_against_share_warrants',
  other_free_reserves: 'other_free_reserves',
  share_application_money_pending_allotment: 'share_application_money_pending_allotment',
  share_capital: 'share_capital',
  funds: 'funds',
  advertising_promotional: 'advertising_promotional',
  bad_debts: 'bad_debts',
  bank_charges: 'bank_charges',
  charitable_contributions: 'charitable_contributions',
  commissions_and_fees: 'commissions_and_fees',
  entertainment: 'entertainment',
  entertainment_meals: 'entertainment_meals',
  equipment_rental: 'equipment_rental',
  finance_costs: 'finance_costs',
  global_tax_expense: 'global_tax_expense',
  insurance: 'insurance',
  interest_paid: 'interest_paid',
  legal_professional_fees: 'legal_professional_fees',
  office_expenses: 'office_expenses',
  office_general_administrative_expenses: 'office_general_administrative_expenses',
  other_business_expenses: 'other_business_expenses',
  other_miscellaneous_service_cost: 'other_miscellaneous_service_cost',
  promotional_meals: 'promotional_meals',
  rent_or_lease_of_buildings: 'rent_or_lease_of_buildings',
  repair_maintenance: 'repair_maintenance',
  shipping_freight_delivery: 'shipping_freight_delivery',
  supplies_materials: 'supplies_materials',
  travel: 'travel',
  travel_meals: 'travel_meals',
  utilities: 'utilities',
  auto: 'auto',
  cost_of_labor: 'cost_of_labor',
  dues_subscriptions: 'dues_subscriptions',
  payroll_expenses: 'payroll_expenses',
  taxes_paid: 'taxes_paid',
  unapplied_cash_bill_payment_expense: 'unapplied_cash_bill_payment_expense',
  amortization_expense: 'amortization_expense',
  appropriations_to_depreciation: 'appropriations_to_depreciation',
  borrowing_cost: 'borrowing_cost',
  distribution_costs: 'distribution_costs',
  external_services: 'external_services',
  extraordinary_charges: 'extraordinary_charges',
  income_tax_expense: 'income_tax_expense',
  loss_on_discontinued_operations_net_of_tax: 'loss_on_discontinued_operations_net_of_tax',
  management_compensation: 'management_compensation',
  other_current_operating_charges: 'other_current_operating_charges',
  other_external_services: 'other_external_services',
  other_rental_costs: 'other_rental_costs',
  other_selling_expenses: 'other_selling_expenses',
  project_studies_surveys_assessments: 'project_studies_surveys_assessments',
  purchases_rebates: 'purchases_rebates',
  shipping_and_delivery_expense: 'shipping_and_delivery_expense',
  staff_costs: 'staff_costs',
  sundry: 'sundry',
  travel_expenses_general_and_admin_expenses: 'travel_expenses_general_and_admin_expenses',
  travel_expenses_selling_expense: 'travel_expenses_selling_expense',
  depreciation: 'depreciation',
  exchange_gain_or_loss: 'exchange_gain_or_loss',
  other_miscellaneous_expense: 'other_miscellaneous_expense',
  penalties_settlements: 'penalties_settlements',
  amortization: 'amortization',
  gas_and_fuel: 'gas_and_fuel',
  home_office: 'home_office',
  home_owner_rental_insurance: 'home_owner_rental_insurance',
  other_home_office_expenses: 'other_home_office_expenses',
  mortgage_interest: 'mortgage_interest',
  rent_and_lease: 'rent_and_lease',
  repairs_and_maintenance: 'repairs_and_maintenance',
  parking_and_tolls: 'parking_and_tolls',
  vehicle: 'vehicle',
  vehicle_insurance: 'vehicle_insurance',
  vehicle_lease: 'vehicle_lease',
  vehicle_loan_interest: 'vehicle_loan_interest',
  vehicle_loan: 'vehicle_loan',
  vehicle_registration: 'vehicle_registration',
  vehicle_repairs: 'vehicle_repairs',
  other_vehicle_expenses: 'other_vehicle_expenses',
  wash_and_road_services: 'wash_and_road_services',
  deferred_tax_expense: 'deferred_tax_expense',
  depletion: 'depletion',
  exceptional_items: 'exceptional_items',
  extraordinary_items: 'extraordinary_items',
  income_tax_other_expense: 'income_tax_other_expense',
  mat_credit: 'mat_credit',
  prior_period_items: 'prior_period_items',
  tax_roundoff_gain_or_loss: 'tax_roundoff_gain_or_loss',
  equipment_rental_cos: 'equipment_rental_cos',
  other_costs_of_service_cos: 'other_costs_of_service_cos',
  shipping_freight_delivery_cos: 'shipping_freight_delivery_cos',
  supplies_materials_cogs: 'supplies_materials_cogs',
  cost_of_labor_cos: 'cost_of_labor_cos',
  cost_of_sales: 'cost_of_sales',
  freight_and_delivery_cost: 'freight_and_delivery_cost',
  accounts_payable: 'accounts_payable',
  outstanding_dues_micro_small_enterprises: 'outstanding_dues_micro_small_enterprises',
  outstanding_dues_other_than_micro_small_enterprises: 'outstanding_dues_other_than_micro_small_enterprises',
  credit_card: 'credit_card',
  other_long_term_liabilities: 'other_long_term_liabilities',
  shareholder_notes_payable: 'shareholder_notes_payable',
  accruals_and_deferred_income: 'accruals_and_deferred_income',
  accrued_long_term_liabilities: 'accrued_long_term_liabilities',
  accrued_vacation_payable: 'accrued_vacation_payable',
  bank_loans: 'bank_loans',
  debts_related_to_participating_interests: 'debts_related_to_participating_interests',
  deferred_tax_liabilities: 'deferred_tax_liabilities',
  government_and_other_public_authorities: 'government_and_other_public_authorities',
  group_and_associates: 'group_and_associates',
  liabilities_related_to_assets_held_for_sale: 'liabilities_related_to_assets_held_for_sale',
  long_term_borrowings: 'long_term_borrowings',
  long_term_debit: 'long_term_debit',
  long_term_employee_benefit_obligations: 'long_term_employee_benefit_obligations',
  obligations_under_finance_leases: 'obligations_under_finance_leases',
  other_long_term_provisions: 'other_long_term_provisions',
  provision_for_liabilities: 'provision_for_liabilities',
  provisions_non_current_liabilities: 'provisions_non_current_liabilities',
  staff_and_related_long_term_liability_accounts: 'staff_and_related_long_term_liability_accounts',
  direct_deposit_payable: 'direct_deposit_payable',
  line_of_credit: 'line_of_credit',
  loan_payable: 'loan_payable',
  global_tax_payable: 'global_tax_payable',
  global_tax_suspense: 'global_tax_suspense',
  other_current_liabilities: 'other_current_liabilities',
  payroll_clearing: 'payroll_clearing',
  payroll_tax_payable: 'payroll_tax_payable',
  prepaid_expenses_payable: 'prepaid_expenses_payable',
  rents_in_trust_liability: 'rents_in_trust_liability',
  trust_accounts_liabilities: 'trust_accounts_liabilities',
  federal_income_tax_payable: 'federal_income_tax_payable',
  insurance_payable: 'insurance_payable',
  sales_tax_payable: 'sales_tax_payable',
  state_local_income_tax_payable: 'state_local_income_tax_payable',
  accrued_liabilities: 'accrued_liabilities',
  current_liabilities: 'current_liabilities',
  current_portion_employee_benefits_obligations: 'current_portion_employee_benefits_obligations',
  current_portion_of_obligations_under_finance_leases: 'current_portion_of_obligations_under_finance_leases',
  current_tax_liability: 'current_tax_liability',
  dividends_payable: 'dividends_payable',
  duties_and_taxes: 'duties_and_taxes',
  interest_payables: 'interest_payables',
  provision_for_warranty_obligations: 'provision_for_warranty_obligations',
  provisions_current_liabilities: 'provisions_current_liabilities',
  short_term_borrowings: 'short_term_borrowings',
  social_security_agencies: 'social_security_agencies',
  staff_and_related_liability_accounts: 'staff_and_related_liability_accounts',
  sundry_debtors_and_creditors: 'sundry_debtors_and_creditors',
  trade_and_other_payables: 'trade_and_other_payables',
  non_profit_income: 'non_profit_income',
  other_primary_income: 'other_primary_income',
  sales_of_product_income: 'sales_of_product_income',
  service_fee_income: 'service_fee_income',
  discounts_refunds_given: 'discounts_refunds_given',
  unapplied_cash_payment_income: 'unapplied_cash_payment_income',
  cash_receipt_income: 'cash_receipt_income',
  operating_grants: 'operating_grants',
  other_current_operating_income: 'other_current_operating_income',
  own_work_capitalized: 'own_work_capitalized',
  revenue_general: 'revenue_general',
  sales_retail: 'sales_retail',
  sales_wholesale: 'sales_wholesale',
  savings_by_tax_scheme: 'savings_by_tax_scheme',
  dividend_income: 'dividend_income',
  interest_earned: 'interest_earned',
  other_investment_income: 'other_investment_income',
  other_miscellaneous_income: 'other_miscellaneous_income',
  tax_exempt_interest: 'tax_exempt_interest',
  gain_loss_on_sale_of_fixed_assets: 'gain_loss_on_sale_of_fixed_assets',
  gain_loss_on_sale_of_investments: 'gain_loss_on_sale_of_investments',
  loss_on_disposal_of_assets: 'loss_on_disposal_of_assets',
  other_operating_income: 'other_operating_income',
  unrealised_loss_on_securities_net_of_tax: 'unrealised_loss_on_securities_net_of_tax',
} as const;

export interface RemoteAccountDumpSchema {
  account_number?: RemoteAccountDumpSchemaAccountNumber;
  account_sub_type: AccountingSubAccountType;
  account_type: RemoteAccountType;
  active: boolean;
  classification: RemoteAccountClassification;
  id: string;
  name: string;
  parent_account?: RemoteAccountDumpSchemaParentAccount;
}

export type AccountMappingDumpSchemaRemoteAccountId = string | null;

export type AccountMappingDumpSchemaRemoteAccount = RemoteAccountDumpSchema | null;

export type AccountMappingDumpSchemaInventoryProfileId = string | null;

export interface AccountMappingDumpSchema {
  id: string;
  inventory_profile_id?: AccountMappingDumpSchemaInventoryProfileId;
  remote_account?: AccountMappingDumpSchemaRemoteAccount;
  remote_account_id?: AccountMappingDumpSchemaRemoteAccountId;
  type: MoabAccountType;
}

export type DepreciationSettingCreateSchemaUsefulLife = number | null;

export type DepreciationSettingCreateSchemaDepreciationPercentage = string | null;

export type DepreciationSettingUpdateSchemaUsefulLife = number | null;

export type DepreciationSettingUpdateSchemaDepreciationPercentage = string | null;

export type DepreciationSettingUpdateSchemaDepreciationFrequency = DepreciationFrequency | null;

export interface DepreciationSettingUpdateSchema {
  depreciation_frequency?: DepreciationSettingUpdateSchemaDepreciationFrequency;
  depreciation_methodology: DepreciationMethodology;
  depreciation_percentage?: DepreciationSettingUpdateSchemaDepreciationPercentage;
  first_period_depreciation: FirstPeriodDepreciationSetting;
  is_business_default: boolean;
  name: string;
  useful_life?: DepreciationSettingUpdateSchemaUsefulLife;
}

export type PaginatedDepreciationSettingResponseSchemaNext = number | null;

export interface PaginatedDepreciationSettingResponseSchema {
  elements: DepreciationSettingDumpSchema[];
  next?: PaginatedDepreciationSettingResponseSchemaNext;
  total_count: number;
}

export type FirstPeriodDepreciationSetting = typeof FirstPeriodDepreciationSetting[keyof typeof FirstPeriodDepreciationSetting];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirstPeriodDepreciationSetting = {
  full_month: 'full_month',
  mid_month: 'mid_month',
  zero_month: 'zero_month',
  full_year: 'full_year',
  half_year: 'half_year',
  zero_year: 'zero_year',
} as const;

export type DepreciationSettingDumpSchemaUsefulLife = number | null;

export type DepreciationSettingDumpSchemaDepreciationPercentage = string | null;

export type DepreciationMethodology = typeof DepreciationMethodology[keyof typeof DepreciationMethodology];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepreciationMethodology = {
  straight_line: 'straight_line',
} as const;

export type DepreciationFrequency = typeof DepreciationFrequency[keyof typeof DepreciationFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepreciationFrequency = {
  monthly: 'monthly',
  yearly: 'yearly',
} as const;

export type DepreciationSettingCreateSchemaDepreciationFrequency = DepreciationFrequency | null;

export interface DepreciationSettingCreateSchema {
  depreciation_frequency?: DepreciationSettingCreateSchemaDepreciationFrequency;
  depreciation_methodology: DepreciationMethodology;
  depreciation_percentage?: DepreciationSettingCreateSchemaDepreciationPercentage;
  first_period_depreciation: FirstPeriodDepreciationSetting;
  is_business_default: boolean;
  name: string;
  useful_life?: DepreciationSettingCreateSchemaUsefulLife;
}

export interface DepreciationSettingDumpSchema {
  depreciation_frequency: DepreciationFrequency;
  depreciation_methodology: DepreciationMethodology;
  depreciation_percentage?: DepreciationSettingDumpSchemaDepreciationPercentage;
  first_period_depreciation: FirstPeriodDepreciationSetting;
  id: string;
  is_business_default: boolean;
  name: string;
  useful_life?: DepreciationSettingDumpSchemaUsefulLife;
}

export type AccountingAccessDumpSchemaWrapperAccess = AccountingAccessDumpSchema | null;

export interface AccountingAccessDumpSchemaWrapper {
  access?: AccountingAccessDumpSchemaWrapperAccess;
}

export type AccountingAccessStatus = typeof AccountingAccessStatus[keyof typeof AccountingAccessStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountingAccessStatus = {
  active: 'active',
  pending: 'pending',
  failed: 'failed',
} as const;

export type AccountingAccessProvider = typeof AccountingAccessProvider[keyof typeof AccountingAccessProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountingAccessProvider = {
  quickbooks: 'quickbooks',
} as const;

export interface AccountingAccessDumpSchema {
  business_id: string;
  id: string;
  provider: AccountingAccessProvider;
  status: AccountingAccessStatus;
}

export interface SuccessResponse {
  success?: boolean;
}

export interface QBOAuth {
  code: string;
  realm_id: string;
}

export interface QBOAuthUrl {
  url: string;
}

