import { lazily } from 'react-lazily'

import { getPurchaseOrderGetQueryOptions } from '_autogenerated'
import { purchaseOrderUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  PurchaseOrderListPage,
  PurchaseOrderCreatePage,
  PurchaseOrderDetailPageRoot,
  PurchaseOrderDetailPageDetailsTab,
  PurchaseOrderDetailPageActivityTab,
  PurchaseOrderEditPage,
} = lazily(() => import('./components'))

export const purchaseOrderRoutes = [
  createRoute({
    route: purchaseOrderUrls,
    Component: PurchaseOrderListPage,
  }),
  createRoute({
    route: purchaseOrderUrls.create,
    Component: PurchaseOrderCreatePage,
  }),
  createRoute({
    route: purchaseOrderUrls.detail,
    Component: PurchaseOrderDetailPageRoot,
    prefetch: ({ id }) => [getPurchaseOrderGetQueryOptions(id)],
    children: [
      createRoute({
        route: purchaseOrderUrls.detail,
        Component: PurchaseOrderDetailPageDetailsTab,
      }),
      createRoute({
        route: purchaseOrderUrls.detail.activity,
        Component: PurchaseOrderDetailPageActivityTab,
      }),
    ],
  }),
  createRoute({
    route: purchaseOrderUrls.edit,
    Component: PurchaseOrderEditPage,
    prefetch: ({ id }) => [getPurchaseOrderGetQueryOptions(id)],
  }),
]
