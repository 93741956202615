import { Navigate } from 'react-router-dom'

import { type CommentableObjects } from '_autogenerated'
import { urlFactory, useRouteParams, createRoute } from 'routing'

import { vendorUrls, purchaseOrderUrls } from './urls'

const redirectUrls = urlFactory('', {
  activity: urlFactory('/activity/:activityLogId/:objectType/:objectId', {}),
})

const activityUrlForObjectType: Record<
  CommentableObjects,
  (_: { objectId: string }) => string
> = {
  order: ({ objectId }) => `/orders/${objectId}/activity`,
  invoice: ({ objectId }) => `/billing/invoices/${objectId}/activity`,
  payment: ({ objectId }) => `/billing/payments/${objectId}/activity`,
  customer: ({ objectId }) => `/customers/${objectId}/activity`,
  vendor: ({ objectId }) => vendorUrls.detail.buildUrl({ id: objectId }),
  purchase_order: ({ objectId }) =>
    purchaseOrderUrls.detail.activity.buildUrl({ id: objectId }),
}

function ActivityRedirect() {
  const { objectType, objectId } = useRouteParams(redirectUrls.activity)

  return (
    <Navigate
      to={activityUrlForObjectType[objectType as CommentableObjects]({
        objectId,
      })}
    />
  )
}

export const redirectRoutes = [
  createRoute({
    route: redirectUrls.activity,
    Component: ActivityRedirect,
  }),
]
