import { lazily } from 'react-lazily'

import { getVendorGetQueryOptions } from '_autogenerated'
import { vendorUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  VendorListPage,
  VendorCreatePage,
  VendorDetailPageRoot,
  VendorDetailPagePurchaseDetailsTab,
  VendorDetailPagePurchaseOrdersTab,
  VendorEditPage,
} = lazily(() => import('./components'))

export const vendorRoutes = [
  createRoute({
    route: vendorUrls,
    Component: VendorListPage,
  }),
  createRoute({
    route: vendorUrls.create,
    Component: VendorCreatePage,
  }),
  createRoute({
    route: vendorUrls.detail,
    Component: VendorDetailPageRoot,
    prefetch: ({ id }) => [getVendorGetQueryOptions(id)],
    children: [
      createRoute({
        route: vendorUrls.detail,
        Component: VendorDetailPagePurchaseDetailsTab,
      }),
      createRoute({
        route: vendorUrls.detail.purchaseOrders,
        Component: VendorDetailPagePurchaseOrdersTab,
      }),
    ],
  }),
  createRoute({
    route: vendorUrls.edit,
    Component: VendorEditPage,
    prefetch: ({ id }) => [getVendorGetQueryOptions(id)],
  }),
]
